import React from 'react';
import { RecoilRoot } from 'recoil';
// import ErrorBoundary from './recoil/ErrorBoundry'
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
// import { unstable_createMuiStrictModeTheme as createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { createBrowserHistory } from 'history'

const theme = responsiveFontSizes(createTheme({
    palette: {
        // primary: {
        //     main: '#6C54A4'
        // },
        // secondary: {
        //     main: '#17768D'
        // },
        // all: '#BA274A',//'#92374D',
        // male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
        // female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
        // other: '#E1BC29',
        // genders: {
        //     male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
        //     female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
        //     other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
        // },
        // respondents: '#17768D',
        // dial: '#7C9EB2', //677BA8
        // selectedDial: '#17768D',//'#008bf9',
        // tuneIn: '#3BB273',//'#58BC82',
        // tuneOut: '#E15554',//'#DB504A'
    },
}));


const Root = () => {

    return (
        <RecoilRoot>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <DndProvider backend={HTML5Backend}>
                        <Router>
                            <AppRoutes />
                        </Router>
                    </DndProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </RecoilRoot >
    )
}

export default Root;