import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUser, emailTemplates } from '../recoil/atoms'
import { useLocalStorage } from '../hooks/hooks'
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
// import MaterialTable from "material-table"
import MaterialTable from "@material-table/core"
import AddFab from '../components/AddFab';
import EmailTemplateModal from '../components/EmailTemplateModal';
import EmailTemplatesSubscription from '../recoil/EmailTemplatesSubscription';
import { withSuspense } from '../hooks/suspense'
import { saveEmailTemplatePromise, deleteEmailTemplatePromise } from '../services/emailTemplateService'
import { useOktaAuth } from '@okta/okta-react';

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const useStyles = makeStyles({
  templates: {
    marginBottom: '1rem',
    width: '100%'
  },
  page: {
    padding: '1em'
  },
  footer: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
  names: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
})

const DismissBtn = (id) => {
  const { closeSnackbar } = useSnackbar();
  return <>
      <IconButton
          aria-label="close"
          color="inherit"
          // className={styles.close} //NO CLASS DEFINED
          onClick={() => closeSnackbar(id)}
      >
          <CloseIcon />
      </IconButton>
  </>
}

const EmailsPage = React.memo((props) => {
  const templates = useRecoilValue(emailTemplates);
  const user = useRecoilValue(currentUser);
  const styles = useStyles();
  const [templateSelected, setTemplateSelected] = useLocalStorage('templateSelected', null)//useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState({});
  const [emailDialogOpen, setEmailDialogOpen] = useLocalStorage('emailTemplateDialogState', false) //useState(false);
  // const [error, setError] = useState();
  // const [errorOpen, setErrorOpen] = useState(false)
  const [tableSort, setTableSort] = useLocalStorage(`emailtemplatestablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`emailtemplatestableorder`, {})
  const { oktaAuth } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory();

  const tableRef = useRef()

  useEffect(() => {
    if (!user.fullAdmin) {
      history.push(`/sessions`)
    }
  }, [user, history])

  const handleCloseEmailDialog = useCallback(() => {
    // clearLocalStorage();
    setEmailDialogOpen(false);
    setTemplateSelected(null)
  }, [setTemplateSelected, setEmailDialogOpen]);

  const submitEmailTemplate = useCallback((event, submitData) => {
    setLoading(true)
    const template = cloneDeep(submitData)
    const data = (templateSelected && templateSelected.id) ? difference(templateSelected, submitData) : template
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {

      for (let i in template) {
        if (typeof template[i] !== 'boolean' && !template[i]) delete template[i]
      }
      if (template.tableData) delete template.tableData

      const token = oktaAuth.getAccessToken()
      saveEmailTemplatePromise(template, token).then((res) => {
        setUpdate({})
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving EmailTemplate')
          enqueueSnackbar('Error Saving EmailTemplate', { preventDuplicate: true, action: DismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      setUpdate({})
      setLoading(false)
    }
    setEmailDialogOpen(false);
    // clearLocalStorage();
    setTemplateSelected(null)
  }, [templateSelected, oktaAuth, setTemplateSelected, setEmailDialogOpen, enqueueSnackbar, user.email]);

  const addEmailTemplate = useCallback((event, data) => {
    setTemplateSelected(null);
    setEmailDialogOpen(true);
  }, [setTemplateSelected, setEmailDialogOpen])

  const editEmailTemplate = useCallback((event, data) => {
    // const rowData = cloneDeep(data)
    // delete rowData.tableData // REMOVE TABLE DATA PROP
    setTemplateSelected(data);
    setEmailDialogOpen(true);
  }, [setTemplateSelected, setEmailDialogOpen])

  const deleteEmailTemplate = useCallback((event, { id, tableData }) => {
    setLoading(true)
    const token = oktaAuth.getAccessToken()
    deleteEmailTemplatePromise(id, token).then((res) => {
      setUpdate({})
      setLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Deleting EmailTemplate')
        enqueueSnackbar('Error Deleting EmailTemplate', { preventDuplicate: true, action: DismissBtn });
        setUpdate({})
        setLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const tableColumns = useCallback(() => ([
    { title: "Template Name", field: "name" },
    { title: "Subject Line", field: "subject" },
    { title: "Email Title", field: 'emailTitle'},
    { title: "Include Logo Image", field: "includeLogo" },
    { title: "Include Event Title", field: "includeTitle" },
    { title: "Include Event Date/Time", field: "includeTime" }
  ]).map((x, i) => {
    x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
    x.defaultSort = tableSort[x.field]
    // if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [tableSort, tableOrder])

  const emailTemplatesLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    const field = tableColumns()[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  return (
    <div className={styles.page}>
      <EmailTemplatesSubscription refresh={update} onLoaded={emailTemplatesLoaded} key={`emailtemplatessubscription`} />
      {emailDialogOpen && <EmailTemplateModal data={templateSelected} onSubmit={submitEmailTemplate} open={emailDialogOpen} handleClose={handleCloseEmailDialog} />}
      <div className={styles.templates}>
        <MaterialTable
          key={`email-templates-table`}
          tableRef={tableRef}
          onOrderChange={handleOrderChange}
          onColumnDragged={handleColumnDrag}
          options={{
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelType: 'single',
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100],
            actionsCellStyle: {
              // display: 'flex',
              // justifyContent: 'flex-end',
              marginRight: '5px'
            },
          }}
          columns={tableColumns()}
          isLoading={!templates || loading}
          data={templates || []} //MATERIAL TABLE MUTATES PROPS :(
          title="Email Templates"
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: editEmailTemplate
            },
            {
              icon: 'delete',
              tooltip: 'Delete',
              onClick: deleteEmailTemplate,
              hidden: !user.fullAdmin,
            }
          ]}
        />
      </div>
      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        TransitionProps={{
          onExited: handleErrorClear
        }}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={styles.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
      {user.fullAdmin && <AddFab show={true} onClick={addEmailTemplate} />}
      <div className={styles.footer} />
    </div>
  );
})

export default withSuspense(EmailsPage)