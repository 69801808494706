import React from 'react'
import { Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import vwxLogo from '../resources/vwx-logo.svg'

const useStyles = makeStyles({
    logo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // padding: '1rem',
        textAlign: 'center'
    },
    logoSvg: {
        height: '65px',
        width: '260px',
        transform: 'scale(1)'
    },
    logoFont: {
        fontFamily: `'Yanone Kaffeesatz', sans-serif`,
        // fontFamily: `'BenchNine', sans-serif`,
        // fontWeight: 'light',
        fontWeight: 300,
        letterSpacing: '0.07em',
        lineHeight: 1
    },
    logoFontBold: {
        fontFamily: `'Yanone Kaffeesatz', sans-serif`,
        // fontFamily: `'BenchNine', sans-serif`,
        // fontWeight: 'light',
        fontWeight: 700,
        letterSpacing: '0.07em',
        lineHeight: 1
    },
    sub: {
        lineHeight: 1,
        padding: '0.75em'
    }
});

const DrawerLogo = React.memo(() => {
    const styles = useStyles()

    return (
        <div className={styles.logo}>
            {/* <Typography variant="h2" className={styles.logoFont}><strong className={styles.logoFontBold}>Live</strong>Trac</Typography> */}
            {/* <Typography variant="h2" className={styles.logoFont}><strong className={styles.logoFontBold}>Virtu</strong>Works</Typography> */}
            <img src={vwxLogo} className={styles.logoSvg} />
            <Typography variant="button" className={styles.sub}>Session Management Console</Typography>
        </div>

    );

})

export default withTheme(DrawerLogo)