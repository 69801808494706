import React, { useState, useEffect, useRef, useCallback } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { draftToMarkdown } from 'markdown-draft-js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  subroot: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  sublabel: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: ({ label }) => label ? 'calc(20% + 16px)' : '10px'
  },
  base: {
    flexBasis: '80%',
  },
  richInput: {
    border: '1px solid #bdbdbd',
    borderRadius: '4px'
  },
});

const RichTextInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, html }) => {
  const styles = useStyles({ label })
  const [defaultValue, setDefaultValue] = useState(!!value && typeof value === 'object' ? JSON.stringify(value) : null)

  const loadedRef = useRef(false)
  const valueRef = useRef()
  const editorStateRef = useRef()

  useEffect(() => {
    if (value?.template) {
      const newValue = { ...value }
      delete newValue.template
      setDefaultValue(JSON.stringify(newValue))
    }
  }, [value])

  useEffect(() => {
    if (!!defaultValue && loadedRef.current) {
      handleChange(editorStateRef.current)
    }
    loadedRef.current = true
  }, [defaultValue, handleChange])

  const handleChange = useCallback((editorState) => {
    const convertFn = html ? stateToHTML : convertToRaw
    valueRef.current = convertFn(editorState.getCurrentContent())
    onChange(null, valueRef.current)
    editorStateRef.current = editorState
  }, [html, onChange]);

  return (
    <>
      <div className={styles.root}>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <div className={styles.base}>
          <MUIRichTextEditor
            disabled={true}
            // ref={editorRef}
            label={placeholder}
            className={styles.richInput}
            onChange={handleChange}
            onSave={console.log}
            defaultValue={defaultValue}
            toolbarButtonSize="small"
            inlineToolbar={true}
            controls={["title", "bold", "italic", "underline", "strikethrough", "clear", "link", "numberList", "bulletList"]}
            inlineToolbarControls={["bold", "italic", "underline", "strikethrough", "link", "clear"]}
          />
        </div>
      </div>
      {sublabel && <div className={styles.subroot}>
        <Typography variant="caption" className={styles.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </>
  );
})

// RichTextInputField.defaultProps = {
//   label: 'Text'
// }

export default RichTextInputField;
