import React, { useMemo, useCallback } from 'react';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  subroot: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  textField: {
    flexBasis: '80%',
    padding: '7px 9px',
    border: 'solid 1px #bdbdbd',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
    // width: '100%'
  }
});

const DisplayField = React.memo(({ value, label, displayLabel, displayComponent, endComponent }) => {
  const styles = useStyles({ label })

  // Check if the displayComponent is a function and call it
  const DisplayComponent = useMemo(() => {
    if (typeof displayComponent === 'function') {
      return () => displayComponent(value);
    }
    return displayComponent;
  }, [displayComponent, value]);

  const EndComponent = useMemo(() => {
    if (typeof endComponent === 'function') {
      return () => endComponent(value);
    }
    return endComponent;
  }, [endComponent, value])

  // Check if the displayLabel is a function and call it
  const displayValue = useMemo(() => {
    if (typeof displayLabel === 'function') {
      return displayLabel(value);
    }
    return value;
  }, [displayLabel, value]);

  return (
    <>
      <div className={styles.root}>
        {label && <Typography className={styles.label}>{label}</Typography>}

        {!!displayComponent ? <DisplayComponent /> : 
        !!endComponent ? <Typography className={styles.textField}>{displayValue}<EndComponent/></Typography> :
         <Typography className={styles.textField}>{displayValue}</Typography>}
      </div>
    </>
  );
})

export default DisplayField;
