import React, { useCallback, useRef } from 'react';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import SelectInputField from './SelectInputField';

const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  builder: {
    flexBasis: '80%'
  },
  addnew: {
    margin: '8px 0 8px 0'
  },
});

const MultiSelectInputField = React.memo(({ value = [], label, buttonLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, optionValueKey, fullObjects, ...restProps }) => {
  const styles = useStyles();
  const valueRef = useRef();

  valueRef.current = value || []

  const handleAdd = useCallback(() => {
    const newValue = [...valueRef.current, null]
    onChange(null, newValue)
  }, [onChange])

  const handleInputChange = useCallback((changedValue, index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue[index] = (changedValue && (!fullObjects && optionValueKey)) ? Array.isArray(changedValue) ? changedValue.map(x => x[optionValueKey]) : changedValue[optionValueKey] : changedValue
    console.log('NEW SELECT BUILDER VALUE', newValue)
    onChange(null, newValue)
  }, [onChange, optionValueKey, fullObjects]);

  const handleRemove = useCallback((index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue.splice(index, 1)
    // invalidate && invalidate(`${fieldKey}-button${index}`, false)
    onChange(null, newValue)
  }, [onChange]);

  return (
    <div className={styles.root}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      <div className={styles.builder}>
        {value && value.map((val, i) =>
          <SelectInputField
            // type='select'
            fieldKey={`${fieldKey}-${i}`}
            key={`${fieldKey}-${i}`}
            // index={i}
            value={val}
            required={true}
            // required={required || (conditional && conditionMet)}
            optionValueKey={optionValueKey}
            fullObjects={fullObjects}
            onRemove={() => handleRemove(i)}
            onChange={(e, v) => handleInputChange(v, i)}
            invalidate={invalidate}
            {...restProps}
          />
        )}
        <div className={styles.addnew}>
        <Button variant="outlined" onClick={handleAdd}>
          <AddIcon /> {buttonLabel || 'Add'}
        </Button>
      </div>
      </div>
    </div>
  );
})

// MultiSelectInputField.defaultProps = {

// }

export default MultiSelectInputField;
