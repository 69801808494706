import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser, selectedSession } from '../recoil/atoms'
import { pastSessions, currentSessions, upcomingSessions } from '../recoil/selectors'
import { useLocalStorage } from '../hooks/hooks'
import { useSnackbar } from 'notistack';
import { IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import FileCopyIcon from '@material-ui/icons/FileCopy';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
import CloseIcon from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TheatersIcon from '@material-ui/icons/Theaters';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FilterListIcon from '@material-ui/icons/FilterList';
// import MovieIcon from '@material-ui/icons/Movie';
import VideoCallIcon from '@material-ui/icons/VideoCall';
// import MaterialTable from "material-table"
import MaterialTable, { MTableToolbar } from "@material-table/core"
import AddFab from '../components/AddFab';
import SessionModal from '../components/SessionModal';
import VideoImportModal from '../components/VideoImportModal';
import SessionTableDetails from '../components/SessionTableDetails';
import SessionsSubscription from '../recoil/SessionsSubscription';
import { withSuspense } from '../hooks/suspense'
import { deleteSessionPromise, insertSessionPromise, updateSessionPromise } from '../services/sessionsService'
import { getVideosPromise, uploadBrightcoveVideoPromise, uploadTicktboxVideoPromise, getYoutubeVideoPromise } from '../services/videoService'
import { getUsersPromise, getUserMeetingsPromise } from '../services/zoomService'
import { useOktaAuth } from '@okta/okta-react';
import moment from 'moment-timezone'
import { getSurveysPromise } from '../services/surveyService';
import { getPFMScreeningsPromise } from '../services/recruitService';
import { getAcuityAppointmentTypes, getAcuityCalendars } from '../services/appointmentService';
import MTableBodyRow from '../components/standard/MTableBodyRow';
import { getProjectsPromise } from '../services/projectsService';
import translations from "../utils/translations.json";

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const useStyles = makeStyles({
  sessions: {
    marginBottom: '1rem',
    width: '100%'
  },
  page: {
    padding: '1em'
  },
  footer: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
  fillAvailableWidth: {
    width: "-webkit-fill-available",
  },
  sessionFilterTitle: {
    opacity: 1,
    fontWeight: 600,
    fontSize: '12px',
    padding: '16px 16px 6px 16px',
  },
  sessionFilterOptions: {
    listStyleType: 'none',
    marginTop: 0,
    padding: 0,
    minWidth: '200px',

    "& li": {
      paddingLeft: "20px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },

    "& li:hover": {
      cursor: 'pointer',
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
})

const DismissBtn = (id) => {
  const { closeSnackbar } = useSnackbar();
  return <>
      <IconButton
          aria-label="close"
          color="inherit"
          // className={styles.close} //NO CLASS DEFINED
          onClick={() => closeSnackbar(id)}
      >
          <CloseIcon />
      </IconButton>
  </>
}

// Session time to local time based on user browser
const sessionTime = (time) => {
  const localTimezone = moment.tz.guess()
  return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
}

const SessionsPage = React.memo(({ filter, ...props }) => {
  const [showCompleted, setShowCompleted] = useState(true)
  const [showTest, setShowTest] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showDial, setShowDial] = useState(false);
  const [showProject, setShowProject] = useState(false);
  // const [sessions, setSessions] = useRecoilState(sessionState);
  const sessions = useRecoilValue(filter === 'past' ? pastSessions([showCompleted, showTest, showCancelled, showDeleted, showDial, showProject]) : filter === "today's" ? currentSessions : upcomingSessions);
  const user = useRecoilValue(currentUser);
  const [tableSessions, setTableSessions] = useState(undefined)
  // const setSessions = useSetRecoilState(sessionState)
  const styles = useStyles();
  const [sessionSelected, setSessionSelected] = useLocalStorage('sessionSelected', null)//useState();
  const setSelectedSession = useSetRecoilState(selectedSession)
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState({});
  const [sessionDialogOpen, setSessionDialogOpen] = useLocalStorage('sessionDialogState', false) //useState(false);
  // const [error, setError] = useState();
  // const [errorOpen, setErrorOpen] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [videoImportOpen, setVideoImportOpen] = useLocalStorage('videoImportDialogState', false) //useState(false);
  const [importStarted, setImportStarted] = useState(false)
  const [importComplete, setImportComplete] = useState(false)
  const [importPercent, setImportPercent] = useState()
  const [importProgress, setImportProgress] = useState()
  const [anchorEl, setAnchorEl] = useState(null);

  // const [tableFilters, setTableFilters] = useLocalStorage(`session${filter}tablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${filter}tablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${filter}tableorder`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`session${filter}openrow`, {})
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const historyState = history.location.state

  const tableRef = useRef()
  // const openRef = useRef(openRow)

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    // console.log("total sessions:", sessions, sessions?.filter(session => !session.test && session.isDialSession === true));
    setTableSessions(sessions ? sessions.map(o => {
      const s = { ...o }

      // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
      // if (tableRef.current) {
      //   const { detailPanel } = tableRef.current.props;
      //   let handleShowDetailPanel = detailPanel;

      //   if (typeof detailPanel === 'object') {
      //     handleShowDetailPanel = detailPanel[0].render
      //   }

      //   s.tableData = {
      //     showDetailPanel: openRef.current[filter] && (`${s.id}` === `${openRef.current[filter].id}` && handleShowDetailPanel)
      //   }
      // }
      return s
    }) : undefined)
  }, [sessions, filter])

  useEffect(() => {
    if (historyState && historyState.add) {
      history.replace(history.location.pathname)
      setSessionSelected(null)
      setSessionDialogOpen(true)
    }
  }, [historyState, history, setSessionSelected, setSessionDialogOpen])

  const handleCloseSessionDialog = useCallback(() => {
    // clearLocalStorage();
    setSessionDialogOpen(false);
    setSessionSelected(null)
  }, [setSessionSelected, setSessionDialogOpen]);

  // const showSessionGraph = useCallback((event, { id: sessionId }) => {
  //   const win = window.open(config.dialUrl + '/session/' + sessionId, '_blank');
  //   win.focus();
  // }, []);

  // const resetSessionVideo = useCallback((event, data) => {
  //   setLoading(true)
  //   const reset = {
  //     id: data.id,
  //     started: false,
  //     complete: false,
  //     startedBy: null,
  //     // startTime: null,
  //     sessionStartTime: null,
  //     sessionEndTime: null
  //   }
  // // REMOVE OKTA PROMISE HERE
  //   oktaAuth.getAccessToken().then((token) =>
  //     updateSessionPromise(reset, token)
  //   ).then((res) => {
  //     setUpdate({})
  //     setLoading(false)
  //   }).catch(error => {
  //     if (error.message === 'Unauthorized') {
  //       oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
  //     } else {
  //       setError('Error Resetting Session')
  //       setUpdate({})
  //       setLoading(false)
  //     }
  //   })
  // }, []);

  const submitSession = useCallback((event, submitData) => {

    setLoading(true)
    if (submitData.audienceSegments) submitData.audienceSegments = submitData.audienceSegments.map(x => {
      delete x.collapsed
      x.questionKey = x.questionKey.replace(/\s*$/, '')
      x.answers.forEach(y => {
        if (y.display) y.display = y.display.replace(/\s*$/, '')
        y.answer = y.answer.replace(/\s*$/, '')
      })
      if (x.matrix && x.matrixKeys) {
        x.matrixKeys.forEach(y => {
          if (y.display) y.display = y.display.replace(/\s*$/, '')
          y.option = y.option.replace(/\s*$/, '')
        })
      } else {
        x.matrixKeys = undefined
      }
      return x
    })
    if (submitData.virtualSegments) submitData.virtualSegments = submitData.virtualSegments.map(x => {
      delete x.collapsed
      return x
    })
    const linkedScreenings = submitData.externalRecruitId && submitData.linkedScreenings ? [...submitData.linkedScreenings, submitData.externalRecruitId].sort() : null
    delete submitData.linkedScreenings
    const data = (sessionSelected && sessionSelected.id) ? difference(sessionSelected, submitData) : cloneDeep(submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      // const sessionName = data.hasOwnProperty('sessonName') ? data.sessonName : sessionSelected?.sessionName
      const vidId = data.hasOwnProperty('videoId') ? data.videoId : sessionSelected?.videoId
      const multiSection = data.hasOwnProperty('multiSection') ? data.multiSection : sessionSelected?.multiSection
      const vidLength = data.hasOwnProperty('videoLength') ? data.videoLength : sessionSelected?.videoLength
      const contentLength = data.hasOwnProperty('contentLength') ? data.contentLength : sessionSelected?.contentLength
      const autStop = data.hasOwnProperty('autoStop') ? data.autoStop : sessionSelected?.autoStop
      const geoFilter = data.hasOwnProperty('enableGeoRestriction') ? data.enableGeoRestriction : sessionSelected?.enableGeoRestriction
      const geoFilterX = data.hasOwnProperty('enhancedGeoRestriction') ? data.enhancedGeoRestriction : sessionSelected?.enhancedGeoRestriction
      const subGeoRestriction = data.hasOwnProperty('subGeoRestrictionType') ? data.subGeoRestrictionType : sessionSelected?.subGeoRestrictionType
      const preventLate = data.hasOwnProperty('preventLateEntry') ? data.preventLateEntry : sessionSelected?.preventLateEntry
      const limitDisconnect = data.hasOwnProperty('limitDisconnect') ? data.limitDisconnect : sessionSelected?.limitDisconnect
      const isDialSession = data.hasOwnProperty('isDialSession') ? data.isDialSession : sessionSelected?.isDialSession
      const dialMode = data.hasOwnProperty('dialMode') ? data.dialMode : sessionSelected?.dialMode
      const externalRecruit = data.hasOwnProperty('externalRecruit') ? data.externalRecruit : sessionSelected?.externalRecruit
      const recruitPlatform = data.hasOwnProperty('recruitPlatform') ? data.recruitPlatform : sessionSelected?.recruitPlatform
      const externalRecruitId = data.hasOwnProperty('externalRecruitId') ? data.externalRecruitId : sessionSelected?.externalRecruitId
      const hasQualifyingSegment = data.hasOwnProperty('hasQualifyingSegment') ? data.hasQualifyingSegment : sessionSelected?.hasQualifyingSegment
      const hasAcuity = data.hasOwnProperty('acuityTechCheck') ? data.acuityTechCheck : sessionSelected?.acuityTechCheck
      const hasSurvey = data.hasOwnProperty('hasSurvey') ? data.hasSurvey : sessionSelected?.hasSurvey
      const hasZoom = data.hasOwnProperty('hasZoomMeeting') ? data.hasZoomMeeting : sessionSelected?.hasZoomMeeting
      const hasMagicLink = data.hasOwnProperty('clientMagicLink') ? data.clientMagicLink : sessionSelected?.clientMagicLink
      const hasPasscode = data.hasOwnProperty('requirePasscode') ? data.requirePasscode : sessionSelected?.requirePasscode
      const vidPlatform = data.hasOwnProperty('videoPlatform') ? data.videoPlatform : sessionSelected?.videoPlatform
      const segments = data.hasOwnProperty('audienceSegments') ? data.audienceSegments : sessionSelected?.audienceSegments
      const vSegments = data.hasOwnProperty('virtualSegments') ? data.virtualSegments : sessionSelected?.virtualSegments
      const hasProject = data.hasOwnProperty('hasProject') ? data.hasProject : sessionSelected?.hasProject
      const chartConfig = data.hasOwnProperty('chartConfig') ? data.chartConfig : sessionSelected?.chartConfig
      const type = data.hasOwnProperty('type') ? data.type : sessionSelected?.type
      const viewerLanguage = data.hasOwnProperty('viewerLanguage') ? data.viewerLanguage : sessionSelected?.viewerLanguage
      // if (!isEqual(sessionSelected, submitData)) {
      // const data = cloneDeep(submitData)
      data.type = type;
      if (sessionSelected) {
        data.id = sessionSelected.id
        data.chimeChatInstance = sessionSelected.chimeChatInstance
      }
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
        // if ((Array.isArray(data[i]) && !data[i].length) || (typeof data[i] !== 'boolean' && !data[i])) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (!hasProject) {
        data.projectId = null
      }
      // const genderSegment = data.audienceSegments.find(x => x.answers.find(ans => ans.questionKey === 'Gender' || !!['Male', 'Female'].includes(ans.answer)))
      // if (!!genderSegment) {
      if (data.audienceSegments || data.virtualSegments) {
        const segmentGoals = [...(segments || []), ...(vSegments || [])].reduce((p, c) => {
          p[c.questionKey] = c.answers.reduce((pa, pc) => (pa[pc.answer] = typeof pc.goal === 'object' ? !pc.goal ? null : { ...pc.goal } : parseFloat(pc.goal), pa), {})
          return p
        }, {})
        data.segmentGoals = JSON.stringify(segmentGoals)
      }
      if (data.audienceSegments) {
        const dialChartSegments = sessionSelected && cloneDeep(sessionSelected.dialChartSegments) || []
        // if (dialChartSegments && dialChartSegments.length) {
        data.audienceSegments.forEach((segment) => {
          segment.answers.forEach(answer => delete answer.goal)
          if (!segment.freeNumeric && !isEqual(segment.answers.map(x => x.answer), dialChartSegments.find(x => x.questionKey === segment.questionKey)?.answers.map(x => x.answer))) {
            const index = dialChartSegments.findIndex(x => x.questionKey === segment.questionKey)
            if (index !== -1 || (segment.questionKey === 'Gender' || segment.answers.reduce((p, c) => p || ['Male', 'Female'].includes(c.answer), false))) {
              const newChartAns = segment.answers.map(ans => {
                const newAns = cloneDeep(ans)
                delete newAns.goal
                const shouldShow = dialChartSegments[index]?.answers.find(x => x.answer === newAns.answer)?.showOnChart
                newAns.showOnChart = typeof shouldShow === 'boolean' ? shouldShow : (['Male', 'Female'].includes(newAns.answer) || false)
                return newAns
              })
              if (index === -1) {
                const newSeg = cloneDeep(segment)
                delete newSeg.multiple
                delete newSeg.question
                delete newSeg.collapsed
                newSeg.hasCrosstab = false
                newSeg.crosstabKeys = []
                newSeg.answers = newChartAns
                dialChartSegments.unshift(newSeg)
              } else {
                dialChartSegments[index].answers = newChartAns
              }
            }
          }
        })
        // FOR MYSQL DATA API
        if (!hasProject || !!data.projectId) {
          data.dialChartSegments = JSON.stringify(dialChartSegments)
          data.audienceSegments = JSON.stringify(data.audienceSegments)
          if (externalRecruit && externalRecruitId) {
            data.externalRecruitId = externalRecruitId
          }
        } else {
          delete data.dialChartSegments
          delete data.audienceSegments
        }
      }
      if (data.virtualSegments) {
        data.virtualSegments.forEach((segment) => {
          segment.answers.forEach(answer => delete answer.goal)
        })
        data.virtualSegments = JSON.stringify(data.virtualSegments)
      }
      if (data.chimeChatChannels) {
        if (data.chimeChatInstance) {
          const oldChannels = sessionSelected.chimeChatChannels || []
          const deletes = oldChannels.filter(x => !data.chimeChatChannels.find(y => x.channelId === y.channelId)).map(x => ({ name: x.name, channelId: x.channelId }))
          const updates = data.chimeChatChannels.filter(x => oldChannels.find(y => x.channelId === y.channelId && (x.name !== y.name || x.type !== y.type || x.restricted !== y.restricted))).map(x => ({ name: x.name, channelId: x.channelId, type: x.type, restricted: x.restricted }))
          data.chimeChatChanges = { deletes, updates }
        }
        data.chimeChatChannels = JSON.stringify(data.chimeChatChannels)
      }
      if (type === 'Focus Group') {
        data.videoId = null
        data.videoPlatform = null
        data.videoLength = null
        data.contentLength = null
        data.autoStopTime = null
        data.isLiveSession = false
        data.multiSection = false
        data.internalVideoName = null
        data.videoMarkers = null
      } else {
        if (!vidLength || !contentLength?.length) {
          data.videoLength = null
          data.contentLength = null
        }
        data.videoId = vidId
        data.videoPlatform = vidPlatform
        if (vidPlatform === 'Brightcove') {
          data.isLiveSession = false
          if (vidId === 'NEW') data.multiSection = multiSection
        }
        if (data.contentLength) data.contentLength = JSON.stringify(data.contentLength)
        if (!autStop) data.autoStopTime = null
        if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName)
        if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)
      }
      if (!hasAcuity) {
        data.acuityAppointmentTypeId = null
        data.acuityCalendarId = null
      }
      if (!externalRecruit) {
        data.recruitPlatform = null
        data.externalRecruitId = null
        data.pauseExternalRecruit = false
      } else if (data.externalRecruitId) {
        data.externalRecruit = true
        data.recruitPlatform = data.recruitPlatform || recruitPlatform
        if (hasProject && recruitPlatform === 'PFM' && linkedScreenings?.length) {
          data.linkedScreenings = linkedScreenings
        } else if (recruitPlatform === 'QTC') {
          data.audienceSegments = data.audienceSegments || (segments && JSON.stringify(segments))
        }
      }
      if (!hasQualifyingSegment) { //check for project
        data.hasQualifyingSegment = false
      }
      if (data.qualifyingSegment) {
        data.qualifyingSegment = JSON.stringify(data.qualifyingSegment)
      }
      //  data.viewerEmailTemplate = translations.linkToVirtualEvent[data.viewerLanguage]; // keep here for now. Need to discuss!
      if (data.allowedBrowsers) data.allowedBrowsers = JSON.stringify(data.allowedBrowsers)
      if (!geoFilter) {
        data.allowedCountries = []
      }
      if (!geoFilter || (!geoFilterX || !subGeoRestriction)) {
        data.subGeoRestriction = null
      }
      if (data.allowedCountries) data.allowedCountries = JSON.stringify(data.allowedCountries)
      if (data.subGeoRestriction) data.subGeoRestriction = JSON.stringify(data.subGeoRestriction)
      if (!preventLate) {
        data.lateEntryTimeLimit = null
      }
      if (!limitDisconnect) {
        data.disconnectTimeLimit = null
        data.disconnectCountLimit = null
      }
      if (!isDialSession || type === 'Focus Group') {
        // data.dialBranding = null
        data.dialStyle = null
        data.dialMotion = null
        data.dialSnap = null
        data.gaugeStyle = null
        data.numberOfMeters = null
        data.scoreBase = null
        data.sliderKnob = null
        data.sliderKnobColor = null
        data.includeTuneOut = false
        data.inactiveReminder = false
        data.inactiveInterval = null
        data.inactiveMessage = null
        data.clientDialViewer = false
        data.symbolOptions = null
        data.inputTime = null
        data.chartConfig = null;
      } else {
        if (dialMode === 'symbol') {
          if (data.symbolOptions) {
            chartConfig.defaultEmoji = data.symbolOptions[0].emoji;
            data.chartConfig = chartConfig;
            data.symbolOptions = JSON.stringify(data.symbolOptions)
          }
          if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
          data.dialMotion = null
          data.dialSnap = null
          data.gaugeStyle = null
          data.numberOfMeters = null
          data.scoreBase = null
          data.sliderKnob = null
          data.sliderKnobColor = null
        } else if (dialMode === 'score') {
          data.symbolOptions = null
          data.chartConfig = null
          data.inputTime = null
        } else {
          data.dialMotion = null
          data.dialSnap = null
          data.gaugeStyle = null
          data.numberOfMeters = null
          data.scoreBase = null
          data.sliderKnob = null
          data.sliderKnobColor = null
          data.symbolOptions = null
          data.chartConfig = null
          data.inputTime = null
          data.clientDialViewer = false;
        }
      }
      if (!hasSurvey) {
        data.surveyType = null
        data.surveyId = null
        data.surveyPlacement = null
      }
      if (!hasZoom) {
        data.zoomHostId = null
        data.zoomMeetingId = null
      } else if (data.zoomMeetingId === 'NEW') {
        if (!data.zoomHostId) data.zoomHostId = sessionSelected.zoomHostId
        data.zoomMeetingName = data.sessionName || sessionSelected.sessionName
        data.zoomMeetingTime = moment.tz(data.guestCheckInTime || sessionSelected.guestCheckInTime, "America/Los_Angeles").seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')
      }
      if (!hasMagicLink) {
        data.magicLinkUrl = null
        data.requirePasscode = false
        data.magicLinkPasscode = null
        data.requireEmailValidation = false
        data.requireApproval = false
      } else if (!hasPasscode) {
        data.magicLinkPasscode = null
      }
      delete data.sessionStartTime
      delete data.contentStartTime
      delete data.sessionEndTime
      delete data.liveStartTime
      delete data.videoIndex
      delete data.videoStartTime
      delete data.videoEndTime
      delete data.chartVideoStart
      delete data.chartVideoEnd
      // FOR MYSQL DATA API
      if (data.scheduledStartTime) data.scheduledStartTime = moment(data.scheduledStartTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.staffArrivalTime) data.staffArrivalTime = moment(data.staffArrivalTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.guestCheckInTime) data.guestCheckInTime = moment(data.guestCheckInTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.clientArrivalTime) data.clientArrivalTime = moment(data.clientArrivalTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss");
      // if (!data.sessionId) data.sessionId = short.generate()
      let submitPromise
      if (!data.id) {
        // console.log('NEW SESSION')
        data.createdBy = user.email
        data.lastUpdatedBy = user.email
        submitPromise = insertSessionPromise
      } else {
        // console.log('UPDATE SESSION')
        data.lastUpdatedBy = user.email
        submitPromise = updateSessionPromise
      }

      const token = oktaAuth.getAccessToken()
      submitPromise(data, token).then((res) => {
        // console.log('updated', res)
        // setSessions(prev => {
        //   const newData = [...prev]
        //   const index = prev.findIndex(session => session.sessionId === data.sessionId)
        //   newData[index] = data
        //   // console.log('new sessions', newData)
        //   return newData
        // })
        setUpdate({})
        // setLoading(false)
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Session')
          enqueueSnackbar('Error Saving Session', { preventDuplicate: true, action: DismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })
    } else {
      // console.log('NO CHANGES MADE')
      setUpdate({})
      setLoading(false)
    }
    setSessionDialogOpen(false);
    // clearLocalStorage();
    setSessionSelected(null)
  }, [sessionSelected, oktaAuth, setSessionSelected, setSessionDialogOpen, enqueueSnackbar, user.email]);

  const cloneSession = useCallback((event, row) => {
    // setLoading(true)
    const data = cloneDeep(row)
    for (let i in data) {
      if ((Array.isArray(data[i]) && !data[i].length) || !data[i]) delete data[i]
    }
    delete data.tableData
    delete data.id
    delete data.dialChartSegments

    try {
      const audienceSegments = data.audienceSegments || []
      const segmentGoals = data.segmentGoals || {}
      data.audienceSegments = audienceSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })
      const virtualSegments = data.virtualSegments || []
      data.virtualSegments = virtualSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })

      if (!data.isDialSession) {
        // If dial is not checked we need to set default value to its fields
        //data.isDialSession = true
        data.scoreBase = 100
        data.dialStyle = "Embedded Slider"
        data.dialMotion = "Stepwise"
        data.dialSnap = false
        data.gaugeStyle = "With Shapes"
        data.sliderKnob = "Clapperboard"
        data.sliderKnobColor = "#F44E3B"
        data.numberOfMeters = 19
        data.noDialArrowKeys = false
        data.includeTuneOut = true
        data.limitTuneOut = false
        data.startTuneOutTime = 2
        data.stopTuneOutTime = 2
        data.askTuneOutReason = false
        data.maxReasonLength = 140
        data.maxReasonTime = 45
        data.inactiveReminder = true
        data.inactiveInterval = 120
        data.inactiveMessage = data.viewerLanguage ? translations.continueToMoveDial[data.viewerLanguage] : 'Please continue to move your dial'
        data.clientDialViewer = true
      }
    } catch (e) {
      console.log(e)
    }

    if (data.externalRecruit && !data.hasProject) {
      data.externalRecruitId = null //'NEW'
      data.pauseExternalRecruit = false
    }

    if (data.hasZoomMeeting) {
      data.zoomMeetingId = 'NEW'
    }

    if (data.chimeChatChannels) {
      data.chimeChatChannels = data.chimeChatChannels.map(channel => (channel.channelId = null, channel))
    }

    // TODO: REMOVE (cannot clone sessions with these attributes)
    delete data.syncOffset
    delete data.started
    delete data.startedBy
    delete data.sessionStartTime
    delete data.contentStartTime
    delete data.sessionEndTime
    delete data.liveStartTime
    delete data.videoStartTime
    delete data.videoIndex
    delete data.videoEndTime
    delete data.chartVideoStart
    delete data.chartVideoEnd
    delete data.finished
    delete data.complete
    delete data.checkInStarted
    delete data.chimeChatInstance
    delete data.qualifyingSegment
    delete data.contentStartTime
    // delete data.videoLength

    // save on server after editing
    setSessionSelected(data);
    setSessionDialogOpen(true);

    // clone on server first then open for editing
    // // REMOVE OKTA PROMISE HERE
    // oktaAuth.getAccessToken().then((token) =>
    //   upsertSessionPromise(data, token)
    // ).then((res) => {
    //   // console.log('cloned', res)
    //   setSessionSelected(data);
    //   setSessionDialogOpen(true);
    //   setSessions(prev => {
    //     return [data, ...prev]
    //   })
    //   setUpdate({})
    //   setLoading(false)
    // }).catch(error => {
    //   console.log(error)
    //   setLoading(false)
    // })
  }, [setSessionSelected, setSessionDialogOpen]);

  const addSession = useCallback((event, data) => {
    setSessionSelected(null);
    setSessionDialogOpen(true);
  }, [setSessionSelected, setSessionDialogOpen])

  const editSession = useCallback((event, row) => {
    //console.log("data", row);

    const data = cloneDeep(row)
    // delete rowData.tableData // REMOVE TABLE DATA PROP

    try {
      const segmentGoals = data.segmentGoals || {}
      data.audienceSegments = data.audienceSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })
      const virtualSegments = data.virtualSegments || []
      data.virtualSegments = virtualSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })

      // Check if Dial setting is checked
      if (!data.isDialSession) {
        // If dial is not checked we need to set default value to its fields
        //data.isDialSession = true
        data.scoreBase = 100
        data.dialStyle = "Embedded Slider"
        data.dialMotion = "Stepwise"
        data.dialSnap = false
        data.gaugeStyle = "With Shapes"
        data.sliderKnob = "Clapperboard"
        data.sliderKnobColor = "#F44E3B"
        data.numberOfMeters = 19
        data.noDialArrowKeys = false
        data.includeTuneOut = true
        data.limitTuneOut = false
        data.startTuneOutTime = 2
        data.stopTuneOutTime = 2
        data.askTuneOutReason = false
        data.maxReasonLength = 140
        data.maxReasonTime = 45
        data.inactiveReminder = true
        data.inactiveInterval = 120
        data.inactiveMessage = data.viewerLanguage ? translations.continueToMoveDial[data.viewerLanguage] : 'Please continue to move your dial'
        data.clientDialViewer = true
      }
    } catch (e) {
      console.log(e)
    }

    setSessionSelected(data);
    setSessionDialogOpen(true);
  }, [setSessionSelected, setSessionDialogOpen])

  const deleteSession = useCallback(({ id }, cancel = false) => {
    setLoading(true)

    const token = oktaAuth.getAccessToken()
    deleteSessionPromise(id, token, cancel).then((res) => {
      // console.log('deleted', res)
      // setSessions(prev => {
      //   const newData = [...prev]
      //   const index = prev.findIndex(session => session.id === id)
      //   delete newData[index]
      //   // console.log('new sessions', newData)
      //   return newData
      // })
      setUpdate({})
      setLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Deleting Session')
        enqueueSnackbar('Error Deleting Session', { preventDuplicate: true, action: DismissBtn });
        setUpdate({})
        setLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const getProjects = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getProjectsPromise(token).then((res) => {
      res.push({
        projectName: 'New Project',
        id: 'NEW'
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('videos error', error)
      }
    })
  }, [oktaAuth])

  const getPFMScreenings = useCallback(async (linked) => {
    const token = oktaAuth.getAccessToken()
    return getPFMScreeningsPromise(linked, token).then((res) => {
      // res.unshift({
      //   title: 'New PFM Online Screening',
      //   id: 'NEW',
      // })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('screenings error', error)
      }
    })
  }, [oktaAuth])

  const getSurveys = useCallback(async (surveyType, forRegistration) => {
    const token = oktaAuth.getAccessToken()
    return getSurveysPromise(surveyType.toLowerCase(), { forRegistration }, token).then((res) => {
      if (forRegistration) {
        res.unshift({
          name: 'New Registration Survey',
          id: 'NEW',
        })
      }
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('surveys error', error)
      }
    })
  }, [oktaAuth])

  const getAppointmentTypes = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getAcuityAppointmentTypes(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('appointments error', error)
      }
    })
  }, [oktaAuth])

  const getCalendars = useCallback(async (type) => {
    const token = oktaAuth.getAccessToken()
    return getAcuityCalendars(type, token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('appointments error', error)
      }
    })
  }, [oktaAuth])

  const getYoutubeVideo = useCallback(async (vid) => {
    const token = oktaAuth.getAccessToken()
    return getYoutubeVideoPromise(vid, token)
  }, [oktaAuth])

  const getVideos = useCallback(async (platform, teamFilter, playlists = false) => {
    const token = oktaAuth.getAccessToken()
    return getVideosPromise(platform, teamFilter, playlists, token).then((res) => {
      if (teamFilter !== 'TC') res.push({
        [platform.toLowerCase() === 'brightcove' ? 'name' : 'videoTitle']: `Add New ${playlists ? 'Playlist' : 'Video'}`,
        [platform.toLowerCase() === 'brightcove' ? 'id' : 'videoId']: 'NEW',
        [platform.toLowerCase() === 'brightcove' ? 'duration' : 'videoLength']: null,
        techCheck: false
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('videos error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getZoomUsers = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getUsersPromise(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('zoom users error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getZoomUserMeetings = useCallback(async (userId) => {
    const token = oktaAuth.getAccessToken()
    return getUserMeetingsPromise(userId, token).then((res) => {
      res.unshift({
        topic: 'New Meeting',
        id: 'NEW',
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('zoom user meetings error', error)
      }
    })
  }, [oktaAuth])

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const handleSelectSession = useCallback((event, row, selection) => {
    const data = cloneDeep(row)
    try {
      const segmentGoals = data.segmentGoals || {}
      data.audienceSegments = data.audienceSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })
      const virtualSegments = data.virtualSegments || []
      data.virtualSegments = virtualSegments.map(segment => {
        if (segmentGoals[segment.questionKey]) {
          Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
            const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
            if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
          })
        }
        return segment
      })
    } catch (e) {
      console.log(e)
    }
    setSelectedSession(data)
    history.push(`/session/${data.id}/${selection}`)
  }, [history, setSelectedSession])

  // const handleStartVideo = useCallback((id, started) => {
  // // REMOVE OKTA PROMISE HERE
  //   oktaAuth.getAccessToken().then((token) =>
  //     startSessionVideoPromise(id, token)
  //   ).then((res) => {
  //     setUpdate({})
  //   }).catch(error => {
  //     if (error.message === 'Unauthorized') {
  //       oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
  //     } else {
  //       setError('Error Starting Session Video')
  //       setUpdate({})
  //     }
  //   })
  // }, [])

  const handleVideoUpload = useCallback((videoId, platform, [file], isForensic) => {
    // console.log('upload!')
    // console.log(file)

    new Promise((resolve, reject) => {
      try {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          video.duration ? resolve(video.duration * 1000) : reject("Cannot determine video duration.")
        }

        video.onerror = () => {
          reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
      } catch (e) {
        reject(e)
      }
    }).then((videoLength) => {

      const onStarted = (e) => {
        setImportProgress('Uploading Video...')
        setImportStarted(true)
      }
      const onInitiated = (e) => console.log(e)
      const onProgress = (p) => setImportPercent(p * 100)
      const onComplete = () => setImportProgress('Ingesting Video...')
      const onError = (e) => setImportProgress('Error Uploading Video')

      setImportProgress('Requesting Upload...')

      const uploadVideoPromise = platform === 'Brightcove' ? uploadBrightcoveVideoPromise : uploadTicktboxVideoPromise

      const token = oktaAuth.getAccessToken()
      return uploadVideoPromise({ videoId, videoLength, isForensic }, file, token, onStarted, onInitiated, onProgress, onComplete, onError).then((res) => {
        console.log('ok', res)
        setImportComplete(true)
        setImportProgress('Upload Complete')
        setUpdate({})
      })

    }).catch(error => {
      console.log('err', error)
    })

  }, [oktaAuth])

  // const handleFileError = useCallback((err) => {
  //   console.log('error!')
  //   console.log(err)
  // }, [])

  const handleOpenVideoImportDialog = useCallback((event, data) => {
    setImportStarted(false)
    setImportComplete(false)
    setImportPercent(null)
    setImportProgress(null)
    setSessionSelected(data)
    setVideoImportOpen(true);
  }, [setSessionSelected, setVideoImportOpen]);

  const handleCloseVideoImportDialog = useCallback(() => {
    setSessionSelected(null)
    setVideoImportOpen(false);
    setUpdate({})
  }, [setSessionSelected, setVideoImportOpen]);

  const tableColumns = useCallback((filter) => (filter === 'past' ? [
    { title: "ID", field: "id", hidden: true, searchable: true },
    { title: "Session Name", field: "sessionName" },
    { title: "Scheduled Date/Time", field: "scheduledStartTime", render: row => <span>{sessionTime(row.scheduledStartTime)}</span> },
    { title: "Video Title", field: "videoTitle" },
    { title: "Session Start Time", field: "sessionStartTime", render: row => <span>{sessionTime(row.sessionStartTime)}</span> }
  ] : [
    { title: "ID", field: "id", hidden: true, searchable: true },
    { title: "Session Name", field: "sessionName" },
    { title: "Scheduled Date/Time", field: "scheduledStartTime", render: row => <span>{sessionTime(row.scheduledStartTime)}</span> },
    { title: "Video Title", field: "videoTitle" },
  ]).map((x, i) => {
    x.columnIndex = (tableOrder[filter] && tableOrder[filter][x.field] !== undefined) ? tableOrder[filter][x.field] : i
    // console.log('building columns', x, i)
    if (x.field === "scheduledStartTime") {
      x.defaultSort = tableSort[filter] && Object.keys(tableSort[filter]).length ? tableSort[filter] && tableSort[filter][x.field] : 'desc'
    } else {
      x.defaultSort = tableSort[filter] && tableSort[filter][x.field]
    }
    // x.defaultSort = tableSort[filter] && tableSort[filter][x.field]
    // if (x.filtering !== false) x.defaultFilter = tableFilters[filter] && tableFilters[filter][x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [tableSort, tableOrder])

  const tableActions = useMemo(() => {
    // const ax = [{
    //   icon: PeopleAltIcon,
    //   tooltip: 'Manage Guests',
    //   onClick: (e, r) => handleSelectSession(e, r, 'guests')
    // }]
    // if (filter === "today's") {
    //   ax.push({
    //     icon: PlaylistAddCheckIcon,
    //     tooltip: 'Guest Balance',
    //     onClick: (e, r) => handleSelectSession(e, r, 'balance'),
    //     hidden: !user.accessLevels.includes('balance')
    //   }, {
    //     icon: TheatersIcon,
    //     tooltip: 'Manage Video',
    //     onClick: (e, r) => handleSelectSession(e, r, 'video')
    //   })
    // }
    const ax = [{
      icon: PeopleAltIcon,
      tooltip: 'Manage Guests',
      onClick: (e, r) => handleSelectSession(e, r, 'guests'),
      hidden: !user.accessLevels.includes('tc')
    }, {
      icon: PlaylistAddCheckIcon,
      tooltip: 'Guest Balance',
      onClick: (e, r) => handleSelectSession(e, r, 'balance'),
      hidden: !user.accessLevels.includes('balance')
    },
    (rowData) => {
      const focusGroup = rowData.type === 'Focus Group'
      return {
        icon: focusGroup ? VisibilityIcon : TheatersIcon,
        tooltip: `Manage ${focusGroup ? 'Focus Group' : 'Video'}`,
        onClick: (e, r) => handleSelectSession(e, r, 'video')
      }
    },
    (rowData) => {
      return {
        icon: AttachMoneyIcon,
        tooltip: 'Session Rewards',
        onClick: (e, r) => handleSelectSession(e, r, 'rewards'),
        hidden: !user.fullAdmin,
        disabled: !(rowData.started && rowData.complete)
      }
    }]
    return ax
  }, [user, handleSelectSession])

  const sessionsLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const handleOrderChange = useCallback((i, order) => setTableSort(prev => {
    const field = tableColumns(filter)[i]?.field
    return {
      ...prev, [filter]: field ? { [field]: order } : {}
    }
  }), [filter, setTableSort, tableColumns])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns(filter)]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    // console.log('table order change', {[filter]: cols.reduce((p, c, ix) => {
    //   p[c.field] = ix
    //   return p
    // }, {}) })

    setTableOrder(prev => {
      return {
        ...prev, [filter]: cols.reduce((p, c, ix) => {
          p[c.field] = ix
          return p
        }, {})
      }
    })
  }, [tableColumns, filter, setTableOrder])

  const filterSessionsOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [])

  const filterSessionsClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const sessionFilteringOptions = [{
    name: "complete",
    label: "Completed",
    value: showCompleted,
    onChange: (e) => {
      setShowCompleted(e.target.checked);
    }
  }, {
    name: "test",
    label: "Test",
    value: showTest,
    onChange: (e) => {
      setShowTest(e.target.checked);
    }
  }, {
    name: "canceled",
    label: "Canceled",
    value: showCancelled,
    onChange: (e) => {
      setShowCancelled(e.target.checked);
    }
  }, {
    name: "deleted",
    label: "Deleted",
    value: showDeleted,
    onChange: (e) => {
      setShowDeleted(e.target.checked);
    }
  }, {
    name: "dial",
    label: "Dial Session",
    value: showDial,
    onChange: (e) => {
      setShowDial(e.target.checked);
    }
  }, {
    name: "project",
    label: "Project Group",
    value: showProject,
    onChange: (e) => {
      setShowProject(e.target.checked);
    }
  }];

  return (
    <div className={styles.page}>
      <SessionsSubscription refresh={update} onLoaded={sessionsLoaded} filter={filter} key={`${filter}subscription`} />
      {sessionDialogOpen && <SessionModal team={user.team} allowFullEdit={user.accessLevels.includes('session')} allowPostEdit={!!user.fullAdmin} allowVideoEdit={user.accessLevels.includes('video')} data={sessionSelected} getProjects={getProjects} getVideos={getVideos} getYoutubeVideo={getYoutubeVideo} getSurveys={getSurveys} getPFMScreenings={getPFMScreenings} getAppointmentTypes={getAppointmentTypes} getCalendars={getCalendars} getZoomUsers={getZoomUsers} getZoomUserMeetings={getZoomUserMeetings} onSubmit={submitSession} open={sessionDialogOpen} handleClose={handleCloseSessionDialog} />}
      {videoImportOpen && <VideoImportModal session={sessionSelected} onSubmit={handleVideoUpload} open={videoImportOpen} percent={importPercent} progress={importProgress} started={importStarted} complete={importComplete} handleClose={handleCloseVideoImportDialog} />}
      <div className={styles.sessions}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={filterSessionsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            <div className={styles.sessionFilterTitle}>Filter</div>
            <ul className={styles.sessionFilterOptions}>
              {sessionFilteringOptions.map(option => <li key={option.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={option.value}
                      onChange={option.onChange}
                      name={option.name}
                      color="primary"
                    ></Checkbox>
                  }
                  label={option.label}
                ></FormControlLabel>
              </li>)}
            </ul>
          </div>

        </Popover>
        <MaterialTable
          key={`session${filter}table`}
          tableRef={tableRef}
          onOrderChange={handleOrderChange}
          // onFilterChange={(e) => setTableFilters(prev => {
          //   const filters = {}
          //   e.forEach((f) => {
          //     filters[f.column.field] = f.value
          //   })
          //   return { ...prev, [tab]: filters }
          // })}
          onColumnDragged={handleColumnDrag}
          options={{
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelType: 'single',
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100],
            actionsCellStyle: {
              // display: 'flex',
              // justifyContent: 'flex-end',
              marginRight: '5px'
            },
          }}
          columns={tableColumns(filter)}
          // columns={filter === 'past' ? [
          //   { title: "Session Name", field: "sessionName", defaultSort: tableSort[filter] && tableSort[filter][0] },
          //   { title: "Scheduled Date/Time", field: "scheduledStartTime", render: row => <span>{moment(row.scheduledStartTime).format('MMMM Do YYYY, h:mm a')}</span>, defaultSort: tableSort[filter] && Object.keys(tableSort[filter]).length ? tableSort[filter] && tableSort[filter][1] : 'desc' },
          //   { title: "Video Title", field: "videoTitle", defaultSort: tableSort[filter] && tableSort[filter][2] },
          //   { title: "Video Start Time", field: "sessionStartTime", render: row => <span>{moment(row.sessionStartTime).format('MMMM Do YYYY, h:mm a')}</span>, defaultSort: tableSort[filter] && tableSort[filter][3] }
          // ] : [
          //     { title: "Session Name", field: "sessionName", defaultSort: tableSort[filter] && tableSort[filter][0] },
          //     { title: "Scheduled Date/Time", field: "scheduledStartTime", render: row => <span>{moment(row.scheduledStartTime).format('MMMM Do YYYY, h:mm a')}</span>, defaultSort: tableSort[filter] && Object.keys(tableSort[filter]).length ? tableSort[filter] && tableSort[filter][1] : 'desc' },
          //     { title: "Video Title", field: "videoTitle", defaultSort: tableSort[filter] && tableSort[filter][2] },
          //   ]}
          isLoading={!tableSessions || loading}
          data={tableSessions} //MATERIAL TABLE MUTATES PROPS :(
          title={`${filter.charAt(0).toUpperCase() + filter.slice(1)} Sessions`}
          actions={[
            filter === 'past' && {
              icon: FilterListIcon,
              tooltip: 'Filter Sessions',
              onClick: filterSessionsOpen,
              isFreeAction: true,
            },
            // ...tableActions,
            (rowData) => {
              return {
                icon: VideoCallIcon,
                tooltip: `Upload Video to ${rowData.videoPlatform}`,
                onClick: handleOpenVideoImportDialog,
                disabled: rowData.videoUploaded,
                hidden: rowData.type === 'Focus Group' || (['Brightcove', 'ticktBox'].includes(rowData.videoPlatform) ? !!rowData.videoLength : true) || !user.accessLevels.includes('video'),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            ...tableActions,
            // (rowData) => {
            //   return {
            //     icon: 'undo',
            //     tooltip: 'RESET SESSION VIDEO',
            //     onClick: resetSessionVideo,
            //     disabled: (!rowData.sessionStartTime),
            //     hidden: (!rowData.videoLength),
            //     // isLoading: emailLoading.has(rowData.id)
            //   }
            // },
            {
              icon: FileCopyIcon,
              tooltip: 'Clone',
              onClick: cloneSession,
              hidden: !user.accessLevels.includes('session')
            },
            (rowData) => {
              return {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: editSession,
                hidden: !(user.accessLevels.includes('session') || user.accessLevels.includes('video'))
                // disabled: (!!rowData.started),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            // {
            //   icon: 'edit',
            //   tooltip: 'Edit',
            //   onClick: editSession
            // },

            filter !== 'past' ?
              (rowData) => {
                return {
                  icon: 'cancel',
                  tooltip: 'Cancel',
                  onClick: () => { deleteSession(rowData, true) },
                  hidden: !user.accessLevels.includes('session'),
                  disabled: (!!rowData.checkInStarted)
                }
              } : null,
            filter !== 'past' ?
              (rowData) => {
                return {
                  icon: 'delete',
                  tooltip: 'Delete',
                  onClick: () => { deleteSession(rowData, false) },
                  hidden: !user.accessLevels.includes('session'),
                  disabled: (!!rowData.checkInStarted)
                }
              } : null,
          ]}
          // actions={filter === 'upcoming' ? [
          //   {
          //     icon: PeopleAltIcon,
          //     tooltip: 'Manage Guests',
          //     onClick: (e, r) => handleSelectSession(e, r, 'guests')
          //   },
          //   (rowData) => {
          //     return {
          //       icon: VideoCallIcon,
          //       tooltip: 'Upload Video to Brightcove',
          //       onClick: handleOpenVideoImportDialog,
          //       disabled: rowData.videoUploaded,
          //       hidden: rowData.videoLength,
          //       // isLoading: emailLoading.has(rowData.id)
          //     }
          //   },
          //   (rowData) => {
          //     return {
          //       icon: 'undo',
          //       tooltip: 'RESET SESSION VIDEO',
          //       onClick: resetSessionVideo,
          //       disabled: (!rowData.sessionStartTime),
          //       hidden: (!rowData.videoLength),
          //       // isLoading: emailLoading.has(rowData.id)
          //     }
          //   },
          //   {
          //     icon: FileCopyIcon,
          //     tooltip: 'Clone',
          //     onClick: cloneSession
          //   },
          //   (rowData) => {
          //     return {
          //       icon: 'edit',
          //       tooltip: 'Edit',
          //       onClick: editSession,
          //       disabled: (!!rowData.sessionStartTime),
          //       // isLoading: emailLoading.has(rowData.id)
          //     }
          //   },
          //   // {
          //   //   icon: 'edit',
          //   //   tooltip: 'Edit',
          //   //   onClick: editSession
          //   // },
          //   {
          //     icon: 'delete',
          //     tooltip: 'Delete',
          //     onClick: deleteSession
          //   }
          // ] : filter === "today's" ? [
          //   {
          //     icon: PeopleAltIcon,
          //     tooltip: 'Manage Guests',
          //     onClick: (e, r) => handleSelectSession(e, r, 'guests')
          //   },
          // {
          //   icon: TheatersIcon,
          //   tooltip: 'Manage Video',
          //   onClick: (e, r) => handleSelectSession(e, r, 'video')
          // },
          // ] :
          //     [
          //       (rowData) => {
          //         return {
          //           icon: ShowChartIcon,
          //           tooltip: 'View Graph',
          //           hidden: (!rowData.isDialSession),
          //           onClick: showSessionGraph
          //         }
          //       },
          //       {
          //         icon: 'undo',
          //         tooltip: 'RESET SESSION VIDEO',
          //         onClick: resetSessionVideo
          //       },
          //     ]
          // }
          components={{
            // Action: props => {
            //   const action = typeof props.action === "function" ? props.action(props.data) : props.action
            //   return (
            //     <MTableActionLoading {...props} />
            //   )
            // },
            // Actions: props => {
            //   return (
            //     <div className={styles.actionRow}><MTableActions {...props} /></div>
            //   )
            // },
            // Row: props => {
            //   return (
            //     <MTableBodyRow {...props}
            //       onRowClick={(e, row, toggleDetailPanel) => {
            //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
            //         e.stopPropagation();
            //         // openRef.current = openRef.current.id === row.id ? {} : row
            //         setOpenRow(prev => {
            //           const selected = (prev[filter] && prev[filter].id === row.id) ? {} : { id: row.id }
            //           return { ...prev, [filter]: selected }
            //         })
            //       }} />
            //   )
            // }
          }}
          detailPanel={[{
            // disabled: true,
            render: ({ rowData }) => <SessionTableDetails {...rowData} />
          }]}
        // detailPanel={rowData => <SessionTableDetails controlVideo={handleStartVideo} {...rowData} />}
        />
      </div>
      {/* <Snackbar
        // key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        TransitionProps={{
          onExited: handleErrorClear
        }}
        // onExited={handleErrorClear}
        message={error}
        action={
          <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button> * /}
            <IconButton
              aria-label="close"
              color="inherit"
              className={styles.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
      {user.accessLevels.includes('session') && <AddFab show={filter === 'upcoming'} onClick={addSession} />}
      <div className={styles.footer} />
    </div>
  );
})

export default withSuspense(SessionsPage)