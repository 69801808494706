import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocalStorage, } from '../hooks/hooks'
import { Typography, Select, MenuItem, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import CloseIcon from '@material-ui/icons/Close';
import AnnouncementIcon from '@material-ui/icons/Announcement'
import ChatIcon from '@material-ui/icons/Chat'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import MaterialTable, { MTableActions, MTableBodyRow } from "material-table"
import MaterialTable, { MTableActions } from "@material-table/core"
import { withSuspense } from '../hooks/suspense'
import MTableActionLoading from './standard/MTableActionLoading';
import StandardDialog from './standard/StandardDialog';

const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  video: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem'
  },
  videoControls: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '1rem'
  },
  videoButton: {
    margin: '6px'
  },
  sessions: {
    marginBottom: '1rem',
    width: '100%'
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  messageRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  selectField: {
    flexBasis: '80%',
  },
  titlegroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  tabs: {
    justifyContent: 'space-between'
  },
  tabBadge: {
    // position: 'absolute'
    paddingLeft: '16px',
    paddingBottom: '3px'
  },
  dialog: {
    width: '50%',
    minWidth: '500px'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const SessionTechCheckView = React.memo(({ sessionId, connections, guests, editGuest, disableChat, openChat, sendGuestMessage, startVideo, approveGuest, denyGuest, resetGuest, waitlistGuest, approveLoading, waitlistLoading, denyLoading, resetLoading, techCheckNote }) => {
  // console.log('render video page')
  const [tableConnections, setTableConnections] = useState(undefined)
  const styles = useStyles();
  const [messageBoxOpen, setMessageBoxOpen] = useState(false)
  const [messageGuest, setMessageGuest] = useState()
  const [messageRole, setMessageRole] = useState('ALL')
  const [messageStatus, setMessageStatus] = useState()
  // const [ejectWarnOpen, setEjectWarnOpen] = useState(false)
  // const [guestToEject, setGuestToEject] = useState(null)
  const [tableFilters, setTableFilters] = useLocalStorage(`session${sessionId}tctablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${sessionId}tctablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${sessionId}tctableorder`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`session${sessionId}tcopenrow`, {})
  const [techCheckNoteShowed, setTechCheckNoteShowed] = useLocalStorage(`session${sessionId}techchecknote`, {});
  // const [currentFilter, setCurrentFilter] = useState([]);
  // const [displayNote, setDisplayNote] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const noteRef = useRef(techCheckNoteShowed)
  const connectionsRef = useRef(tableConnections)
  const tableRef = useRef()
  // const openRef = useRef(openRow)

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    noteRef.current = techCheckNoteShowed
  }, [techCheckNoteShowed])

  const dismissBtn = useCallback((id) => {
    return <>
      <IconButton
        aria-label="close"
        color="inherit"
        // className={styles.close} //STYLE NOT DEFINED
        onClick={() => {
          setTechCheckNoteShowed({acknowledged: true, text: techCheckNote});
          closeSnackbar(id);
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  }, [techCheckNote, setTechCheckNoteShowed, closeSnackbar]);

  useEffect(() => {
    // console.log(connections)
    if (connections && guests) {
      setTableConnections(connections ? cloneDeep(Object.values(connections)).map((c) => {
        const guest = guests.find(g => `${g.id}` === `${c.userId}`) || {}
        c.currentStatus = c.userStatus + (c.userSubstatus ? ` - ${c.userSubstatus}` : '')
        if (!['Survey', 'Complete', 'Approved', 'Waitlisted', 'Denied', 'Blocked'].includes(c.userStatus)) {
          if (c.disconnected) c.currentStatus = "Disconnected"
        }

        // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
        // if (tableRef.current) {
        //   const { detailPanel } = tableRef.current.props;
        //   let handleShowDetailPanel = detailPanel;

        //   if (typeof detailPanel === 'object') {
        //     handleShowDetailPanel = detailPanel[0].render
        //   }

        //   c.tableData = {
        //     showDetailPanel: openRef.current && (`${c.userId}` === `${openRef.current.id}` && handleShowDetailPanel)
        //   }
        // }

        return { ...guest, ...c }
      }).filter(x => !!x.id && !['Approved', 'Waitlisted', 'Denied', 'Removed'].includes(x.userStatus) && !x.canceled) : undefined)//.reduce((r, v, i, a, k = v.currentStatus) => ((r[k] || (r[k] = [])).push(cloneDeep(v)), r), {}) : undefined)
    }
  }, [connections, guests])

  useEffect(() => {
    // console.log('tech check connections', tableConnections)
    connectionsRef.current = tableConnections
  }, [tableConnections])

  const tableColumns = useCallback(() => [
    { title: "First Name", field: "firstName", filtering: false },
    { title: "Last Name", field: "lastName", filtering: false },
    { title: "Email", field: "email", filtering: false },
    { title: "Zoom Alias", field: "alias", filtering: false },
    {
      title: "Current Status",
      field: "currentStatus",
      filtering: false,
      render: rowData => <Typography variant="h6" style={{ color: 'green' }}>{rowData.currentStatus}</Typography>
    }
  ].map((x, i) => {
    x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
    x.defaultSort = tableSort[x.field]
    if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [tableSort, tableOrder, tableFilters])

  useEffect(() => {
    return () => {
      closeSnackbar();
    }
  }, [closeSnackbar])

  useEffect(() => {
    if (techCheckNote && (noteRef.current?.text !== techCheckNote || !noteRef.current?.acknowledged)) {
      // setDisplayNote(true)
      enqueueSnackbar(`Tech Check Note: ${techCheckNote}`, {
        preventDuplicate: true, action: dismissBtn, anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }, variant: 'info', autoHideDuration: null
      });
      setTechCheckNoteShowed({
        text: techCheckNote,
        acknowledged: false
      });
    }
  }, [techCheckNote, enqueueSnackbar, dismissBtn, setTechCheckNoteShowed])

  const openMessageDialog = useCallback((event, data, currentTab) => {
    setMessageGuest(data);
    setMessageStatus(currentTab);
    setMessageBoxOpen(true)
  }, []);

  // const handleCloseNote = useCallback((event) => {
  //   setDisplayNote(false)
  // }, []);

  const handleMessageStatus = useCallback((event) => {
    setMessageStatus(event.target.value);
  }, []);

  const handleMessageRole = useCallback((event) => {
    setMessageRole(event.target.value);
  }, []);

  const sendMessage = useCallback((event, user, { message }, { status, role }) => {
    if (user) {
      sendGuestMessage(user, message)
    } else {
      sendGuestMessage({
        connectionId: 'ALL',
        audience: role || 'ALL',
        status: status || user.userStatus
      }, message)
    }
    setMessageBoxOpen(false)
    setMessageGuest(null);
    setMessageRole('ALL')
  }, [sendGuestMessage]);

  // const openEjectWarning = useCallback((event, data) => {
  //   console.log(data)
  //   setGuestToEject(data);
  //   setEjectWarnOpen(true)
  // }, []);

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    const field = tableColumns()[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleFilterChange = useCallback((e) => setTableFilters(prev => {
    const filters = {}
    e.forEach((f) => {
      filters[f.column.field] = f.value
    })
    return filters
  }), [setTableFilters])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  const openUserChat = useCallback((event, guest) => openChat(guest, true), [openChat])

  const messageBoxContent = useMemo(() => (
    !messageGuest && <>
      <div className={styles.messageRow}>
        <Typography className={styles.label}>Role:</Typography>
        <Select
          value={messageRole}
          onChange={handleMessageRole}
          // displayEmpty
          className={styles.selectField}
        >
          <MenuItem value='ALL'>ALL</MenuItem>
          <MenuItem value='Viewer'>Viewer</MenuItem>
          <MenuItem value='Client'>Client</MenuItem>
          <MenuItem value='VIP'>VIP</MenuItem>
        </Select>
      </div>
      <div className={styles.messageRow}>
        <Typography className={styles.label}>Status:</Typography>
        <Select
          value={messageStatus}
          onChange={handleMessageStatus}
          // displayEmpty
          className={styles.selectField}
        >
          <MenuItem value='ALL'>ALL</MenuItem>
          <MenuItem value='Not Ready'>Not Ready</MenuItem>
          <MenuItem value='Waiting'>Waiting</MenuItem>
          <MenuItem value='Watching'>Watching</MenuItem>
        </Select>
      </div>
    </>
  ), [styles, messageGuest, messageRole, messageStatus, handleMessageRole, handleMessageStatus])

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        open={displayNote}
        // autoHideDuration={6000}
        // onClose={handleAlertClose}
      >
        <Alert 
            onClose={handleCloseNote}
            severity='info'
            // color='warning'
            variant='filled'
            // classes={{
            //     root: styles.toastBackgroundColor,
            //     icon: styles.toastIconColor,
            //     message: styles.toastTextColor,
            //     action: styles.toastTextColor
            // }}
        >
            {techCheckNote}
        </Alert>
      </Snackbar> */}
      <MaterialTable
        // style={{ zIndex: 1 }}
        tableRef={tableRef}
        // onSearchChange={(e) => console.log("search changed: " + e)}
        // onOrderChange={(i, order) => setTableSort({ [i]: order })}
        onOrderChange={handleOrderChange}
        onFilterChange={handleFilterChange}
        onColumnDragged={handleColumnDrag}
        key={`session${sessionId}techchecktable`}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          detailPanelType: 'single',
          // toolbarButtonAlignment: 'left',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100]
        }}
        columns={tableColumns()}
        // columns={[
        //   { title: "First Name", field: "firstName", filtering: false, defaultSort: tableSort[0] },
        //   { title: "Last Name", field: "lastName", filtering: false, defaultSort: tableSort[1] },
        //   { title: "Email", field: "email", filtering: false, defaultSort: tableSort[2] },
        //   { title: "Zoom Alias", field: "alias", filtering: false, defaultSort: tableSort[3] },
        //   {
        //     title: "Current Status",
        //     field: "currentStatus",
        //     filtering: false,
        //     defaultSort: tableSort[4],
        //     render: rowData => <Typography variant="h6" style={{color: 'green'}}>{rowData.currentStatus}</Typography>
        //   }
        // ]}
        isLoading={!(guests && connections && tableConnections)}
        // data={(!!(tableConnections) && tableConnections[tab]) || undefined} //MATERIAL TABLE MUTATES PROPS :(
        data={tableConnections} //MATERIAL TABLE MUTATES PROPS :(
        // title={`Registered Guests`}
        title='Tech Check'
        actions={[
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Guest',
            onClick: editGuest,
            disabled: rowData.selfGuided
          }),
          (rowData) => ({
            icon: PlayArrowIcon,
            tooltip: 'Start Video',
            onClick: () => startVideo(rowData.userId),
            disabled: rowData.selfGuided || !rowData.connectionId || rowData.currentStatus !== 'Waiting'
          }),
          (rowData) => ({
            icon: ChatIcon,
            tooltip: 'Open Chat',
            onClick: openUserChat,
            disabled: rowData.selfGuided || disableChat
          }),
          (rowData) => ({
            icon: AnnouncementIcon,
            tooltip: 'Send Message',
            onClick: openMessageDialog,
            disabled: rowData.selfGuided || !rowData.connectionId
          }),
          (rowData) => ({
            icon: ThumbUpIcon,
            tooltip: 'Approve Guest',
            onClick: approveGuest,
            isLoading: approveLoading.has(rowData.id),
            disabled: rowData.selfGuided || (rowData.currentStatus !== 'Complete' && rowData.userStatus !== 'Discussion')
          }),
          (rowData) => ({
            icon: HourglassEmptyIcon,
            tooltip: 'Waitlist Guest',
            onClick: waitlistGuest,
            isLoading: waitlistLoading.has(rowData.id),
            disabled: rowData.selfGuided || !['Complete', 'Disconnected'].includes(rowData.currentStatus)
          }),
          (rowData) => ({
            icon: RotateLeftIcon,
            tooltip: 'Reset Guest',
            onClick: resetGuest,
            isLoading: resetLoading.has(rowData.id),
            disabled: !rowData.selfGuided
          }),
          (rowData) => ({
            icon: 'clear',
            tooltip: 'Deny Guest',
            onClick: denyGuest,
            isLoading: denyLoading.has(rowData.id),
            // disabled: rowData.selfGuided
          })
        ]}
        components={{
          Action: props => {
            // const action = typeof props.action === "function" ? props.action(props.data) : props.action
            return (
              // <div className={styles.action}>
              //   <Typography>{JSON.stringify(typeof props.action)}</Typography>
              //   {action.isLoading && <CircularProgress size={48} className={styles.actionLoading} />}
              //   <MTableAction {...props} />
              // </div>
              <MTableActionLoading {...props} />
            )
          },
          Actions: props => {
            return (
              <div className={styles.actionRow}><MTableActions {...props} /></div>
            )
          },
          // Row: props => {
          //   return (
          //     <MTableBodyRow {...props}
          //       onRowClick={(e, row, toggleDetailPanel) => {
          //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
          //         e.stopPropagation();
          //         // openRef.current = openRef.current.id === row.id ? {} : row
          //         setOpenRow(prev => prev.id === row.id ? {} : { id: row.id })
          //       }}
          //     />
          //   )
          // }
        }}
      // detailPanel={[{
      //   disabled: true,
      //   render: ({ rowData }) => <GuestTableDetails {...rowData} />
      // }]}
      />
      <StandardDialog
        open={messageBoxOpen}
        title={messageGuest ? `Send Message to ${messageGuest.firstName} ${messageGuest.lastName}` : `Send Group Message`}
        content={messageBoxContent}
        fieldOptions={[
          {
            label: 'Chat Message',
            key: 'message',
            type: 'text',
            multiline: true,
            required: true
          }
        ]}
        submitLabel="Send"
        onSubmit={(e, t) => {
          sendMessage(e, messageGuest, t, { status: messageStatus, role: messageRole })
        }}
        // cancelLabel="NO"
        onCancel={() => {
          setMessageBoxOpen(false)
        }}
      />
    </>
  );
})

export default withSuspense(SessionTechCheckView)