import React, { useRef, useCallback, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import App from './App';
import OktaLogin from './pages/OktaLogin';
// import LoginCallback from './components/auth/LoginCallback'
// import SecureRoute from './components/auth/SecureRoute'
import config from './config.json'

const oktaAuthObj = new OktaAuth({
    issuer: config.oktaConfig.issuer,
    clientId: config.oktaConfig.clientId,
    originalUri: window.location.origin,
    redirectUri: window.location.origin + '/authcallback',
    responseType: ['id_token', 'token'],
    scopes: ['openid', 'email', 'profile', 'groups', 'appdata', 'offline_access'],
    pkce: false
});

oktaAuthObj.tokenManager.on("added", (key, newToken) => {
    console.log(`OKTA token added`)
})

oktaAuthObj.tokenManager.on("error", (error) => {
    console.log('OKTA ERROR', JSON.stringify(error))
})
oktaAuthObj.tokenManager.on("expired", (key, token) => {
    console.log("OKTA token has expired")
})

oktaAuthObj.tokenManager.on('renewed', function (key, newToken, oldToken) {
    console.log("OKTA renewed----", newToken, ('accessToken' in newToken));
});

const baseUrl = config.oktaConfig.issuer.replace(/\/oauth2\/.+/, '')

const AppRoutes = React.memo(({ history, location: { pathname } }) => {
    const redirected = useRef()

    const onAuthRequired = useCallback(() => {
        console.log("LOGIN REQUIRED")
        if (pathname !== '/login' && pathname !== '/authcallback' && !redirected.current) {
            // oktaAuth.setFromUri(pathname)
            history.push('/login')
            redirected.current = true
        }
    }, [history, pathname])

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri === '/authcallback' ? '/' : originalUri, window.location.origin));
    };

    // const onSessionExpired = useCallback(() => {
    //     console.log("EXPIRED")
    //     if (pathname !== '/login' && pathname !== '/authcallback' && !redirected.current) {
    //         // oktaAuth.setFromUri(pathname)
    //         history.push('/login')
    //         redirected.current = true
    //     }
    // }, [history, pathname])

    return (
        <Security
            oktaAuth={oktaAuthObj}
            onAuthRequired={onAuthRequired}
            // onSessionExpired={onSessionExpired}
            restoreOriginalUri={restoreOriginalUri}
        >
            {/* <Switch> */}
            <Route exact path='/login' render={() => <OktaLogin baseUrl={baseUrl} />} />
            <Route exact path='/authcallback' component={LoginCallback} />
            <SecureRoute path="**" component={App} />
            {/* </Switch> */}
        </Security>
    )
})

export default withRouter(AppRoutes);