import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocalStorage, clearLocalStorage } from '../hooks/hooks'
import { useClipboard } from 'use-clipboard-copy';
import { AppBar, Tabs, Tab, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChatIcon from '@material-ui/icons/Chat'
import MaterialTable, { MTableActions, MTableBodyRow } from "@material-table/core"
import { withSuspense } from '../hooks/suspense'
import MTableActionLoading from './standard/MTableActionLoading';

const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  video: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem'
  },
  videoControls: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '1rem'
  },
  videoButton: {
    margin: '6px'
  },
  videoData: {
    flex: 1
  },
  clientViewers: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'max-content',
    padding: '0.5rem'
  },
  popover: {
    pointerEvents: 'none',
  },
  // clientBadge: {
  //   marginLeft: '0.5rem',
  //   marginTop: 'auto',
  //   marginBottom: 'auto'
  // },
  sessions: {
    marginBottom: '1rem',
    width: '100%'
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chatRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  selectField: {
    flexBasis: '80%',
  },
  titlegroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  tabs: {
    justifyContent: 'space-between'
  },
  tabBadge: {
    // position: 'absolute'
    paddingLeft: '16px',
    paddingBottom: '3px'
  },
  dialog: {
    width: '50%',
    minWidth: '500px'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const tabs = [
  'Not Arrived',
  'Arrived',
  'Disconnected'
]

const SessionClientView = React.memo(({ sessionId, allowPii, allowIp, connections, clients, disableChat, openChat, uncheckInGuest, uncheckInLoading }) => {
  // console.log('render client page')
  const [tableConnections, setTableConnections] = useState(undefined)
  const styles = useStyles();
  const [tab, setTab] = useLocalStorage(`session${sessionId}clienttab`, 'Not Arrived')
  const [tableFilters, setTableFilters] = useLocalStorage(`session${sessionId}clienttablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${sessionId}clienttablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${sessionId}clienttableorder`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`session${sessionId}clientopenrow`, {})

  const clipboard = useClipboard({
    copiedTimeout: 1500
  });

  const copyLinkUrl = useCallback((ev, { viewerLinkUrl }) => {
    // ev.preventDefault();
    ev.stopPropagation();

    clipboard.copy(viewerLinkUrl);
    // if (!navigator.clipboard) return;
    // navigator.clipboard.writeText(ax).then(() => alert('Copied to clipboard')).catch((err) =>console.error('Failed to copy!', err))

  }, [clipboard])

  const clearAllTableFilters = useCallback(() => {
    const currentTableFilers = [
      `session${sessionId}clienttablefilter`,
      `session${sessionId}clienttablesort`,
      `session${sessionId}clienttableorder`
    ];
    clearLocalStorage(currentTableFilers);
    setTableFilters({});
    setTableSort({});
    setTableOrder({});
  }, [sessionId, setTableFilters, setTableSort, setTableOrder]);

  const connectionsRef = useRef(tableConnections)
  const tableRef = useRef()
  // const openRef = useRef(openRow)

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    // console.log('CLIENT CONNECTIONS: ', connections)
    if (connections && clients) {
      setTableConnections(connections ? cloneDeep(Object.values(connections)).map((c) => {
        const client = clients.find(g => `${g.id}` === `${c.userId}`) || {}
        if (!['Not Arrived', 'Removed'].includes(c.userStatus)) {
          if (c.disconnected) c.userStatus = "Disconnected"
        }

        // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
        // if (tableRef.current) {
        //   const { detailPanel } = tableRef.current.props;
        //   let handleShowDetailPanel = detailPanel;

        //   if (typeof detailPanel === 'object') {
        //     handleShowDetailPanel = detailPanel[0].render
        //   }

        //   c.tableData = {
        //     showDetailPanel: openRef.current[c.userStatus] && (`${c.userId}` === `${openRef.current[c.userStatus].id}` && handleShowDetailPanel)
        //   }
        // }

        return { ...client, ...c }
      }).reduce((r, v, i, a, k = v.userStatus) => ((r[k] || (r[k] = [])).push(cloneDeep(v)), r), {}) : undefined)
    }
  }, [connections, clients])

  useEffect(() => {
    connectionsRef.current = tableConnections
  }, [tableConnections])

  const handleTabChange = useCallback((event, newTab) => {
    setTab(newTab);
  }, [setTab]);

  const tableColumns = useCallback(() => [
    ...(allowPii ? [
      { title: "First Name", field: "firstName", filtering: false },
      { title: "Last Name", field: "lastName", filtering: false }
    ] : []),
    { title: "Email", field: "email", filtering: false },
    { title: "Zoom Alias", field: "alias", filtering: false }
  ].map((x, i) => {
    x.columnIndex = (tableOrder[tab] && tableOrder[tab][x.field] !== undefined) ? tableOrder[tab][x.field] : i
    x.defaultSort = tableSort[tab] && tableSort[tab][x.field]
    if (x.filtering !== false) x.defaultFilter = tableFilters[tab] && tableFilters[tab][x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [allowPii, tab, tableSort, tableFilters, tableOrder])

  const openUserChat = useCallback((event, guest) => openChat(guest), [openChat])

  const tableActions = useMemo(() => {
    const actions = [
      {
        icon: ClearAllIcon,
        tooltip: 'Clear All Filters',
        onClick: clearAllTableFilters,
        isFreeAction: true,
      },
      (rowData) => {
        return {
          icon: AssignmentIcon,
          tooltip: clipboard.copied ? 'Link Copied to Clipboard' : 'Copy Link URL',
          onClick: copyLinkUrl,
          hidden: !rowData.viewerLinkUrl
        }
      }
    ]
    if (['Not Arrived', 'Disconnected'].includes(tab)) actions.push(
      (rowData) => {
        return {
          icon: 'undo',
          tooltip: 'Uncheck-In Client',
          onClick: uncheckInGuest,
          isLoading: uncheckInLoading.has(rowData.id)
          // hidden: ['Survey', 'Complete', 'Disconnected'].includes(tab)
        }
      })
    if (['Arrived', 'Disconnected'].includes(tab)) actions.push(
      {
        icon: ChatIcon,
        tooltip: 'Open Chat',
        onClick: openUserChat,
        disabled: disableChat
      }
    )
    return actions
  }, [tab, uncheckInLoading, uncheckInGuest, disableChat, clearAllTableFilters, openUserChat, copyLinkUrl, clipboard.copied])


  const handleOrderChange = useCallback((i, order) => setTableSort(prev => {
    const field = tableColumns()[i]?.field
    return {
      ...prev, [tab]: field ? { [field]: order } : {}
    }
  }), [tableColumns, tab, setTableSort])

  const handleFilterChange = useCallback((e) => setTableFilters(prev => {
    const filters = {}
    e.forEach((f) => {
      filters[f.column.field] = f.value
    })
    return { ...prev, [tab]: filters }
  }), [tab, setTableFilters])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(prev => {
      return {
        ...prev, [tab]: cols.reduce((p, c, ix) => {
          p[c.field] = ix
          return p
        }, {})
      }
    })
  }, [tableColumns, tab, setTableOrder])


  return (
    <>
      <AppBar position="relative" color="default" elevation={2}>
        <Tabs
          value={tab}
          key={`client${sessionId}tabs`}
          onChange={handleTabChange}
          // variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            flexContainer: styles.tabs
          }}
        >
          {tabs && tabs.map((tb) => (
            <Tab key={`tab-${tb}`} label={
              <div>
                {tb}
                <Badge color="secondary" max={999} badgeContent={(tableConnections && tableConnections[tb] && tableConnections[tb].length) || '0'}>
                  <div className={styles.tabBadge} />
                </Badge>
              </div>
            } value={tb} />
          ))}
        </Tabs>
      </AppBar>
      <MaterialTable
        tableRef={tableRef}
        onOrderChange={handleOrderChange}
        onFilterChange={handleFilterChange}
        onColumnDragged={handleColumnDrag}
        key={`session${sessionId}clienttable${tab}`}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          detailPanelType: 'single',
          // toolbarButtonAlignment: 'left',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100]
        }}
        columns={tableColumns()}
        isLoading={!(clients && connections && tableConnections)}
        data={(!!(tableConnections && tableConnections[tab]) && tableConnections[tab].map(x => x)) || undefined} //MATERIAL TABLE MUTATES PROPS :(
        title={tab}
        actions={tableActions}
        components={{
          Action: props => {
            return (
              <MTableActionLoading {...props} />
            )
          },
          Actions: props => {
            return (
              <div className={styles.actionRow}><MTableActions {...props} /></div>
            )
          },
          // Row: props => {
          //   return (
          //     <MTableBodyRow {...props}
          //       onRowClick={(e, row, toggleDetailPanel) => {
          //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
          //         e.stopPropagation();
          //         // openRef.current = openRef.current.id === row.id ? {} : row
          //         setOpenRow(prev => {
          //           const selected = (prev[tab] && prev[tab].id === row.id) ? {} : { id: row.id }
          //           return { ...prev, [tab]: selected }
          //         })
          //       }} />
          //   )
          // }
        }}
      // detailPanel={[{
      //   disabled: true,
      //   render: ({ rowData }) => <GuestTableDetails allowPii={allowPii} allowIp={allowIp} {...rowData} />
      // }]}
      />
    </>
  );
})

export default withSuspense(SessionClientView)