import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUser, bannedList } from '../recoil/atoms'
import { useLocalStorage } from '../hooks/hooks'
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
// import MaterialTable from "material-table"
import MaterialTable from "@material-table/core"
import AddFab from '../components/AddFab';
import BannedModal from '../components/BannedModal';
import { useSnackbar } from 'notistack'
import BannedSubscription from '../recoil/BannedSubscription';
import { withSuspense } from '../hooks/suspense'
import { deleteBannedPromise, insertBannedPromise, updateBannedPromise } from '../services/bannedService'
import { useOktaAuth } from '@okta/okta-react';

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const useStyles = makeStyles({
  banned: {
    marginBottom: '1rem',
    width: '100%'
  },
  page: {
    padding: '1em'
  },
  footer: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
  names: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
})

const DismissBtn = (id) => {
  const { closeSnackbar } = useSnackbar();
  return <>
      <IconButton
          aria-label="close"
          color="inherit"
          // className={styles.close} //NO CLASS DEFINED
          onClick={() => closeSnackbar(id)}
      >
          <CloseIcon />
      </IconButton>
  </>
}

const BannedPage = React.memo((props) => {
  const banned = useRecoilValue(bannedList);
  const user = useRecoilValue(currentUser);
  // const [tableBanned, setTableBanned] = useState(undefined)
  const styles = useStyles();
  const [bannedSelected, setBannedSelected] = useLocalStorage('bannedSelected', null)//useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState({});
  const [bannedDialogOpen, setBannedDialogOpen] = useLocalStorage('bannedDialogState', false) //useState(false);
  // const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar()
  // const [errorOpen, setErrorOpen] = useState(false)
  const [tableSort, setTableSort] = useLocalStorage(`bannedtablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`bannedtableorder`, {})
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const tableRef = useRef()

  useEffect(() => {
    if (!user.fullAdmin) {
      history.push(`/sessions`)
    }
  }, [user, history])

  const handleCloseBannedDialog = useCallback(() => {
    // clearLocalStorage();
    setBannedDialogOpen(false);
    setBannedSelected(null)
  }, [setBannedSelected, setBannedDialogOpen]);

  const submitBanned = useCallback((event, submitData) => {
    setLoading(true)
    const data = (bannedSelected && bannedSelected.id) ? difference(bannedSelected, submitData) : cloneDeep(submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {

      if (bannedSelected) {
        data.id = bannedSelected.id
      }
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
      }
      if (data.tableData) delete data.tableData

      let submitPromise
      if (!data.id) {
        data.createdBy = user.email
        data.lastUpdatedBy = user.email
        submitPromise = insertBannedPromise
      } else {
        data.lastUpdatedBy = user.email
        submitPromise = updateBannedPromise
      }
      const token = oktaAuth.getAccessToken()
      submitPromise(data, token).then((res) => {
        setUpdate({})
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Banned')
          enqueueSnackbar('Error Saving banned', { preventDuplicate: true, action: DismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      setUpdate({})
      setLoading(false)
    }
    setBannedDialogOpen(false);
    // clearLocalStorage();
    setBannedSelected(null)
  }, [bannedSelected, oktaAuth, setBannedSelected, setBannedDialogOpen, enqueueSnackbar, user.email]);

  const addBanned = useCallback((event, data) => {
    setBannedSelected(null);
    setBannedDialogOpen(true);
  }, [setBannedSelected, setBannedDialogOpen])

  const editBanned = useCallback((event, data) => {
    // const rowData = cloneDeep(data)
    // delete rowData.tableData // REMOVE TABLE DATA PROP
    setBannedSelected(data);
    setBannedDialogOpen(true);
  }, [setBannedSelected, setBannedDialogOpen])

  const deleteBanned = useCallback((event, { id, tableData }) => {
    setLoading(true)
    const token = oktaAuth.getAccessToken()
    deleteBannedPromise(id, token).then((res) => {
      setUpdate({})
      setLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Deleting Banned')
        enqueueSnackbar('Error Deleting Banned', { preventDuplicate: true, action: DismissBtn });
        setUpdate({})
        setLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const tableColumns = useCallback(() => ([
    { title: "Email", field: "email" },
    { title: "Ban Reason", field: "banReason" }
  ]).map((x, i) => {
    x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
    x.defaultSort = tableSort[x.field]
    // if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [tableSort, tableOrder])

  const bannedLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    const field = tableColumns()[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  return (
    <div className={styles.page}>
      <BannedSubscription refresh={update} onLoaded={bannedLoaded} key={`bannedsubscription`} />
      {bannedDialogOpen && <BannedModal data={bannedSelected} onSubmit={submitBanned} open={bannedDialogOpen} handleClose={handleCloseBannedDialog} />}
      <div className={styles.banned}>
        <MaterialTable
          key={`banned-table`}
          tableRef={tableRef}
          onOrderChange={handleOrderChange}
          onColumnDragged={handleColumnDrag}
          options={{
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelType: 'single',
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100],
            actionsCellStyle: {
              // display: 'flex',
              // justifyContent: 'flex-end',
              marginRight: '5px'
            },
          }}
          columns={tableColumns()}
          isLoading={!banned || loading}
          data={banned || []} //MATERIAL TABLE MUTATES PROPS :(
          title="Banned Emails"
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: editBanned,
              hidden: !user.fullAdmin,
            },
            {
              icon: 'delete',
              tooltip: 'Delete',
              onClick: deleteBanned,
              hidden: !user.fullAdmin,
            }
          ]}
        />
      </div>
      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        TransitionProps={{
          onExited: handleErrorClear
        }}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={styles.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
      {user.fullAdmin && <AddFab show={true} onClick={addBanned} />}
      <div className={styles.footer} />
    </div>
  );
})

export default withSuspense(BannedPage)