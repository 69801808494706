import config from '../config.json'
import worker from 'workerize-loader?inline!../utils/fetchWorker' // eslint-disable-line import/no-webpack-loader-syntax

const fetchWorker = worker()

export const getSessionsPromise = ({ includeDeleted, offset }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/sessions?offset=${offset || 0}&includeDeleted=${!!includeDeleted}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data //data.records || data.Items
    })

// export const getAllSessions = (includeDeleted, token, sessions = []) => getSessionsPromise({includeDeleted}, sessions.length, token).then((resp) => {
//     sessions.push(...resp.records)
//     const more = sessions.length !== resp.count;
//     if (more) {
//         return getAllSessions(includeDeleted, token, sessions)
//     } else {
//         return sessions
//     }
// })

export const getAllSessions = ({ includeDeleted = false, offset = 0 }, token) =>
    fetchWorker.threadedFetch(`https://${config.rest.sessionManagementAPI}/sessions`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        params: { includeDeleted, offset }
    }, true).then(data => {
        return data
    })

export const getAllSessionFilesPromise = (sessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session/files?sessionId=${sessionId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json().catch(err => {
                return []; // to catch no response from .json()
            })
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const getSessionFilePromise = (sessionId, filename, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session/file?sessionId=${sessionId}&filename=${filename}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const deleteSessionFilePromise = (sessionId, filename, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session/file?sessionId=${sessionId}&filename=${filename}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const uploadSessionFilePromise = (file, sessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session/file`, { // need to save the filename to the session
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            filename: file.name,
            filetype: file.type,
            sessionId,
        })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(signedResponseData => {
        const formData = new FormData();
        Object.entries(signedResponseData.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", file);
        return fetch(signedResponseData.url, {
            method: 'POST',
            mode: 'cors',
            body: formData

        })
    }).then(response => { // save filename to session
        if (response.status >= 200 && response.status <= 299) {
            return response
        } else {
            throw response
        }
    })


export const getSessionPromise = (sessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session?sessionId=${sessionId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return (data.records && data.records[0]) || data.Item
    })


export const deleteSessionPromise = (sessionId, token, cancel = false) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session${cancel ? '?cancel=true' : ''}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: sessionId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data;
    })

export const insertSessionPromise = (session, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(session)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateSessionPromise = (session, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(session)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const startSessionVideoPromise = (sessionId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/video-start`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id: sessionId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })