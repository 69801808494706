import React, { useState, useEffect } from 'react';
import { Chip, Divider, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
// import PersonIcon from '@material-ui/icons/Person';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// import FaceIcon from '@material-ui/icons/Face';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import ScheduleIcon from '@material-ui/icons/Schedule';
// import VideocamIcon from '@material-ui/icons/Videocam';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
// import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
// import TheatersIcon from '@material-ui/icons/Theaters';
// import MovieIcon from '@material-ui/icons/Movie';
// import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import NotesIcon from '@material-ui/icons/Notes';
import BlockIcon from '@material-ui/icons/Block';
// import HttpIcon from '@material-ui/icons/Http';
import moment from 'moment'
import UAParser from 'ua-parser-js'

const parser = new UAParser();

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '2em'
    },
    category: {
        // flexBasis: '20%'
        textAlign: 'right',
        marginRight: '6px'
    },
    info: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-evenly',
        width: '100%'
    },
    segment: {
        maxWidth: '400px',
        minWidth: '300px',
        width: '40%',
        // marginLeft: 'auto',
        margin: '12px 24px 12px 24px',
        padding: '6px',
        border: '2px solid #eee',
        borderRadius: '8px',
    },
    question: {
        display: 'flex',
        flexDirection: 'row',
    },
    answers: {
        display: 'flex',
        flexDirection: 'column',
    },
    box: {
        margin: '6px'
    },
    group: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px',
        borderBottom: '2px solid black'
    },
    section: {
        marginBottom: '6px'
    },
    infogroup: {
        margin: '6px auto 6px 1em'
    },
    marker: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    sidemargin: {
        margin: '0 3px 0 3px'
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    titlegroup: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px'
    },
    titlerow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10rem' //for photo
    },
    chip: {
        margin: '1px',
        fontSize: '0.6125rem'
    },
    pfm: {
        margin: '1px',
        backgroundColor: '#6C54A4'
    },
    textIcon: {
        marginRight: '0.5em'
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    youtube: {
        margin: '1px',
        backgroundColor: '#e52d27'//'#b31217'
    },
    photo: {
        height: '100%',
        width: '100%'
    },
    banBox: {
        marginLeft: '6px'
    }
})

// const LabelBadge = withStyles((theme) => ({
//     badge: {
//         transform: 'scale(1) translate(110%, -50%)'
//     },
// }))(Badge);

const emailTypes = {
    schedule: "Registration Reminder Link",
    verify: "Self-Guided Tech Check Link",
    event: "Event Link (Re-Send)",
    custom: "Custom Email"
}

const GuestTableDetails = React.memo(({ id, sessionId, email, emailVerified, firstName, lastName, phoneNumber, role, alias, state, segments, emailSends, created, createdBy, checkedInTime, checkedInBy, lastUpdated, lastUpdatedBy, notes, photo, ejectReason, autoDenied, banned, deleted, userStatus, userSubstatus, videoTime, videoLength, unloadTime, ipAddress, userBrowser, allowPii, allowIp, getGuestPhoto }) => {
    const styles = useStyles();
    const [browserInfo, setBrowserInfo] = useState()
    const [photoUrl, setPhotoUrl] = useState()

    useEffect(() => {
        if (!!userBrowser) {
            parser.setUA(userBrowser);
            const result = parser.getResult()
            setBrowserInfo(result)
        }
    }, [userBrowser])

    useEffect(() => {
        if (!photoUrl && !!photo && !!getGuestPhoto) {
            getGuestPhoto(id, photo).then((url) => {
                setPhotoUrl(url)
            }).catch((err) => {
                console.log('PHOTO ERROR', err)
            })
        }
    }, [id, photo, getGuestPhoto, photoUrl, setPhotoUrl])

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div className={styles.titlerow}>
                    <div className={styles.titlegroup}>
                        {/* <Typography variant="h4">{firstName} {lastName}</Typography> */}
                        <div className={styles.textRow}>
                            <AccountBoxIcon className={styles.textIcon} />
                            <Typography variant="h4">{firstName} {lastName}</Typography>
                            {banned && <div className={styles.banBox}>
                                <Chip className={styles.chip} label='BANNED' size="small" color="secondary" />
                            </div>}
                        </div>
                        {allowPii && <>
                            <div className={styles.textRow}>
                                <EmailIcon className={styles.textIcon} />
                                <Typography variant="h6">{email}</Typography>
                            </div>
                            <div className={styles.textRow}>
                                <PhoneIcon className={styles.textIcon} />
                                <Typography variant="h6">{phoneNumber}</Typography>
                            </div>
                        </>}
                        <div className={styles.textRow}>
                            <RecentActorsIcon className={styles.textIcon} />
                            <Typography variant="h5">{alias}</Typography>
                        </div>
                        {checkedInTime && <div className={styles.textRow}>
                            <CheckCircleIcon size="small" className={styles.textIcon} />
                            <Typography variant="subtitle2">{moment(checkedInTime).format('MMMM Do YYYY, h:mm a')} by {checkedInBy}</Typography>
                        </div>}
                        {/* {checkedInTime && <div className={styles.textRow}>
                        {/* <ScheduleIcon className={styles.textIcon} /> */}
                        {/* <Typography variant="caption">Checked-In at {moment(checkedInTime).format('MMMM Do YYYY, h:mm a')} by {checkedInBy}</Typography>
                    </div> */}
                    </div>
                    {photoUrl && <img className={styles.photo} src={photoUrl} />}
                </div>
                <div className={styles.titlegroup}>
                    <Chip className={styles.chip} label={state} size="small" color="primary" />
                    <Chip className={styles.chip} label={`ID ${id}`} size="small" variant="outlined" />
                    <Chip className={styles.chip} label={role} size="small" variant="outlined" color="primary" />
                </div>
            </div>
            {!!notes && <div className={styles.info}>
                <div className={styles.box}>
                    <div className={styles.header} style={{ borderBottom: 'none' }}>
                        <NotesIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Notes: {notes}</Typography>
                    </div>
                    {/* <div className={styles.infogroup}>
                        {Object.entries(segments).map(([question, answer], i) => (
                            <div className={styles.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle1">{question}</Typography>
                                <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                                <Typography variant="subtitle2">{answer}</Typography>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>}
            {!!ejectReason && <div className={styles.info}>
                <div className={styles.box}>
                    <div className={styles.header} style={{ borderBottom: 'none' }}>
                        <BlockIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Eject Reason: {ejectReason}</Typography>
                    </div>
                </div>
            </div>}
            <div className={styles.info}>
                {!!(ipAddress && browserInfo) && <div className={styles.box}>
                    <div className={styles.header}>
                        <SettingsEthernetIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Network Information</Typography>
                    </div>
                    <div className={styles.titlegroup}>
                        {allowIp && <div className={styles.marker}>
                            <Typography variant="subtitle1">IP Address</Typography>
                            <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                            <Typography variant="subtitle2">{ipAddress}</Typography>
                        </div>}
                        <div className={styles.marker}>
                            <Typography variant="subtitle1">Browser</Typography>
                            <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                            <Typography variant="subtitle2">{browserInfo.browser.name} {browserInfo.browser.version}</Typography>
                        </div>
                        <div className={styles.marker}>
                            <Typography variant="subtitle1">Operating System</Typography>
                            <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                            <Typography variant="subtitle2">{browserInfo.os.name} {browserInfo.os.version}</Typography>
                        </div>
                        {(browserInfo.device.model || browserInfo.device.type || browserInfo.device.vendor) && <div className={styles.marker}>
                            <Typography variant="subtitle1">Device</Typography>
                            <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                            <Typography variant="subtitle2">{browserInfo.device.model} {browserInfo.device.type} {browserInfo.device.vendor}</Typography>
                        </div>}
                    </div>
                </div>}
            </div>
            <div className={styles.info}>
                {(!!segments && !!Object.keys(segments).length) && <div className={styles.box}>
                    <div className={styles.header}>
                        <QuestionAnswerIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Guest Segment Answers</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        {Object.entries(segments).map(([question, answer], i) => (
                            <div className={styles.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle1">{question}</Typography>
                                <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                                {(!!answer && typeof answer === 'object' && !Array.isArray(answer)) ? Object.entries(answer).map(([answerKey, answerValue]) => (<Typography variant="subtitle2">{answerKey} - {answerValue}</Typography>))
                                    : <Typography variant="subtitle2">{Array.isArray(answer) ? answer.join(', ') : answer}</Typography>}
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
            <div className={styles.info}>
                {(!!emailSends && !!Object.keys(emailSends).length) && <div className={styles.box}>
                    <div className={styles.header}>
                        <EmailIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Last Sent Emails</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        {Object.entries(emailSends).map(([type, time], i) => (
                            <div className={styles.marker} key={`email-time-display${i}`}>
                                <Typography variant="subtitle1">{emailTypes[type]}</Typography>
                                <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                                <Typography variant="subtitle2">{new Date(`${time}Z`).toLocaleString('en', { timeZoneName: 'short' })}</Typography>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
            <div className={styles.info}>
                <div className={styles.box}>
                    {/* <div className={styles.header}>
                        <PhonelinkSetupIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Dial Settings</Typography>
                    </div> */}
                    <Divider />
                    <div className={styles.titlegroup}>
                        <Typography variant="caption">Guest Created at {moment(created).format('MMMM Do YYYY, h:mm a')} by {createdBy}</Typography>
                        <Typography variant="caption">Last Updated at {moment(lastUpdated).format('MMMM Do YYYY, h:mm a')} by {lastUpdatedBy}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default withTheme(GuestTableDetails)