import React, { useState, useCallback } from 'react';
import { Zoom } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
// import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import CloseIcon from '@material-ui/icons/Close';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Files from 'react-butterfiles'

const useStyles = makeStyles({
  addFab: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: '10',
    padding: '1rem',
    boxSizing: 'content-box'
  },
  actionClosed: {
    height: 0
  }
})

const GuestSpeedDial = React.memo(({ show, allowUpload, onClickAdd, onUploadCSV, onError, theme, test }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [mockUpload, setUploadType] = useState(false);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <div>
      <div className={`${styles.addFab} mui-fixed`}>
        <Zoom
          key={'add-session-fab'}
          in={show}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${transitionDuration.exit}ms`,
          // }}
          unmountOnExit
        >
        <Files
            multiple={false} maxSize="2mb" multipleMaxSize="10mb" accept={['.csv', 'text/csv', 'text/x-csv', 'application/csv', 'application/x-csv', 'application/vnd.ms-excel', '']}
            onSuccess={(files) => {
              onUploadCSV(files, mockUpload);
            }}
            onError={onError}
          >
            {({ browseFiles }) => (
              <SpeedDial
                ariaLabel="Guest Actions"
                // className={classes.speedDial}
                // hidden={hidden}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction={'up'}
                classes={{actionsClosed: styles.actionClosed}}
              >
                <SpeedDialAction
                  key='addguestaction'
                  icon={<PersonAddIcon />}
                  tooltipTitle="Add Guest"
                  onClick={onClickAdd}
                />
                {/* <Files
              multiple={false} maxSize="2mb" multipleMaxSize="10mb" accept={["application/pdf", "image/jpg", "image/jpeg"]}
              onSuccess={files => alert({ files })}
              onError={errors => alert({ errors })}
                >
                  {({ browseFiles }) => ( */}
                {allowUpload && <SpeedDialAction
                  key='uploadcsvaction'
                  icon={<PublishIcon />}
                  tooltipTitle="Upload CSV"
                  onClick={() => {
                    setUploadType(false);
                    browseFiles();
                  }}
                />}
                {test && allowUpload && <SpeedDialAction
                  key='uploadmockcsvaction'
                  icon={<CloudUploadIcon />}
                  tooltipTitle="Upload Mock CSV"
                  onClick={() => {
                    setUploadType(true);
                    browseFiles();
                  }}
                />}
                {/* //   )}
            // </Files> */}
          </SpeedDial>
          )}
          </Files>
        </Zoom>
      </div>
    </div>
  );
})

export default withTheme(GuestSpeedDial)