import React, { useState } from 'react';
import { Modal, Fade, Paper, Button , Typography, Badge, CircularProgress, List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { Clear, Description } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem'
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30px',
        paddingBottom: '30px',
        width: '100vw',
        justifyContent: 'center'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '40%',
        minWidth: '300px',
        padding: '15px 0 0 0',
        maxHeight: '98%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    body: {
        padding: '0 15px 0 15px',
        overflowY: 'auto',
        marginTop: '1rem',
    },
    headerText: {
        margin: 'auto 0 auto 0',
        whiteSpace: "nowrap"
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0 0 15px 0',
        marginTop: '5rem'
    },
    button: {
        margin: '3px'
    },
    deleteIconButton: {
        padding: '0px',
        fontSize: '0px',
        minWidth: '0px',
        color: 'grey',
        "&:hover": { color: "red", backgroundColor: 'transparent' }
    },
    customBadge: {
        backgroundColor: "#4BB543",
        color: "white"
    },
    dropzone: {
        minHeight: 140
    }
});


const UploadSessionFileModal = ({ openAddFileModal, checkFileList, uploadSessionFile, setOpenAddFileModal }) => {
    const styles = useStyles();
    const [loadingFiles, setLoadingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileStatus, setFileStatus] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    // const [filesData, setFilesData] = useState(filesToUpload.map(i => {}));

    const handleFileChange = (files) => {
        setLoadingFiles([]);
        setUploadedFiles([]);
        setFilesToUpload(files.filter(i => !uploadedFiles.some(j => i.name === j)));
        // setFilesToUpload(e.target.files[0]);
    }

    const removeFile = (name) => {
        let files = filesToUpload.filter(i => i.name !== name);
        setFilesToUpload(files);
    }

    const onClickUpload = async () => {
        setLoadingFiles(filesToUpload.map(i => i.name));
        for (const file of filesToUpload){
            await uploadSessionFile(file);
            let uplFiles = uploadedFiles;
            uplFiles.push(file.name);
            setUploadedFiles(uplFiles);
            setLoadingFiles(loadingFiles.filter(i => i.name !== file.name));
        }
    }

    const closeModal = () => {
        setFilesToUpload([]);
        setLoadingFiles([]);
        setUploadedFiles([]);
        checkFileList();
        setOpenAddFileModal(false)
    }

    return <>

        <Modal
            id="add-file-modal"
            className={styles.modal}
            open={openAddFileModal}
        >

            <Fade in={openAddFileModal}>
                <Paper className={styles.paper}>
                    <div className={styles.header}>
                        <Typography variant="h5" className={styles.headerText}>Add File</Typography>
                    </div>
                    <div className={styles.body}>
                        <form style={{ textAlignLast: 'center' }} autoCapitalize="off">

                            <DropzoneArea dropzoneClass={styles.dropzone} showAlerts={false} onChange={handleFileChange} showPreviewsInDropzone={false} filesLimit={10} />
                            {filesToUpload.length > 0 ? <>
                            <div>
                                <List style={{overflowY: 'scroll', height: 150}}>
                                    {filesToUpload.map((file, i) => {
                                        return <ListItem key={i}>
                                            <ListItemIcon><Badge classes={uploadedFiles?.includes(file.name) ? { badge: styles.customBadge } : {}} variant="dot"><Description /></Badge></ListItemIcon>
                                            {loadingFiles?.includes(file.name) ? <CircularProgress color="primary" size={20} /> : <></>}
                                            <ListItemText primary={file.name} />
                                            <ListItemIcon >{!loadingFiles?.includes(file.name) && !uploadedFiles.includes(file.name) && <Tooltip title="Remove File"><Button className={styles.deleteIconButton} onClick={() => removeFile(file.name)}><Clear /></Button></Tooltip>}</ListItemIcon>
                                        </ListItem>
                                    })}
                                </List>
                            </div></> : <></>}
                            <div className={styles.footer}>
                                <Button className={styles.button} disabled={filesToUpload.length === uploadedFiles.length} variant="contained" onClick={onClickUpload} color="primary">Upload</Button>
                                <Button className={styles.button} variant="contained" onClick={closeModal}>Close</Button>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Fade>
        </Modal>
    </>
}

export default withTheme(UploadSessionFileModal)