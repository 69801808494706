import { useRef, useEffect, useState } from 'react';

export const useEventListener = (eventName, handler, passive = true, element = window) => {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = (event) => {
            if (!passive) {
                event.preventDefault();
            }
            savedHandler.current(event)
        };

        element.addEventListener(eventName, eventListener, { passive });

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element, passive]);
};

let storageKeys = []

export const clearLocalStorage = (keyList) => {
    (keyList || storageKeys).forEach((key) => {
        // We will remove any of the keys passed from the storage keys
        storageKeys = [...storageKeys].filter(sKey => sKey !== key);
        window.localStorage.removeItem(key);
    });
}

export const useLocalStorage = (key, initialValue) => {
    storageKeys = [...new Set([...storageKeys, key])]

    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            // console.log(key, item)
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.log(error);
        }
    }, [key, storedValue])

    return [storedValue, setStoredValue];
}

export const useMemoCompare = (next, compare) => {
    // Ref for storing previous value
    const previousRef = useRef();
    const previous = previousRef.current;
    
    // Pass previous and next value to compare function
    // to determine whether to consider them equal.
    const isEqual = compare(previous, next);
  
    // If not equal update previousRef to next value.
    // We only update if not equal so that this hook continues to return
    // the same old value if compare keeps returning true.
    useEffect(() => {
      if (!isEqual) {
        previousRef.current = next;
      }
    });
    
    // Finally, if equal then return the previous value
    return isEqual ? previous : next;
  }