import React, { useCallback, useRef, useMemo } from 'react';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import StandardField from './StandardField';
import DeleteIcon from '@material-ui/icons/Delete';

const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  builder: {
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    display: 'flex',
    alignItems: 'center'
  },
  addnew: {
    margin: '8px 0 8px 0'
  },
});
const EMOJI_LIMIT_MAX = 7;

const MultiEmojiInputField = React.memo(({ value = [], label, sublabel, staticLength, buttonLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, ...restProps }) => {
  const styles = useStyles();
  const valueRef = useRef();

  valueRef.current = value || []

  const handleAdd = useCallback(() => {
    if (valueRef.current.length < EMOJI_LIMIT_MAX) {
      const newValue = [...valueRef.current, null]
      onChange(null, newValue)
    }
  }, [onChange])

  const handleInputChange = useCallback((changedValue, index, key) => {
    const newValue = cloneDeep(valueRef.current)
    newValue[index] = {
      ...(newValue[index] || {}),
      [key]: changedValue
    }
    onChange(null, newValue)
  }, [onChange]);

  const handleRemove = useCallback((index, key) => {
    if (valueRef.current.length > 1) {
      const newValue = cloneDeep(valueRef.current)
      newValue.splice(index, 1)
      for (let i = index; i < newValue.length; i++) {
        if (newValue[i]?.tooltip && newValue[i]?.emoji) {
          invalidate(`${key}-emoji-${index}`, false);
          invalidate(`${key}-tooltip-${index}`, false);
        }
      }
      invalidate(`${key}-emoji-${newValue.length}`, false);
      invalidate(`${key}-tooltip-${newValue.length}`, false);
      // invalidate && invalidate(`${fieldKey}-button${index}`, false)
      onChange(null, newValue)
    }
  }, [onChange]);

  return (
    <div className={styles.root}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      <div style={{ width: '100%' }}>
        <div className={styles.builder}>
          {(value || []).map((val, i) => (<div className={styles.option}>
            <StandardField
              type='emoji'
              label="Emoji"
              // sublabel={typeof sublabel === 'function' ? sublabel(i + 1) : sublabel}
              fieldKey={`${fieldKey}-emoji-${i}`}
              key={`${fieldKey}-emoji-${i}`}
              value={val?.emoji}
              required={required}
              onChange={(e, v) => handleInputChange(v, i, 'emoji')}
              invalidate={invalidate}
              {...restProps}
            />
            <StandardField
              type='text'
              label="Tooltip Label"
              // sublabel={typeof sublabel === 'function' ? sublabel(i + 1) : sublabel}
              fieldKey={`${fieldKey}-tooltip-${i}`}
              key={`${fieldKey}-tooltip-${i}`}
              value={val?.tooltip}
              required={required}
              // onRemove={() => handleRemove(i)}
              onChange={(e, v) => handleInputChange(v, i, 'tooltip')}
              invalidate={invalidate}
              {...restProps}
            />
            <span><Button disabled={restProps.disabled} onClick={() => handleRemove(i, fieldKey)} style={{ fontWeight: 800, minWidth: 0 }}><DeleteIcon /></Button></span>
          </div>))}
        </div>
        {valueRef.current.length < EMOJI_LIMIT_MAX && <div className={styles.addnew}>
          <Button disabled={restProps.disabled} variant="outlined" onClick={handleAdd}>
            <AddIcon /> {buttonLabel || 'Add'}
          </Button>
        </div>}
      </div>
    </div>
  );
})

// MultiEmojiInputField.defaultProps = {

// }

export default MultiEmojiInputField;
