import React, { useCallback, useMemo, useRef } from 'react';
import StandardModal from './standard/StandardModal';
import { Typography } from '@material-ui/core';

const GuestRewardModal = React.memo((props) => {
  const { data, getTangoCatalogs, allowedTangoCatalogs, currencySymbols } = props
  //console.log('guest rewards', data)

  const tangoCatalogsRef = useRef()

  const disabledFn = useCallback(({ id, tangoPaymentId, adjusted }) => tangoPaymentId || !adjusted, [])

  const disabledDoneFn = useCallback(({ id, tangoPaymentId, excluded }) => tangoPaymentId, [])

  const tangoCatalogs = useCallback(() => tangoCatalogsRef.current || getTangoCatalogs().then(res => res.filter(x => allowedTangoCatalogs.includes(x.utid))).then(res => (tangoCatalogsRef.current = res, res)), [getTangoCatalogs, allowedTangoCatalogs])

  const onPaymentTypeUpdate = useCallback((newValue, newParams) => {
    newParams.reward.currency = newValue.currencyCode
    return newParams
  }, [])

  const rewardAmountPrefix = useCallback(({ reward }) => currencySymbols[reward.currency], [currencySymbols])
 
  return (
    <StandardModal
      modalKey='guestRewardModal'
      open
      {...props}
      title={'Edit Guest Rewards'}
      buttonLabel='Submit'
      fields={[
        {
          key: 'excluded',
          label: 'Exclude from Rewards',
          type: 'boolean',
          defaultValue: false,
          disabled: disabledDoneFn,
        },
        {
          key: 'adjusted',
          label: 'Adjusted Reward',
          type: 'boolean',
          defaultValue: false,
          disabled: disabledDoneFn,
          // conditions: [
          //   {
          //     condition: ({ videoPlatform }) => videoPlatform === 'Brightcove',
          //     required: true
          //   }
          // ],
        },
        {
          key: 'reward.paymentTypeId',
          label: 'Tango Reward',
          placeholder: 'Select Tango Payment Option',
          type: 'select',
          options: tangoCatalogs,
          optionLabel: 'rewardName',
          optionValueKey: 'utid',
          required: true,
          onUpdate: onPaymentTypeUpdate,
          disabled: disabledFn,
        },
        {
          key: 'reward.amount',
          label: 'Reward Amount',
          prefix: rewardAmountPrefix,
          placeholderChar: '#',
          type: 'number',
          minValue: 0,
          maxValue: 100,
          decimals: 2,
          disabled: disabledFn,
        },
        {
          key: 'notes',
          label: 'Notes',
          type: 'display',
          conditions: [
            {
              condition: ({ notes }) => !!notes && notes.trim() !== "",
            }
          ],
        },
        {
          key: 'ejectReason',
          label: 'Eject Reason',
          type: 'display',
          conditions: [
            {
              condition: ({ state }) => state === 'Ejected',
            }
          ],
        }
      ]}
    />
  );
})

export default GuestRewardModal;
