import React from 'react';
// import { Zoom, Grow, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import AppNavBar from './components/AppNavBar';
import SessionsPage from './pages/SessionsPage';
import SessionManagementPage from './pages/SessionManagementPage';
// import SettingsPage from './pages/SettingsPage';
import { ErrorPage } from './pages/ErrorPage';
import LoadingPage from './pages/LoadingPage'
import './App.css'
import ProjectsPage from './pages/ProjectsPage';
import BannedPage from './pages/BannedPage';
import EmailsPage from './pages/EmailsPage';

const useStyles = makeStyles({
  app: {
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden'
  },
  transition: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    overflow: 'hidden'
  },
  body: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
})

const AppMain = React.memo((props) => {
  console.log('render app main', props)
  const styles = useStyles();

  // console.log('user is: ', user)

  return (
    <div className={styles.app}>
      <AppNavBar />
      <div className={styles.body}>
        {/* <Zoom
          in={match != null}
          timeout={300}
          unmountOnExit> */}
        {/* <TransitionGroup>
          <CSSTransition
            key={props.location.pathname}
            timeout={{ enter: 300, exit: 0 }}
            // timeout={300}
            classNames="page"
            mountOnEnter
            unmountOnExit
          >
            <div className={styles.body}> */}
        <Switch location={props.location}>
          <Route exact path={['/sessions/upcoming', '/sessions/upcoming/:action', '/']} render={() =>
            <SessionsPage filter="upcoming" />
          } />
          <Route exact path='/sessions/today' render={() =>
            <SessionsPage filter="today's" />
          } />
          <Route exact path='/sessions/past' render={() =>
            <SessionsPage filter="past" />
          } />
          <Route exact path='/projects' render={() =>
            <ProjectsPage />
          } />
          <Route exact path='/banned' render={() =>
            <BannedPage />
          } />
          <Route exact path='/emails' render={() =>
            <EmailsPage />
          } />
          <Route exact path={['/session/:sessionId', '/session/:sessionId/:selectedView']} component={SessionManagementPage} />
          {/* <Route exact path='/session/:sessionId/guests' component={GuestsPage} /> */}
          {/* <Route exact path='/settings' render={() => <SettingsPage />} /> */}
          {/* <Route exact path='/authcallback' render={() => <LoadingPage />} /> */}
          <Route render={() => <ErrorPage error="Not Found" message="Page Not Found" />} />
        </Switch>
        {/* </div>
          </CSSTransition>
        </TransitionGroup> */}
        {/* </Zoom> */}
        {/* <div className={styles.footer} /> */}
      </div>
    </div>
  );
})

export default AppMain;
