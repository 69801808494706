import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocalStorage, clearLocalStorage } from '../hooks/hooks'
import { useClipboard } from 'use-clipboard-copy';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, IconButton, Tooltip, TextField, Fab, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import EmailIcon from '@material-ui/icons/Email';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import NoteIcon from '@material-ui/icons/Note';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FilterListIcon from '@material-ui/icons/FilterList';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import CancelIcon from '@material-ui/icons/Cancel';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import VideocamIcon from '@material-ui/icons/Videocam';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import CloseIcon from '@material-ui/icons/Close';
// import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
// import MaterialTable, { MTableAction, MTableActions, MTableBody, MTableBodyRow } from "material-table"
import MaterialTable, { MTableActions, MTableBodyRow } from "@material-table/core"
import MTableActionLoading from './standard/MTableActionLoading';
import GuestTableDetails from './GuestTableDetails';
import { CsvBuilder } from 'filefy'
import * as moment from 'moment'
import StandardDialog from './standard/StandardDialog';
// import { MTableToolbar } from './standard/MTableToolbar';
import { techCheckMode } from './utils'
import EmailModal from './EmailModal';
import Popover from '@material-ui/core/Popover';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const cloneDeep = require('lodash/cloneDeep');
const reduce = require('lodash/reduce')

const keyify = (obj, prefix = '') => {
  return Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);
}

const findNull = (obj) => {
  for (var key in obj) {
    if (obj[key] === null) {
      return true;
    }
    if (typeof obj[key] === 'object') {
      return findNull(obj[key]);
    }
  }
  return false;
}

const getRowStyle = (rowData) => {
  return {
    backgroundColor: ['Pending', 'Denied'].includes(rowData.industryCheck) ? rowData.industryCheck === 'Denied' ? '#fde2e0' : '#e2e0fd' : '#fff',
    border: rowData.banned ? '2px solid rgb(135, 17, 9)' : (findNull(rowData.segments) && (rowData.role === "Viewer")) ? '3px solid #f1d10d' : 'none',
  }
}

// const short = require('short-uuid');

const useStyles = makeStyles({
  action: {
    position: 'relative',
    marginRight: '5px'
  },
  actionLoading: {
    // color: green[500],
    position: 'absolute',
    // top: -6,
    // left: -6,
    zIndex: 1,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  sessionFilterOptions: {
    listStyleType: 'none',
    marginTop: 0,
    padding: 0,
    minWidth: '200px',

    "& li": {
      paddingLeft: "20px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },

    "& li:hover": {
      cursor: 'pointer',
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  filterButton: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: '1rem',
    paddingTop: "5px",
    cursour: "pointer"
  }
})

const filterOptions = [{
  label: "Check In",
  value: 'checkin'
}, {
  label: "Clients",
  value: "clients",
}, {
  label: "Ejected",
  value: "ejected",
}, {
  label: "Default",
  value: "default",
}];

const AliasRow = React.memo(({ alias, isDuplicate }) => {
  const clipboard = useClipboard({
    copiedTimeout: 1500
  });

  const aliasClick = useCallback((ev) => {
    // ev.preventDefault();
    ev.stopPropagation();

    clipboard.copy(alias);
    // if (!navigator.clipboard) return;
    // navigator.clipboard.writeText(ax).then(() => alert('Copied to clipboard')).catch((err) =>console.error('Failed to copy!', err))

  }, [clipboard, alias])

  return (
    <div
      style={{
        display: 'flex',
        border: `2px solid ${isDuplicate ? '#871109' : 'transparent'}`,
        padding: '6px',
        width: '100%',
        boxSizing: 'border-box'
      }}>
      {alias}
      <Tooltip
        title={clipboard.copied ? 'Copied to Clipboard' : 'Copy Zoom Alias'} >
        <IconButton
          color="inherit"
          size="small"
          style={{ marginLeft: '6px' }}
          onClick={aliasClick}>
          <AssignmentIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
});

const SessionUsersView = React.memo(({
  multiSelect,
  allowCheckIn,
  allowFG,
  allowIp,
  allowPii,
  allowManage,
  emailLoading,
  checkInLoading,
  approveLoading,
  waitlistLoading,
  loading,
  sessionId,
  sessionStarted,
  checkInStarted,
  sessionComplete,
  hasZoomMeeting,
  zoomMeetingId,
  handleBulkZoomRegister,
  segments,
  guests,
  checkInGuest,
  techCheckGuest,
  emailGuestLink,
  emailRegistrationLink,
  sendBatchEmails,
  approveGuest,
  waitlistGuest,
  editGuest,
  deleteGuest,
  banGuest,
  toggleFocusGroup,
  turnAwayGuest,
  turnAwayLoading,
  noShowGuest,
  canceledGuest,
  canceledLoading,
  zoomLoading,
  getGuestPhoto,
  noShowBatch,
  updateBatch,
  bypassTechCheck,
  autoTechCheck,
  moveGuests,
  focusGroup,
  externalRecruit,
  getProjectSessions
}) => {
  // console.log('render guests view', theme)
  // const session = useRecoilValue(selectedSession);
  // const guests = useRecoilValue(guestsForSession(sessionId));
  // const user = useRecoilValue(currentUser);
  const [zoomAliasesDuplicates, setZoomAliasesDuplicates] = useState([]);
  const [tableGuests, setTableGuests] = useState(undefined)
  const [cancelPromptOpen, setCancelPromptOpen] = useState(false)
  const [checkInWarnOpen, setCheckInWarnOpen] = useState(false)
  const [deleteWarnOpen, setDeleteWarnOpen] = useState(false)
  const [banWarnOpen, setBanWarnOpen] = useState(false)
  const [emailModalOpts, setEmailModalOpts] = useState()
  const [recruiterEditOpen, setRecruiterEditOpen] = useState(false)
  const [moveGuestOpen, setMoveGuestOpen] = useState(false);
  const [moveToSessionId, setMoveToSessionId] = useState();
  const [selectedGuest, setSelectedGuest] = useState({})
  const [selectedGuestIds, setSelectedGuestIds] = useState([])
  const [tableFilters, setTableFilters] = useLocalStorage(`session${sessionId}guesttablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`session${sessionId}guesttablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`session${sessionId}guesttableorder`, {})
  const [tableHidden, setTableHidden] = useLocalStorage(`session${sessionId}guesttablehidden`, {zoomRegistered: true})
  const [guestCount, setGuestCount] = useState(0)
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  // const [openRow, setOpenRow] = useLocalStorage(`session${sessionId}guestopenrow`, {})
  // const { oktaAuth } = useOktaAuth();
  const styles = useStyles();
  // const [tab, setTab] = useState(selectedView || 'guests');
  // const [loading, setLoading] = useState(false);

  const tableRef = useRef()
  const selectedRef = useRef()
  // const openRef = useRef(openRow)
  const cancelNote = useRef()
  const scrollTableRef = useRef()
  const projectSessions = useRef();
  const scrollIntervalId = useRef();

  useEffect(() => {
    getProjectSessions().then(res => {
      projectSessions.current = res.filter(i => i.id !== sessionId).map(i => ({ code: i.id, label: i.sessionName }));
      return;
    })
  }, [getProjectSessions])

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    setTableGuests(guests ? guests.map(o => {
      const g = { ...o }
      g.techCheckMode = techCheckMode(g, autoTechCheck, bypassTechCheck)
      g.zoomRegistered = g.zoomRegistrantId ? true : false
      // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
      // if (tableRef.current) {
      //   const { detailPanel } = tableRef.current.props;
      //   let handleShowDetailPanel = detailPanel;

      //   if (typeof detailPanel === 'object') {
      //     handleShowDetailPanel = detailPanel[0].render
      //   }

      //   g.tableData = {
      //     showDetailPanel: g.id === openRef.current.id && handleShowDetailPanel
      //   }
      // }
      // if (!multiSelect && tableRef.current) {
      //   g.tableData = { checked: false }
      // }
      return g
    }) : undefined);
    // Check if there are duplicates and pass them to the alias field 
    if (guests) {
      setZoomAliasesDuplicates(() => {
        const aliasList = guests.map(guest => guest.alias);
        return aliasList.filter((s => v => s.has(v) || !s.add(v))(new Set()));
      })
    }
  }, [guests, autoTechCheck, bypassTechCheck]);

  useEffect(() => {
    setSelectedGuest({})
    setSelectedGuestIds([])
  }, [multiSelect])

  useEffect(() => {
    if (!multiSelect || (!!selectedRef.current && !selectedGuestIds.length)) {
      tableRef.current.onAllSelected(false);
    }
    selectedRef.current = selectedGuestIds.length
  }, [multiSelect, selectedGuestIds])

  useEffect(() => {
    setGuestCount(tableRef.current.dataManager.filteredData.length)
  }, [tableFilters, tableGuests])

  const tableColumns = useMemo(() => {
    // console.log('SEGMENTS', segments)
    const emailCol = allowCheckIn ? [{
      title: "Email",
      field: "email",
      filtering: false,
    }] : []
    const zoomCol = hasZoomMeeting && zoomMeetingId ? [{
      title: "Registered to Zoom?",
      field: "zoomRegistered",
      lookup: {
        'false': 'No',
        'true': 'Yes',
      },
    }] : []
    const piiCols = allowPii ? [{
      title: "First Name",
      field: "firstName",
      filtering: false,
    },
    {
      title: "Last Name",
      field: "lastName",
      filtering: false,
    }] : []
    const columns = [
      { title: "ID", field: "id", hidden: true, searchable: true, hiddenByColumnsButton: true },
      ...piiCols,
      ...emailCol,
      ...zoomCol,
      {
        title: "Zoom Alias",
        field: "alias",
        filtering: false,
        render: row => <AliasRow alias={row.alias} isDuplicate={zoomAliasesDuplicates.includes(row.alias)} />
      },
      {
        title: "State", field: "state",
        lookup: {
          ...(focusGroup ? {'Pre-Qualified': 'Pre-Qualified'} : {}),
          'Qualified': 'Qualified',
          'Registered': 'Registered',
          'Tech-Check': 'Tech-Check',
          'Approved': 'Approved',
          'Waitlisted': 'Waitlisted',
          'Denied': 'Denied',
          'Checked-In': 'Checked-In',
          'Ejected': 'Ejected',
          'Complete': 'Complete',
          'Turn Away': 'Turn Away',
          'No Show': 'No Show',
          'Canceled': 'Canceled',
        },
      },
      {
        title: "Role", field: "role",
        lookup: {
          'Client': 'Client',
          'VIP': 'VIP',
          'Viewer': 'Viewer'
        },
      },
      ...segments.map(segment => (segment.matrix && segment.matrixKeys) ? segment.matrixKeys.map(mk => ({ ...segment, matrixKey: mk.option })) : segment).flat().map((segment, i) => {
        // ((segment.matrix && segment.matrixKeys) || []).map()
        const colTitle = segment.matrixKey ? `${segment.questionKey} - ${segment.matrixKey}` : segment.questionKey
        const colKey = segment.matrixKey ? `segments.${segment.questionKey}.${segment.matrixKey}` : `segments.${segment.questionKey}`
        const segcol = {
          title: colTitle,
          field: colKey,
        }
        if (!segment.multiple && !segment.freeNumeric) {
          segcol.lookup = {}
          segment.answers.forEach(answer => segcol.lookup[answer.answer] = answer.answer)
        } else if (segment.multiple) {
          segcol.render = row => <>{row.segments ? Array.isArray(row.segments[segment.questionKey]) ? row.segments[segment.questionKey].join(', ') : row.segments[segment.questionKey] : ''}</>
        }
        return segcol
      }),
      {
        title: "Country Code", field: "country",
        // lookup: {
        //   'false': 'No',
        //   'true': 'Yes',
        // },
      },
      {
        title: "Recruiter Code", field: "recruiterCode",
        // lookup: {
        //   'false': 'No',
        //   'true': 'Yes',
        // },
      }, {
        title: "Tech Check Mode", field: "techCheckMode",
        lookup: {
          'self': 'Self-Guided',
          'guided': 'Guided',
          'none': 'None'
        },
      }, {
        title: "Tech Check Scheduled Date",
        field: "techCheckScheduledDate",
        filtering: true,
        customFilterAndSearch: (term, row) => `${moment(row.techCheckScheduledTime).format('l')}`.includes(term),
        customSort: (a, b) => Date.parse(a.techCheckScheduledTime) - Date.parse(b.techCheckScheduledTime),
        render: row => <span>{row.techCheckScheduledTime ? moment(row.techCheckScheduledTime).format('l') : 'N/A'}</span>
      }, {
        title: "Tech Check Scheduled Time",
        field: "techCheckScheduledTime",
        filtering: true,
        customFilterAndSearch: (term, row) => `${moment(row.techCheckScheduledTime).format('h:mm a')}`.includes(term),
        customSort: (a, b) => Date.parse(a.techCheckScheduledTime) - Date.parse(b.techCheckScheduledTime),
        render: row => <span>{row.techCheckScheduledTime ? moment(row.techCheckScheduledTime).format('h:mm a') : 'N/A'}</span>
      }, {
        title: "Industry Check", field: "industryCheck",
        lookup: {
          'Needed': 'Needed',
          'Pending': 'Pending',
          'Approved': 'Approved',
          'Denied': 'Denied',
        },
      }, {
        title: "Potential Focus Group", field: "focusGroup",
        lookup: {
          'false': 'No',
          'true': 'Yes',
        },
      }, {
        title: "Selected for Focus Group", field: "selectedFocusGroup",
        lookup: {
          'false': 'No',
          'true': 'Yes',
        },
      },
      {
        title: "Banned", field: "banned",
        lookup: {
          'false': 'No',
          'true': 'Yes',
        },
      }]

    return columns.map((x, i) => {
      x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
      x.defaultSort = tableSort[x.field]
      x.hidden = typeof tableHidden[x.field] === 'boolean' ? tableHidden[x.field] : (x.field.includes('segments.') || x.field === 'banned')
      if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
      return x
    }).sort((a, b) => {
      return a.columnIndex - b.columnIndex;
    })
  }, [tableFilters, tableSort, tableOrder, allowPii, tableHidden, segments, allowCheckIn, focusGroup, zoomAliasesDuplicates])

  const openCancelPrompt = useCallback((event, data) => {
    setSelectedGuest(Array.isArray(data) ? {} : data);
    setSelectedGuestIds(Array.isArray(data) ? data.map(x => x.id) : [])
    setCancelPromptOpen(true)
  }, []);

  const openCheckInWarning = useCallback((event, data) => {
    setSelectedGuest(data);
    setCheckInWarnOpen(true)
  }, []);

  const openDeleteWarning = useCallback((event, data) => {
    setSelectedGuest(data);
    setDeleteWarnOpen(true)
  }, []);

  const openBanGuestWarning = useCallback((event, data) => {
    setSelectedGuest(data);
    setBanWarnOpen(true)
  }, []);

  const openEmailDialog = useCallback((event, data) => {
    const opts = (data || []).reduce((p, row) => {
      p.schedule = p.schedule || (!(row.role !== 'Client' && ['Qualified', 'Canceled', 'No Show'].includes(row.state) && !row.cancelByStaff) || sessionComplete || row.banned)
      p.verify = p.verify || (!(autoTechCheck && row.role !== 'Client' && row.state === 'Registered' && !row.acuityAppointmentId) || bypassTechCheck || sessionComplete || row.banned)
      p.event = p.event || (!(['Checked-In', 'Tech-Check'].includes(row.state)) || (row.role !== 'Client' && sessionComplete) || row.banned)
      return p
    }, {
      schedule: false,
      verify: false,
      event: false
    })
    console.log('EMAIL OPTS', opts)
    setEmailModalOpts(opts)
    setSelectedGuestIds((data || []).map(x => x.id))
  }, [sessionComplete, bypassTechCheck, autoTechCheck]);

  const openRecruiterPrompt = useCallback((event, data) => {
    setRecruiterEditOpen(true)
    setSelectedGuestIds((data || []).map(x => x.id))
  }, []);

  const openMoveGuestPrompt = useCallback((event, data) => {
    setMoveGuestOpen(true)
    setSelectedGuestIds((data || []).map(x => x.id))
  }, []);

  const exportCSV = useCallback((exportData) => {
    const data = cloneDeep(exportData)
    const segs = new Set()
    const headers = reduce(data, (result, value, key) => {
      delete value.tableData
      // copy segments to individual columns
      if (!!value.segments) {
        Object.keys(value.segments).forEach(segment => {
          if (!!value.segments[segment] && typeof value.segments[segment] === 'object' && !Array.isArray(value.segments[segment])) {
            Object.keys(value.segments[segment]).forEach(optionKey => {
              const option = `${segment} | ${optionKey}`
              value[option] = value.segments[segment][optionKey]
              segs.add(option)
            })
          } else {
            value[segment] = Array.isArray(value.segments[segment]) ? value.segments[segment].join('|') : value.segments[segment]
            segs.add(segment)
          }
        })
      }
      delete value.segments
      value['potentialFocusGroup'] = value.focusGroup
      value['selectedForFocusGroup'] = value.selectedFocusGroup
      delete value.focusGroup
      delete value.selectedFocusGroup
      keyify(value).forEach(v => {
        result.add(v)
      })
      return result;
    }, new Set());
    const kx = [...headers]
    const columns = [...headers].map(v => segs.has(v) ? v : v.charAt(0).toUpperCase() + v.slice(1).replace(/([A-Z])/g, ' $1'))
    const builder = new CsvBuilder(`Session ${sessionId} Guest List.csv`);
    builder
      .setColumns(columns)
      .addRows(data.map((rowData) => {
        return kx.map(col => rowData[col])
      }))
      .exportFile();
  }, [sessionId])

  const csvTemplate = useCallback(() => {
    const columns = ["First Name", "Last Name", "Email", "Phone Number", "Country", "Role", "Alias", "Recruiter Code", "Industry Check", "Override 2FA", "Bypass Tech Check", "Potential Focus Group"]
    const segmentNames = segments.map((item) => {
      return item.questionKey;
    });

    const builder = new CsvBuilder("Guest List Import Template.csv");
    builder
      .setColumns([...columns, ...segmentNames])
      .exportFile();
  }, [segments]);

  const clearAllTableFilters = useCallback(() => {
    const currentTableFilers = [
      `session${sessionId}guesttablefilter`,
      `session${sessionId}guesttablesort`,
      `session${sessionId}guesttableorder`
    ];
    clearLocalStorage(currentTableFilers);
    setTableFilters({});
    setTableSort({});
    setTableOrder({});
  }, [sessionId, setTableFilters, setTableSort, setTableOrder]);

  // useEffect(() => {console.log(tableSort)}, [tableSort])
  // console.log('table sort', tableSort)

  const audienceActions = useMemo(() => {
    return allowManage ? [{
      icon: 'download',
      tooltip: 'Export Audience CSV',
      onClick: () => exportCSV(cloneDeep(tableGuests)),
      isFreeAction: true,
    },
    {
      icon: AssignmentReturnedIcon,
      tooltip: 'Download CSV Template',
      onClick: csvTemplate,
      isFreeAction: true,
    }] : []
  }, [allowManage, tableGuests, exportCSV, csvTemplate])

  const handleSendBatchEmails = useCallback((event, data) => {
    sendBatchEmails(data, [...(selectedGuestIds || [])])
    setSelectedGuestIds([])
    setEmailModalOpts()
  }, [selectedGuestIds, sendBatchEmails])

  const handleCloseEmailModal = useCallback(() => {
    setSelectedGuestIds([])
    setEmailModalOpts()
  }, [])

  const handleBatchNoShow = useCallback((event, rows) => {
    noShowBatch(event, rows)
    tableRef.current.onAllSelected(false);
  }, [noShowBatch])

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    const field = tableColumns[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleFilterChange = useCallback((e) => setTableFilters(prev => {
    const filters = {}
    e.forEach((f) => {
      filters[f.column.field] = f.value
    })
    return filters
  }), [setTableFilters])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns].filter(x => !x.hidden)

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  const handleHideColumn = useCallback(({ field, hidden }) => {
    setTableHidden(prev => {
      const hiddenFields = { ...prev }
      hiddenFields[field] = hidden
      return hiddenFields
    })
  }, [setTableHidden])

  const filterSessionsOpen = useCallback((event) => {
    const objectRect = event.currentTarget.getBoundingClientRect();
    const position = {
      top: objectRect.top,
      left: objectRect.left,
    };

    setAnchorEl(event.currentTarget);
    setPopoverPosition(position);
  }, [])

  const filterSessionsClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const filterPreSet = useCallback((type) => {
    filterSessionsClose()
    // clearAllTableFilters()

    if (type === "ejected") {
      // Check if guest is state =ejected
      setTableFilters(prev => {
        const filters = {}
        filters.state = ["Ejected"];
        return filters
      })

      setTableHidden(prev => {
        // Set all columns to hidden
        const hiddenFields = { ...prev }
        tableColumns.forEach((column) => {
          hiddenFields[column.field] = true
        })

        // Set firstName and lastName to visible
        hiddenFields['firstName'] = false
        hiddenFields['lastName'] = false
        hiddenFields['email'] = false
        hiddenFields['alias'] = false
        hiddenFields['state'] = false
        hiddenFields['role'] = false
        hiddenFields['segments.Age'] = false
        hiddenFields['segments.Ethnicity'] = false
        hiddenFields['segments.Gender'] = false
        hiddenFields['focusGroup'] = false
        hiddenFields['selectedFocusGroup'] = false

        return hiddenFields
      })

    } else if (type == "clients") {
      // Check if guest is state =ejected
      setTableFilters(prev => {
        const filters = {}
        filters.role = ["Client"];
        return filters
      })
      setTableHidden(prev => {
        // Set all columns to hidden
        const hiddenFields = { ...prev }
        tableColumns.forEach((column) => {
          hiddenFields[column.field] = true
        })

        // Set firstName and lastName to visible
        hiddenFields['firstName'] = false
        hiddenFields['lastName'] = false
        hiddenFields['email'] = false
        hiddenFields['alias'] = false
        hiddenFields['role'] = false

        return hiddenFields
      })
    } else if (type === "checkin") {
      setTableFilters(prev => {
        const filters = {}
        filters.state = ["Approved", "Checked-In", "Ejected", "Turn Away", "Complete"];
        return filters
      })
      setTableHidden(prev => {
        // Set all columns to hidden
        const hiddenFields = { ...prev }
        tableColumns.forEach((column) => {
          hiddenFields[column.field] = true
        })

        // Set firstName and lastName to visible
        hiddenFields['firstName'] = false
        hiddenFields['lastName'] = false
        hiddenFields['email'] = false
        hiddenFields['alias'] = false
        hiddenFields['state'] = false
        hiddenFields['role'] = false
        hiddenFields['segments.Gender'] = false
        hiddenFields['segments.Age'] = false
        hiddenFields['segments.Ethnicity'] = false
        hiddenFields['focusGroup'] = false
        hiddenFields['selectedFocusGroup'] = false

        return hiddenFields
      })
    } else if (type === "default") {
      setTableFilters({});
      setTableHidden(prev => {
        // Set all columns to hidden
        const hiddenFields = { ...prev }

        tableColumns.forEach((column) => {
          if (column.field.includes("segments.") || column.field === "banned") {
            hiddenFields[column.field] = true
          } else {
            hiddenFields[column.field] = false
          }
        })

        return hiddenFields
      })

    }
  }, [setTableHidden, tableColumns, setTableFilters, filterSessionsClose])

  const renderGuestTableDetails = useCallback(({ rowData }) => <GuestTableDetails allowPii={allowPii} allowIp={allowIp} getGuestPhoto={getGuestPhoto} {...rowData} />, [allowPii, allowIp, getGuestPhoto])

  const handleScrollEnter = useCallback((direction) => {
    // Start scroll on mouse enter
    scrollIntervalId.current = setInterval(() => {
      // Find class name of table
      if (scrollTableRef.current) {
        const scrollView = scrollTableRef.current.querySelector('div[style="overflow-y: auto;"]')
        // If table is not found, return
        if (!scrollView) return

        if (direction === "left") {
          scrollView.scrollLeft -= 100
        }
        else {
          scrollView.scrollLeft += 100
        }
      }
    }, 100)
  }, [])
  
  const handleScrollLeave = useCallback(() => {
    // Stop scroll on mouse leave
    if (scrollIntervalId.current) clearInterval(scrollIntervalId.current)
    scrollIntervalId.current = null
  }, [])

  return (
    <>
      <div style={{ display: 'flex', position: "fixed", right: multiSelect ? "50px" : "100px", bottom: "33px", zIndex: "1" }}>
        <Fab style={{ marginRight: "10px" }} color="#f9f9f9" size="small" onMouseDown={() => handleScrollEnter("left")} onMouseUp={handleScrollLeave} onMouseLeave={handleScrollLeave} >
          <ArrowBackIosIcon fontSize="small" />
        </Fab>
        <Fab color="#f9f9f9" size="small" onMouseDown={() => handleScrollEnter("right")} onMouseUp={handleScrollLeave} onMouseLeave={handleScrollLeave}>
          <ArrowForwardIosIcon fontSize="small" />
        </Fab>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={filterSessionsClose}
        anchorReference="anchorPosition"
        anchorPosition={popoverPosition}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div>
          <ul className={styles.sessionFilterOptions}>
            {filterOptions.map(option => <li key={option.value}>
              <button onClick={() => filterPreSet(option.value)} className={styles.filterButton}>{option.label}</button>
            </li>)}
          </ul>
        </div>
      </Popover>
      <div style={{ flex: 1, overflowX: 'auto' }} ref={scrollTableRef}>
        <MaterialTable
          key={`session${sessionId}guesttable`}
          tableRef={tableRef}
          // onRowClick={() => console.log(tableRef.current)}
          // onToggleDetailPanel={() => console.log(tableRef.current)}
          // onRowClick={(e, row) => setOpenRow(prev => prev.id === row.id ? {} : row)}
          // onOrderChange={(i, order) => setTableSort(prev => {
          //   return {
          //     ...prev, [filter]: {
          //       [i]: order
          //     }
          //   }
          // })}
          // onOrderChange={(i, order) => setTableSort({ [i]: order })}
          onOrderChange={handleOrderChange}
          onFilterChange={handleFilterChange}
          onColumnDragged={handleColumnDrag}
          onChangeColumnHidden={handleHideColumn}
          // localization={{
          //   toolbar: {
          //     exportCSVName: 'Export CSV',
          //     exportPDFName: 'Export PDF'
          //   }
          // }}
          options={{
            emptyRowsWhenPaging: false,
            // actionsColumnIndex: -1,
            // exportButton: {
            //   csv: true,
            // },
            // exportCsv: exportCSV,
            // exportFileName: `Session ${sessionId} Guest List`,
            // exportAllData: true,
            detailPanelType: 'single',
            rowStyle: rowData => getRowStyle(rowData),
            selection: !!multiSelect,
            // selectionProps: rowData => ({
            //   // disabled: rowData.name === 'Mehmet',
            // }),
            // actionsCellStyle: {
            //   display: 'flex',
            //   marginRight: '5px'
            // },
            columnsButton: true,
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100]
          }}
          columns={tableColumns}
          // columns={[
          //   { title: "First Name", field: "firstName", filtering: false, defaultSort: tableSort[0] },
          //   { title: "Last Name", field: "lastName", filtering: false, defaultSort: tableSort[1] },
          //   // { title: "Email", field: "email", filtering: false, defaultSort: tableSort[2] },
          //   { title: "Zoom Alias", field: "alias", filtering: false, defaultSort: tableSort[2] },
          //   {
          //     title: "State", field: "state",
          //     lookup: {
          //       'Registered': 'Registered',
          //       'Tech-Check': 'Tech-Check',
          //       'Approved': 'Approved',
          //       'Waitlisted': 'Waitlisted',
          //       'Denied': 'Denied',
          //       'Checked-In': 'Checked-In',
          //       'Ejected': 'Ejected',
          //       'Complete': 'Complete',
          //       'Focus Group': 'Focus Group'
          //     },
          //     defaultFilter: tableFilters.state,
          //     defaultSort: tableSort[3]
          //   },
          //   {
          //     title: "Role", field: "role",
          //     lookup: {
          //       'Client': 'Client',
          //       'VIP': 'VIP',
          //       'Viewer': 'Viewer'
          //     },
          //     defaultFilter: tableFilters.role,
          //     defaultSort: tableSort[4]
          //   },
          //   {
          //     title: "FG", field: "focusGroup",
          //     lookup: {
          //       'false': 'N',
          //       'true': 'Y',
          //     },
          //     defaultFilter: tableFilters.focusGroup,
          //     defaultSort: tableSort[5]
          //   }
          // ]}
          isLoading={!(tableGuests) || loading}
          data={tableGuests} //MATERIAL TABLE MUTATES PROPS :(
          title={`Session Guests ${guestCount > 0 ? `(${(Array.isArray(tableFilters.state) && tableFilters.state.length > 0) ? "*" : ""}${guestCount})` : ''}`}
          actions={[
            {
              icon: ClearAllIcon,
              tooltip: 'Clear All Filters',
              onClick: clearAllTableFilters,
              isFreeAction: true,
            },
            {
              icon: FilterListIcon,
              tooltip: 'Filters',
              onClick: filterSessionsOpen,
              isFreeAction: true,
            },
            ...audienceActions,
            (rowData) => {
              return {
                icon: allowCheckIn ? 'edit' : NoteIcon,
                tooltip: allowCheckIn ? `Edit ${rowData.role === 'Client' ? 'Client' : 'Guest'}` : `Edit ${rowData.role === 'Client' ? 'Client' : 'Guest'} Notes`,
                onClick: editGuest,
                hidden: !(allowCheckIn || allowFG) || !!multiSelect,
                disabled: rowData.banned
                // disabled: ['Checked-In', 'Ejected', 'Complete'].includes(rowData.state) && rowData.role === "Viewer"
                // disabled: (!['Registered','Approved','Tech-Check'].includes(rowData.state)),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const showTechCheck = (rowData.role === 'Viewer' && ['Registered', 'Tech-Check'].includes(rowData.state))
              const showApprove = (rowData.role === 'Viewer' && rowData.state === 'Waitlisted')
              const showFocusGroup = (rowData.role === 'Viewer' && ['Checked-In', 'Complete'].includes(rowData.state))
              const disable = (rowData.role === 'Viewer' ? ['Pre-Qualified', 'Qualified', 'Tech-Check', 'Denied', 'Turn Away', 'No Show', 'Canceled'].includes(rowData.state) || (rowData.state !== 'Complete' && sessionComplete) || rowData.banned : ['Checked-In', 'Complete'].includes(rowData.state) || (rowData.role !== 'Client' && sessionComplete))
              const allow = showFocusGroup ? allowFG : allowCheckIn
              return {
                icon: showTechCheck ? AddToQueueIcon : showFocusGroup ? (rowData.selectedFocusGroup) ? VisibilityOffIcon : VisibilityIcon : showApprove ? ThumbUpIcon : CheckIcon,
                tooltip: showTechCheck ? 'Start Tech Check' : showFocusGroup ? (rowData.selectedFocusGroup) ? 'Remove From Focus Group' : 'Add to Focus Group' : showApprove ? 'Approve Guest' : `Check-In ${rowData.role === 'Client' ? 'Client' : 'Guest'}`,
                onClick: (rowData.state === 'Ejected') ? openCheckInWarning : (showTechCheck ? techCheckGuest : showFocusGroup ? toggleFocusGroup : showApprove ? approveGuest : checkInGuest),
                disabled: disable || !allow,
                hidden: !!multiSelect,
                isLoading: showApprove ? approveLoading.has(rowData.id) : checkInLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const disable = ((rowData.role !== 'Viewer' || sessionComplete) || (!['Registered', 'Denied'].includes(rowData.state)) || rowData.banned)
              return {
                icon: HourglassEmptyIcon,
                tooltip: 'Waitlist Guest',
                onClick: waitlistGuest,
                disabled: disable,
                hidden: !allowCheckIn || !!multiSelect,
                isLoading: waitlistLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: EmailIcon,
                tooltip: 'Re-send Email',
                onClick: ['Qualified', 'Canceled'].includes(rowData.state) ? emailRegistrationLink : emailGuestLink,
                hidden: !allowCheckIn || !!multiSelect,
                disabled: (!((!bypassTechCheck && autoTechCheck && rowData.state === 'Registered' && !rowData.acuityAppointmentId) || (!!externalRecruit && ['Qualified', 'Canceled'].includes(rowData.state) && !rowData.cancelByStaff) || (['Checked-In', 'Tech-Check'].includes(rowData.state) && !rowData.cancelByStaff)) || (rowData.role !== 'Client' && sessionComplete) || rowData.banned),
                isLoading: emailLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const showTurnAway = (rowData.role === 'Viewer' && ['Approved', 'Turn Away'].includes(rowData.state))
              return {
                icon: showTurnAway ? (rowData.state === 'Turn Away' ? KeyboardReturnIcon : PanToolRoundedIcon) : DesktopAccessDisabledIcon,
                tooltip: showTurnAway ? (`${rowData.state === 'Turn Away' ? 'Un-' : ''}Turn Away ${rowData.role === 'Client' ? 'Client' : 'Guest'}`) : 'No Show',
                onClick: showTurnAway ? turnAwayGuest : noShowGuest,
                hidden: !allowFG || !!multiSelect,
                disabled: ((rowData.role !== 'Viewer' || sessionComplete) || !(['Approved', 'Turn Away', 'Registered'].includes(rowData.state)) || rowData.banned),
                isLoading: turnAwayLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const disable = ((rowData.role !== 'Viewer' || sessionComplete) || (!['Registered', 'Approved', 'Waitlisted'].includes(rowData.state)) || rowData.banned)
              return {
                icon: RemoveFromQueueIcon,
                tooltip: 'Canceled',
                onClick: rowData.state === 'Registered' && rowData.acuityAppointmentId ? openCancelPrompt : (e, r) => canceledGuest(e, [r.id]),
                disabled: disable,
                hidden: !allowCheckIn || !!multiSelect,
                isLoading: canceledLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: NotInterestedIcon,
                tooltip: 'Ban Guest',
                onClick: openBanGuestWarning,
                hidden: !allowManage || !!multiSelect,
                disabled: !(rowData.role === "Viewer" && !sessionStarted && !(["Checked-In", "Complete", "Ejected", "Turn Away"].includes(rowData.state) || rowData.banned)),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: 'delete',
                tooltip: `Delete ${rowData.role === 'Client' ? 'Client' : 'Guest'}`,
                onClick: openDeleteWarning,
                hidden: !allowManage || !!multiSelect,
                disabled: (['Checked-In', 'Tech-Check', 'Complete'].includes(rowData.state) || sessionComplete),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            {
              icon: EmailIcon,
              tooltip: 'Send Batch Email',
              onClick: openEmailDialog,
              hidden: !allowCheckIn || !multiSelect,
              // disabled: sessionComplete,
              // isLoading: emailLoading.has(rowData.id)
            },
            (rowData) => {
              const disable = Array.isArray(rowData) ? rowData.reduce((p, row) => (p = p || (row.role !== 'Viewer' || sessionComplete) || (!['Registered', 'Approved', 'Waitlisted'].includes(row.state)) || row.banned, p), false) : true
              return {
                icon: RemoveFromQueueIcon,
                tooltip: 'Cancel Selected',
                onClick: openCancelPrompt,
                disabled: disable,
                hidden: !allowCheckIn || !multiSelect,
                // isLoading: canceledLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const disable = Array.isArray(rowData) ? rowData.reduce((p, row) => (p = p || (row.role !== 'Viewer' || sessionComplete) || row.state !== 'Registered' || row.banned, p), false) : true
              return {
                icon: DesktopAccessDisabledIcon,
                tooltip: 'No Show Selected',
                onClick: handleBatchNoShow,
                hidden: !allowFG || !multiSelect,
                disabled: disable,
                // isLoading: turnAwayLoading.has(rowData.id)
              }
            },
            (rowData) => {
              const disable = Array.isArray(rowData) ? rowData.reduce((p, row) => (p = p || (row.role !== 'Viewer' || row.banned), p), false) : true
              return {
                icon: AssignmentIndIcon,
                tooltip: 'Change Recruiter Code',
                onClick: openRecruiterPrompt,
                disabled: disable,
                hidden: !(allowCheckIn || allowFG) || !multiSelect,
                // isLoading: canceledLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: VideocamIcon,
                tooltip: 'Register to Zoom',
                onClick: handleBulkZoomRegister,
                hidden: !hasZoomMeeting || !zoomMeetingId || !multiSelect,
                disabled: sessionComplete || rowData.length > 30,
                isLoading: zoomLoading
              }
            },
            (rowData) => {
              const disable = Array.isArray(rowData) ? rowData.some(i => i.state === 'Approved') : true
              return {
                icon: TransferWithinAStation,
                tooltip: 'Move Guest(s) to Session',
                onClick: openMoveGuestPrompt,
                hidden: !multiSelect,
                disabled: disable
              }
            },
          ]}
          components={{
            //Container: props => <div className="table-wrapper">{props.children}</div>,
            Action: props => {
              // const action = typeof props.action === "function" ? props.action(props.data) : props.action
              return (
                // <div className={styles.action}>
                //   <Typography>{JSON.stringify(typeof props.action)}</Typography>
                //   {action.isLoading && <CircularProgress size={48} className={styles.actionLoading} />}
                //   <MTableAction {...props} />
                // </div>
                <MTableActionLoading {...props} />
              )
            },
            Actions: props => {
              return (
                <div className={styles.actionRow}><MTableActions {...props} /></div>
              )
            },
            // Row: props => {
            //   return (
            //     <MTableBodyRow {...props}
            //       onRowClick={(e, row, toggleDetailPanel) => {
            //         toggleDetailPanel()
            //         // props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
            //         e.stopPropagation();
            //         console.log('ROW CLICK', row, props.detailPanel)
            //         // openRef.current = openRef.current.id === row.id ? {} : row

            //         // setOpenRow(prev => prev.id === row.id ? {} : { id: row.id })
            //       }}
            //     // onRowClick={(e, row, toggleDetailPanel) => {
            //     //   // props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
            //     //   e.stopPropagation();
            //     //   console.log('ROW CLICK', row, toggleDetailPanel)
            //     //   // openRef.current = openRef.current.id === row.id ? {} : row
            //     //   toggleDetailPanel()
            //     //   setOpenRow(prev => prev.id === row.id ? {} : { id: row.id })
            //     // }} 
            //     />
            //   )
            // },
            // Toolbar: props => <MTableToolbar {...props} />
          }}
          detailPanel={[{
            // disabled: true,
            render: renderGuestTableDetails
          }]}
        />
      </div>
      {!!emailModalOpts && <EmailModal onSubmit={handleSendBatchEmails} open={!!emailModalOpts} opts={emailModalOpts} handleClose={handleCloseEmailModal} />}
      {/*RemoveFromQueueIcon*/}
      <StandardDialog
        open={cancelPromptOpen}
        title="Cancel Tech Check"
        content={selectedGuest?.id && `Cancelling Tech Check for ${selectedGuest.firstName} ${selectedGuest.lastName}. Please enter a message for the guest if desired and submit.`}
        fieldOptions={[
          {
            label: 'Cancel Message',
            key: 'message',
            type: 'text',
            multiline: true
          },
        ]}
        // submitLabel="YES"
        onSubmit={(e, t) => {
          canceledGuest(e, selectedGuest?.id ? [selectedGuest.id] : [...selectedGuestIds], t)
          setCancelPromptOpen(false)
          setSelectedGuest({})
          setSelectedGuestIds([])
        }}
        // cancelLabel="NO"
        onCancel={() => {
          setCancelPromptOpen(false)
          setSelectedGuest({})
          setSelectedGuestIds([])
        }}
      />
      <StandardDialog
        open={checkInWarnOpen}
        title="Check-In Ejected Guest?"
        content={selectedGuest?.id && `${selectedGuest.firstName} ${selectedGuest.lastName} was ejected from this session. Are you sure you want to proceed with Check-In?`}
        submitLabel="YES"
        onSubmit={(e) => {
          checkInGuest(e, selectedGuest)
          setCheckInWarnOpen(false)
          setSelectedGuest({})
        }}
        cancelLabel="NO"
        onCancel={() => {
          setCheckInWarnOpen(false)
          setSelectedGuest({})
        }}
      />
      <StandardDialog
        open={deleteWarnOpen}
        title="Delete Guest?"
        content={selectedGuest?.id && `Are you sure you want to delete ${selectedGuest.firstName} ${selectedGuest.lastName} from this session?`}
        onSubmit={(e) => {
          deleteGuest(e, selectedGuest)
          setDeleteWarnOpen(false)
          setSelectedGuest({})
        }}
        onCancel={() => {
          setDeleteWarnOpen(false)
          setSelectedGuest({})
        }}
      />
      <StandardDialog
        open={banWarnOpen}
        title="Ban Guest?"
        content={selectedGuest?.id && `Are you sure you want to ban ${selectedGuest.firstName} ${selectedGuest.lastName} (${selectedGuest.email}) from the VSR platform? NOTE: This ban will not allow any guest with this email address to be registered to any session in the VSR.`}
        fieldOptions={[
          {
            label: 'Banned Reason',
            key: 'banReason',
            type: 'text',
            multiline: true,
            required: true
          },
        ]}
        onSubmit={(e, t) => {
          banGuest(e, selectedGuest, t)
          setBanWarnOpen(false)
          setSelectedGuest({})
        }}
        onCancel={() => {
          setBanWarnOpen(false)
          setSelectedGuest({})
        }}
      />
      <StandardDialog
        open={recruiterEditOpen}
        title="Change Recruiter Code"
        fieldOptions={[
          {
            label: 'Recruiter Code',
            key: 'recruiterCode',
            type: 'text'
          },
        ]}
        onSubmit={(e, t) => {
          updateBatch(e, selectedGuestIds, t)
          setRecruiterEditOpen(false)
          setSelectedGuestIds([])
        }}
        onCancel={() => {
          setRecruiterEditOpen(false)
          setSelectedGuestIds([])
        }}
      />
      <StandardDialog
        open={moveGuestOpen}
        title="Move Guest(s) to Session"
        fieldOptions={[
          {
            label: 'Choose Session',
            type: 'select',
            placeholder: 'Select a Session',
            options: projectSessions.current,
            optionLabel: 'label',
            optionValueKey: 'code',
            multiple: false,
            required: true,
            value: moveToSessionId
          }
        ]}
        onChange={(upd) => {
          setMoveToSessionId(upd.code);
        }}
        onSubmit={() => {
          moveGuests(selectedGuestIds, moveToSessionId, sessionId)
          setMoveGuestOpen(false)
          setSelectedGuestIds([])
          setMoveToSessionId()
        }}
        onCancel={() => {
          setMoveGuestOpen(false)
          setSelectedGuestIds([])
          setMoveToSessionId();
        }}
      />
    </>
  );
})

export default SessionUsersView //withSuspense(SessionUsersView)