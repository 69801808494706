import React, { useCallback } from 'react';
import { Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  label: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  radio: {
    flexDirection: 'row'
  }
});

const RadioInputField = React.memo(({ value, label,  onChange, options }) => {
  const styles = useStyles()

  const handleChange = useCallback(e => {
    onChange(null, e.target.value)
  }, [onChange]);

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <RadioGroup value={value} onChange={handleChange} className={styles.radio} >
        {options.map(radio => <FormControlLabel value={radio.code} control={<Radio color="primary" />} label={radio.label} />)}
      </RadioGroup>
    </div>
  );
})

export default RadioInputField;
