import React, { useState, useRef, useCallback } from 'react';
import { Typography, TextField, InputAdornment, Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiPicker from 'emoji-picker-react';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  subroot: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  sublabel: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: ({ label }) => label ? 'calc(20% + 16px)' : '10px'
  },
  button: {
    fontSize: '115%'
  }
});

const EmojiInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, onRemove }) => {
  const styles = useStyles({ label })

  const [anchorEl, setAnchorEl] = useState()

  const toggleOpen = useCallback((event) => {
    setAnchorEl(prev => prev ? null : event.currentTarget)
  }, [])

  const handleChange = useCallback((newValue) => {
    onChange(null, newValue?.emoji)
  }, [onChange]);

  return (
    <>
      <div className={styles.root}>
        {label && <Typography className={styles.label}> {label}</Typography>}
        {/* <TextField className={styles.textField} inputProps={{ maxLength: maxLength || 524288 }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value || ''} placeholder={placeholder || 'Enter text'} onChange={handleChange} multiline={large} rows={large ? 3 : 1 } InputProps={{
          endAdornment: (
            endComponent && <InputAdornment position="end">
              {typeof endComponent === 'function' ? endComponent() : endComponent}
            </InputAdornment>
          ),
        }}/> */}
        <Button disabled={disabled} className={styles.button} variant="outlined" onClick={toggleOpen}>{value || "\u2003"}</Button>
        {/* <Typography className={styles.textField} onClick={toggleOpen}>{value}</Typography> */}
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={toggleOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <EmojiPicker
            skinTonesDisabled={true}
            previewConfig={{
              showPreview: false
            }}
            emojiVersion="12.0"
            onEmojiClick={handleChange}
          />
        </Popover>
      </div>
    </>
  );
})

// TextInputField.defaultProps = {
//   label: 'Text'
// }

export default EmojiInputField;
