import React, { useEffect, useCallback, useRef } from 'react';
import StandardDrawer from './standard/StandardDrawer';
// import FreshdeskWidget from './standard/FreshdeskWidget';
import DrawerLogo from './DrawerLogo';
import TodayIcon from '@material-ui/icons/Today';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import EmailIcon from '@material-ui/icons/Email';
import UpdateIcon from '@material-ui/icons/Update';
import RestoreIcon from '@material-ui/icons/Restore';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useOktaAuth } from '@okta/okta-react';

const AppDrawer = React.memo(({ allowAddSession, allowBan, allowEmails, userName, userEmail, ...props }) => {
    const { oktaAuth } = useOktaAuth();

    // const openSupport = useRef()

    const logout = async () => {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
    };

    // const handleSupportOpen = useCallback(() => {
    //     if (openSupport.current) openSupport.current()
    // }, [])

    const addGroup = allowAddSession ? [{
        // label: 'TEST',
        links: [
            {
                label: 'Add New Session',
                to: {
                    pathname: "/sessions/upcoming",
                    key: Date.now(),
                    state: {
                        add: true
                    }
                },
                icon: AddCircleOutlineIcon,
                disableHighlight: true
            }
        ]
    }] : []

    const banGroup = allowBan || allowEmails ? [{
        links: [
            ...(allowBan ? [{
                label: 'Banned Emails',
                to: '/banned',
                icon: NotInterestedIcon
            }] : []),
            ...(allowEmails ? [{
                label: 'Email Templates',
                to: '/emails',
                icon: EmailIcon
            }] : [])
        ]
    }] : []

    const drawerProps = {
        headerComponent: DrawerLogo,
        linkGroups: [
            {
                // label: 'TEST',
                links: [
                    {
                        label: "Today's Sessions",
                        to: '/sessions/today',
                        icon: TodayIcon
                    },
                    {
                        label: 'Upcoming Sessions',
                        to: '/sessions/upcoming',
                        icon: UpdateIcon,
                        defaultPath: true
                    },
                    {
                        label: 'Past Sessions',
                        to: '/sessions/past',
                        icon: RestoreIcon,
                    }
                ]
            },
            ...addGroup,
            {
                // label: 'TEST',
                links: [
                    {
                        label: "Project Groups",
                        to: '/projects',
                        icon: DynamicFeedIcon
                    }
                ]
            },
            ...banGroup,
            {
                // label: 'TEST',
                links: [
                    // {
                    //     label: 'Settings',
                    //     to: '/settings',
                    //     icon: SettingsIcon,
                    // },
                    // {
                    //     label: 'Contact Support',
                    //     action: handleSupportOpen,
                    //     icon: ContactSupportIcon,
                    // },
                    {
                        label: 'Logout',
                        action: logout,
                        icon: ExitToAppIcon,
                    }
                ]
            }
        ],
        divideLinkGroups: true
    }

    return (<>
        <StandardDrawer {...drawerProps} {...props} />
        {/* <FreshdeskWidget widgetId={33000000476} handleOpenRef={openSupport} name={userName} email={userEmail} /> */}
    </>);

})

export default AppDrawer;
