import React from 'react';
import { CircularProgress, Fab, Zoom } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
  addFab: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: '10',
    padding: '1rem',
    boxSizing: 'content-box'
  }
})

const AddFab = React.memo(({ show, loading, onClick, theme }) => {
  const styles = useStyles();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <div>
      <div className={`${styles.addFab} mui-fixed`}>
        <Zoom
          key={'add-session-fab'}
          in={show}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${transitionDuration.exit}ms`,
          // }}
          unmountOnExit
        >
          <Fab color="primary" onClick={onClick} disabled={loading}>
            {loading ? <CircularProgress size="1.5rem" /> : <AddIcon />}
          </Fab>
        </Zoom>
      </div>
    </div>
  );
})

export default withTheme(AddFab)