import React, { useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: ({ labelPosition }) => labelPosition === 'top' ? 'column' : 'row',
    padding: '5px',
    alignItems: ({ labelPosition }) => labelPosition === 'top' ? 'flex-start' : 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: ({ labelPosition }) => labelPosition === 'top' ? 'left' : 'right',
    marginRight: '10px'
  },
  textField: {
    // flexBasis: '80%'
    // width: '100%'
    width: ({ width }) => `calc(28px + (${width} * 1.25ch))`
  },
  text: {
    padding: '3px'
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexBasis: '80%'
  }
});

let intg = /^\d*\.?\d*$/

const NumberInputField = React.memo(({ label, labelPosition, placeholder, placeholderChar, prefix, suffix, value, disabled, minValue, maxValue, decimals, onChange, invalid, invalidate }) => {
  const width = Math.ceil(Math.log10(Math.floor(maxValue) + 1))
  const char = placeholderChar || '#'
  const inputPlaceholder = placeholder || (char.repeat(width || 2) + (decimals ? `.${char.repeat(decimals)}` : ''))
  const styles = useStyles({ labelPosition, width: (width + (decimals || 0)) || 2 })

  const handleChange = useCallback((event) => {
    if (intg.test(event.target.value) || event.target.value === '') {
      // const incomplete = event.target.value[event.target.value.length - 1] === '.'
      // const newValue = event.target.value !== '' ? incomplete ? event.target.value : parseFloat(event.target.value) : null
      const newValue = event.target.value !== '' ? event.target.value : null
      console.log('number change!', event.target.value, newValue)
      if (newValue === null || (event.target.value && (maxValue ? newValue <= maxValue : true))) {
        console.log('number on change!!', newValue)
        onChange(null, newValue)
      }
    }
  }, [maxValue, onChange]);

  const handleBlur = useCallback((event) => {
    if (event.target.value.length && decimals) {
      const splitValue = event.target.value.split('.')
      const newValue = splitValue[0] + '.' + (splitValue[1] || '').padEnd(2, '0')
      onChange(null, newValue)
    }
  }, [decimals, onChange])

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <div className={styles.inputRow}>
        {!!prefix && <Typography className={styles.text}>{prefix}</Typography>}
        <TextField className={styles.textField} error={invalid} disabled={disabled} inputProps={{ maxLength: (width + ((decimals + 1) || 0)) || 524288, inputMode: decimals ? "decimal" : "numeric" }} variant="outlined" size="small" placeholder={inputPlaceholder} value={value !== null && value !== undefined ? value.toString() : ''} onChange={handleChange} onBlur={handleBlur} />
        {!!suffix && <Typography className={styles.text}>{suffix}</Typography>}
      </div>
    </div>
  );
})

export default NumberInputField;
