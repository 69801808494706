import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocalStorage } from '../hooks/hooks'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, Select, MenuItem, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withSuspense } from '../hooks/suspense'
import { techCheckMode } from './utils'

const groupBy = require('lodash/groupBy');
const intersection = require('lodash/intersection');
const partition = (arr, fn) =>
  arr.reduce((acc, e) => {
    acc[fn(e) ? 0 : 1].push(e)
    return acc
  }, [[], []])

const useStyles = makeStyles({
  video: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem'
  },
  videoControls: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '1rem'
  },
  videoButton: {
    margin: '6px'
  },
  sessions: {
    marginBottom: '1rem',
    width: '100%'
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chatRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    // flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  selectField: {
    flexBasis: '10%',
    marginRight: '10px'
  },
  titlegroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  tabs: {
    justifyContent: 'space-between'
  },
  tabBadge: {
    // position: 'absolute'
    paddingLeft: '16px',
    paddingBottom: '3px'
  },
  dialog: {
    width: '50%',
    minWidth: '500px'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  segmentTitle: {
    fontSize: '200%'
  },
  segmentOption: {
    fontWeight: 'bold',
    fontSize: '125%'
  },
  boldCell: {
    // fontSize: '105%',
    // fontWeight: 'bold'
  },
  table: {
    margin: '1rem 0 1rem 0'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem',
    margin: '0 0.25rem 0 0.25rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoItem: {
    margin: '0 0.1rem 0 0.1rem'
  },
  none: {
    width: '100%',
    textAlign: 'center',
    padding: '2rem',
    boxSizing: 'border-box'
  }
})

const tabs = [
  // 'ALL',
  'Qualified',
  'Registered',
  'Waitlisted',
  'Approved',
  'Turn Away',
  'Checked-In',
  'Complete',
  'Focus Group',
  'No Show',
  'Canceled'
]

const SessionBalanceView = React.memo(({ id: sessionId, audienceSegments, virtualSegments, confirmGoal, seatGoal, focusGroupGoal, connections, guests, bypassTechCheck, autoTechCheck }) => {
  // console.log('render balance page')
  // const user = useRecoilValue(currentUser);
  const [balance, setBalance] = useState()
  // const [tableConnections, setTableConnections] = useState(undefined)
  const styles = useStyles();
  const [tab, setTab] = useLocalStorage(`session${sessionId}balancetab`, 'Approved')
  const [focusFilter, setFocusFilter] = useLocalStorage(`session${sessionId}focusGroupFilter`, {})
  // const [currentFilter, setCurrentFilter] = useState([]);
  // const { oktaAuth } = useOktaAuth();

  const goals = useMemo(() => {
    return tabs.reduce((p, c) => {
      p[c] = {
        name: ['Registered', 'Approved'].includes(c) ? "Confirm" : ['Checked-In', 'Complete'].includes(c) ? "Seat" : c === 'Focus Group' ? "Focus Group" : null,
        value: (['Registered', 'Approved'].includes(c) ? confirmGoal : ['Checked-In', 'Complete'].includes(c) ? seatGoal : c === 'Focus Group' ? focusGroupGoal : null) || 0
      }
      return p
    }, {})
  }, [confirmGoal, seatGoal, focusGroupGoal])

  const segments = useMemo(() => {
    return audienceSegments ? groupBy([...audienceSegments, ...(virtualSegments || [])], 'questionKey') : {}
  }, [audienceSegments, virtualSegments])

  const filteredGuests = useMemo(() => {
    const newGuests = {}
    const grouped = guests ? guests.filter(x => x.role === 'Viewer').map(x => {
      const g = {}
      switch (x.state) {
        // case 'Registered':
        //   g.state = ['Qualified', 'Registered']
        //   break;
        // case 'Waitlisted':
        //   g.state = ['Waitlisted']
        //   break;
        // case 'Approved':
        //   g.state = ['Approved']
        //   break;
        case 'Checked-In':
          g.state = ['Approved', 'Checked-In']
          break;
        case 'Complete':
          g.state = ['Approved', 'Checked-In', 'Complete']
          break;
        case 'Ejected':
          g.state = ['Approved', 'Checked-In', 'Ejected']
          break;
        case 'Turn Away':
          g.state = ['Approved', 'Turn Away']
          break;
        case 'Tech-Check':
          g.state = ['Registered']
          break;
        default:
          g.state = [x.state]
      }
      if (x.focusGroup) {
        g.state.push(...g.state.map(x => `${x}-FG`, 'ALL-FG'))
      }
      if (x.selectedFocusGroup) {
        g.state.push('Focus Group')
      }
      g.segments = x.segments || {}
      g.techCheckMode = techCheckMode(x, autoTechCheck, bypassTechCheck)
      return g
    }).reduce((p, c) => {
      c.state.forEach(s => (p[s] || (p[s] = [])).push(c))
      return p
    }, {}) : {}
    const fgTabs = tabs.map(x => x + '-FG')
    tabs.concat(fgTabs).forEach(t => {
      if (t === 'ALL') {
        newGuests[t] = (guests || []).concat()
      } else {
        newGuests[t] = (grouped[t] || []).concat()
      }
    })
    return newGuests
  }, [guests, autoTechCheck, bypassTechCheck])

  useEffect(() => {
    const newBalance = {}
    const audienceSegments = Object.values(segments).flat()
    Object.entries(filteredGuests).forEach(([state, gx]) => {
      newBalance[state] = {}
      audienceSegments.forEach((segment) => {
        newBalance[state][segment.questionKey] = {}
        const ct = segment.hasCrosstab && segment.crosstabKeys && segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length
        let noTotal = []
        if (segment.dataKey === 'segments') {
          segment.answers.forEach(answer => {
            const split = partition(gx, x => {
              let inGroup = false;
              let range;
              const match = answer.conditions.map(condition => {
                switch (condition.condition) {
                  case "IS":
                  case "INCLUDES":
                    return typeof x.segments[condition.dataSubKey] === 'object' ? Array.isArray(x.segments[condition.dataSubKey]) ? x.segments[condition.dataSubKey].includes(condition.answerKey) : x.segments[condition.dataSubKey] && x.segments[condition.dataSubKey][condition.optionKey] === condition.answerKey  : x.segments[condition.dataSubKey] === condition.answerKey;
                  case "IS NOT":
                  case "EXCLUDES":
                    return !(typeof x.segments[condition.dataSubKey] === 'object' ? Array.isArray(x.segments[condition.dataSubKey]) ? x.segments[condition.dataSubKey].includes(condition.answerKey) : x.segments[condition.dataSubKey] && x.segments[condition.dataSubKey][condition.optionKey] === condition.answerKey  : x.segments[condition.dataSubKey] === condition.answerKey);
                  case "IN":
                    range = segment.freeNumeric ? condition.answerKey.replace(/([^-\S\d])/g, '').split('-').map(s => parseFloat(s)) : null
                    return range && ((parseInt(x.segments[condition.dataSubKey]) - range[0]) * (parseInt(x.segments[condition.dataSubKey]) - range[1]) > 0);
                  case "NOT IN":
                    range = segment.freeNumeric ? condition.answerKey.replace(/([^-\S\d])/g, '').split('-').map(s => parseFloat(s)) : null
                    return range && !((parseInt(x.segments[condition.dataSubKey]) - range[0]) * (parseInt(x.segments[condition.dataSubKey]) - range[1]) > 0);
                  case "GREATER OR =":
                    return (parseInt(x.segments[condition.dataSubKey]) >= parseInt(condition.answerKey));
                  case "LESS OR =":
                    return (parseInt(x.segments[condition.dataSubKey]) <= parseInt(condition.answerKey));
                  case "COUNT >=":
                    return Array.isArray(x.segments[condition.dataSubKey]) && x.segments[condition.dataSubKey].length >= parseInt(condition.answerKey);
                  case "COUNT <=":
                    return Array.isArray(x.segments[condition.dataSubKey]) && x.segments[condition.dataSubKey].length <= parseInt(condition.answerKey);
                  default:
                    return false;
                }
              })
              if (answer.group === "CUSTOM") {
                const condition = answer.customLogic || ""
                const accumulator = {
                  level: 0,
                  operator: [],
                  not: [],
                  value: [],
                  match
                }
                try {
                  const result = [...condition.replace(/\s/g, '')].reduce((s, char, i, arr) => {
                    switch (char) {
                      case "&":
                      case "|":
                        s.operator[s.level] = char
                        break;
                      case "!":
                        s.not[s.level] = true
                        break;
                      case "(":
                        s.level++;
                        break;
                      case ")":
                        s.level--;
                      case "$":
                        let val = char === "$" ? s.match[parseInt(arr.join('').slice(i).match(/^[^\d]*(\d+)/)[1]) - 1] : s.value.pop()
                        if (s.not[s.level]) {
                          val = !val
                          s.not[s.level] = false
                        }
                        if (typeof s.value[s.level] !== 'boolean') {
                          s.value[s.level] = val
                        } else if (s.operator[s.level] === "&") {
                          s.value[s.level] = s.value[s.level] && val
                        } else if (s.operator[s.level] === "|") {
                          s.value[s.level] = s.value[s.level] || val
                        }
                        break;
                    }
                    return s
                  }, accumulator)
                  inGroup = !!result.value[0]
                } catch(err) {
                  console.log("ERROR PARSING CUSTOM LOGIC", err)
                }
              } else {
                inGroup = match.reduce((p,c) => answer.group === 'ALL' ? p && c : p || c, answer.group === 'ALL')
              }
              return inGroup
            })
            const total = split[0]
            noTotal = intersection(noTotal, split[1].map(g => g.id))
            const stats = {
              total: total.length,
              current: `${gx.length ? Math.round(total.length / gx.length * 100) + '%' : '-'}`
            }
            newBalance[state][segment.questionKey][answer.answer] = stats
          })
        } else {
          // let lastOption;
          if (!segment.answers) console.log('CHECK SEGMENTS', segment)
          segment.answers.map(ans => segment.matrix ? segment.matrixKeys.map(option => ({ ...ans, ...option })) : ans).flat().forEach((answer) => {
            // if (!lastOption && answer.option) lastOption = answer.option
            const range = segment.freeNumeric ? answer.answer.replace(/([^-\S\d])/g, '').split('-').map(s => parseFloat(s)) : null
            const total = gx.filter(x => {
              return x.segments[segment.questionKey] && (range ? ((parseInt(x.segments[segment.questionKey]) - range[0]) * (parseInt(x.segments[segment.questionKey]) - range[1]) <= 0) : Array.isArray(x.segments[segment.questionKey]) ? x.segments[segment.questionKey].includes(answer.answer) : (segment.matrix ? x.segments[segment.questionKey][answer.option] : x.segments[segment.questionKey]) === answer.answer)
            })
            if (segment.matrix) {
              if (!noTotal[answer.option]) noTotal[answer.option] = gx
              noTotal[answer.option] = noTotal[answer.option].filter(x => {
                return !x.segments[segment.questionKey] || x.segments[segment.questionKey][answer.option] !== answer.answer
              })
            } else {
              noTotal = noTotal.filter(x => {
                return !x.segments[segment.questionKey] || (range ? ((parseInt(x.segments[segment.questionKey]) - range[0]) * (parseInt(x.segments[segment.questionKey]) - range[1]) > 0) : Array.isArray(x.segments[segment.questionKey]) ? !x.segments[segment.questionKey].includes(answer.answer) : x.segments[segment.questionKey] !== answer.answer)
              })
            }
            const stats = {
              total: total.length,
              current: `${gx.length ? Math.round(total.length / gx.length * 100) + '%' : '-'}`
            }
            if (ct) {
              stats.crosstab = {}
              segment.crosstabKeys.map(({ key }) => key).forEach(crosstabKey => {
                segments[crosstabKey][0].answers.forEach((ans) => { //CHECK
                  stats.crosstab[ans.answer] = total.filter(x => {
                    const range = segments[crosstabKey][0].freeNumeric ? ans.answer.replace(/([^-\S\d])/g, '').split('-').map(s => parseInt(s)) : null
                    return x.segments[crosstabKey] && (range ? ((parseInt(x.segments[crosstabKey]) - range[0]) * (parseInt(x.segments[crosstabKey]) - range[1]) <= 0) : Array.isArray(x.segments[crosstabKey]) ? x.segments[crosstabKey].includes(ans.answer) : x.segments[crosstabKey] === ans.answer)
                  }).length
                })
              })
            }
            if (segment.matrix) {
              if (!newBalance[state][segment.questionKey][answer.option]) newBalance[state][segment.questionKey][answer.option] = {}
              newBalance[state][segment.questionKey][answer.option][answer.answer] = stats
              const none = {
                total: noTotal[answer.option].length,
                current: `${gx.length ? Math.round(noTotal[answer.option].length / gx.length * 100) + '%' : '-'}`
              }
              newBalance[state][segment.questionKey][answer.option]['N/A'] = none
              // if (lastOption !== answer.option) lastOption = answer.option
            } else {
              newBalance[state][segment.questionKey][answer.answer] = stats
            }
          })
        }
        if (!segment.matrix) {
          const none = {
            total: noTotal.length,
            current: `${gx.length ? Math.round(noTotal.length / gx.length * 100) + '%' : '-'}`
          }
          if (ct) {
            none.crosstab = {}
            segment.crosstabKeys.map(({ key }) => key).forEach(crosstabKey => {
              segments[crosstabKey][0].answers.forEach((ans) => { //CHECK
                none.crosstab[ans.answer] = noTotal.filter(x => {
                  const range = segments[crosstabKey][0].freeNumeric ? ans.answer.replace(/([^-\S\d])/g, '').split('-').map(s => parseInt(s)) : null
                  return x.segments[crosstabKey] && (range ? ((parseInt(x.segments[crosstabKey]) - range[0]) * (parseInt(x.segments[crosstabKey]) - range[1]) <= 0) : x.segments[crosstabKey] === ans.answer)
                }).length
              })
            })
          }
          newBalance[state][segment.questionKey]['N/A'] = none
        }
      })
      newBalance[state]._techCheckMode = gx.reduce((p, c) => (p[c.techCheckMode] = (p[c.techCheckMode] || 0) + 1, p), {})
    })
    // console.log('~BALANCE~', newBalance)
    setBalance(newBalance)
  }, [segments, filteredGuests])

  const handleStateChange = useCallback((event) => {
    event.currentTarget.blur()
    setTab(event.target.value);
  }, [setTab]);

  const toggleFocusFilter = useCallback((event) => {
    event.currentTarget.blur()
    // console.log('toggle focus filter', ct, event.target.checked  )
    setFocusFilter(prev => {
      return { ...prev, [tab]: event.target.checked }
    })
  }, [tab, setFocusFilter])

  const balanceColorCoding = useCallback((totalBalance, goal) => {
    const percent = Number.isInteger(goal) ? Math.round(totalBalance / goal * 100) : 0;
    if (percent >= 90 && percent <= 94) {
      return {
        backgroundColor: '#fbfde0',
      };
    } else if (percent >= 95 && percent <= 105) {
      return {
        backgroundColor: '#e0fde2',
      };
    } else if (percent >= 106) {
      return {
        backgroundColor: '#fde2e0',
      };
    }
    return {
      backgroundColor: 'transparent'
    };
  }, []);

  const balanceKey = !!focusFilter[tab] ? tab + '-FG' : tab

  return (
    <div>
      <div className={styles.chatRow}>
        <Typography className={styles.label}>Guest State:</Typography>
        <Select
          value={tab}
          onChange={handleStateChange}
          // displayEmpty
          className={styles.selectField}
        >
          {tabs.map(t => <MenuItem key={`session${sessionId}balancetab${t}`} value={t}>{t}</MenuItem>)}
          {/* <MenuItem value='Registered'>Registered</MenuItem>
          <MenuItem value='Waitlisted'>Waitlisted</MenuItem>
          <MenuItem value='Approved'>Approved</MenuItem>
          <MenuItem value='Checked-In'>Checked-In</MenuItem>
          <MenuItem value='Focus Group'>Focus Group</MenuItem> */}
        </Select>
        {!['Registered', 'Focus Group'].includes(tab) && <>
          <Checkbox size="small" color="primary" checked={!!focusFilter[tab]} onChange={toggleFocusFilter} />
          <Typography>Flagged for Focus Group</Typography>
        </>}
      </div>
      <div className={styles.infoRow}>
        {(goals[tab] && goals[tab].name) && <Paper className={styles.infoBox}>
          <Typography variant="button" className={styles.infoItem}>{goals[tab].name} Goal</Typography>
          <Chip color="secondary" size="small" className={styles.infoItem} label={goals[tab].value} />
        </Paper>}
        <Paper className={styles.infoBox}>
          <Typography variant="button" className={styles.infoItem}>{tab} Count</Typography>
          <Chip color="secondary" size="small" className={styles.infoItem} label={(filteredGuests && filteredGuests[balanceKey] && filteredGuests[balanceKey].length) || '0'} />
        </Paper>
      </div>
      {!Object.values(segments).flat().length && <div className={styles.none}>
        <Typography variant="caption">No Audience Segements Configured</Typography>
      </div>}
      {balance && <>
        {!(bypassTechCheck || balanceKey === "Qualified") && <TableContainer className={styles.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.segmentTitle}>Tech Check Mode</TableCell>
                <TableCell>Total</TableCell>
                {/* <TableCell>Current %</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Guided</TableCell>
                <TableCell className={styles.boldCell}>{balance[balanceKey]._techCheckMode.guided || 0}</TableCell>
                {/* <TableCell className={styles.boldCell}>{balance[balanceKey][segment.questionKey][answer.answer].current}</TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell>Self-Guided</TableCell>
                <TableCell className={styles.boldCell}>{balance[balanceKey]._techCheckMode.self || 0}</TableCell>
                {/* <TableCell className={styles.boldCell}>{balance[balanceKey][segment.questionKey][answer.answer].current}</TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell>None</TableCell>
                <TableCell className={styles.boldCell}>{balance[balanceKey]._techCheckMode.none || 0}</TableCell>
                {/* <TableCell className={styles.boldCell}>{balance[balanceKey][segment.questionKey][answer.answer].current}</TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>}
        {Object.values(segments).flat().map((segment) =>
          <React.Fragment key={`balancesegmenttable-${segment.questionKey}`}>
            {/* <Typography variant="h4">{segment.questionKey}</Typography> */}
            <TableContainer className={styles.table} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.segmentTitle}>{segment.questionKey}{segment.freeNumeric && ' Group'}</TableCell>
                    {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map(crosstabKey =>
                      segments[crosstabKey][0].answers.map((ans, i) =>
                        <TableCell key={`crosstabkeycell-${segment.questionKey}-${i}`}>{ans.answer}</TableCell>
                      )
                    )}
                    <TableCell>Total</TableCell>
                    <TableCell>Goal</TableCell>
                    <TableCell>Current %</TableCell>
                    <TableCell>Goal %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {segment.matrix ? segment.matrixKeys.map((option, i) => (<React.Fragment key={`balancesegmenttable-matrixgroup-${segment.questionKey}-${i}`}>
                    <TableRow key={`matrixkeyrow-${segment.questionKey}-${i}`}>
                      <TableCell className={styles.segmentOption} colSpan={5 + ((segment.hasCrosstab && segment.crosstabKeys?.length) || 0)}>
                        {option.option}
                      </TableCell>
                    </TableRow>
                    {segment.answers.map((answer) => (
                      <TableRow key={`balance-${segment.questionKey}-${answer.answer}`}>
                        <TableCell>{answer.answer}</TableCell>
                        {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map(crosstabKey =>
                          segments[crosstabKey][0].answers.map(ans =>
                            <TableCell>{balance[balanceKey][segment.questionKey][answer.answer].crosstab[ans.answer]}</TableCell>
                          )
                        )}
                        <TableCell style={balanceColorCoding(balance[balanceKey][segment.questionKey][option.option][answer.answer].total, Number.isInteger(goals[tab].value) && !isNaN(parseInt(answer.goal?.[option.option])) ? Math.round(answer.goal[option.option] / 100 * goals[tab].value) : null)} className={styles.boldCell}>{balance[balanceKey][segment.questionKey][option.option][answer.answer].total}</TableCell>
                        <TableCell>{Number.isInteger(goals[tab].value) && answer.goal?.[option.option] ? Math.round(answer.goal[option.option] / 100 * goals[tab].value) : '-'}</TableCell>
                        <TableCell className={styles.boldCell}>{balance[balanceKey][segment.questionKey][option.option][answer.answer].current}</TableCell>
                        <TableCell>{answer.goal?.[option.option] ? answer.goal[option.option] + '%' : '-'}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={`balance-${segment.questionKey}-${option}-none`}>
                      <TableCell>N/A</TableCell>
                      {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map(crosstabKey =>
                        segments[crosstabKey][0].answers.map((ans) =>
                          <TableCell key={`balancesegmenttable-${segment.questionKey}`}>{balance[balanceKey][segment.questionKey][option.option]['N/A'].crosstab[ans.answer]}</TableCell>
                        )
                      )}
                      <TableCell>{balance[balanceKey][segment.questionKey][option.option]['N/A'].total}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{balance[balanceKey][segment.questionKey][option.option]['N/A'].current}</TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                    <TableRow key={`balance-${segment.questionKey}-${option}-total`}>
                      <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                      {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map(crosstabKey =>
                        segments[crosstabKey][0].answers.map(ans =>
                          <TableCell style={{ fontWeight: 'bold' }}>{Object.values(balance[balanceKey][option.option][segment.questionKey]).reduce((p, c) => p + c.crosstab[ans.answer], 0)}</TableCell>
                        )
                      )}
                      <TableCell style={{ fontWeight: 'bold' }}>{Object.values(balance[balanceKey][segment.questionKey][option.option]).reduce((p, c) => p + c.total, 0)}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{segment.answers.reduce((p, c) => p + ((c.goal?.[option.option] && Number.isInteger(goals[tab].value)) ? Math.round(parseFloat(c.goal[option.option]) / 100 * goals[tab].value) : 0), 0)}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{Object.values(balance[balanceKey][segment.questionKey][option.option]).reduce((p, c) => p + (parseFloat(c.current) || 0), 0)}%</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{segment.answers.reduce((p, c) => p + (c.goal?.[option.option] ? parseFloat(c.goal[option.option]) : 0), 0)}%</TableCell>
                    </TableRow>
                  </React.Fragment>)) : <>
                    {segment.answers.map((answer) => (
                      <TableRow key={`balance-${segment.questionKey}-${answer.answer}`}>
                        <TableCell>{answer.answer}</TableCell>
                        {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map((crosstabKey, ia) =>
                          segments[crosstabKey][0].answers.map((ans, ib) =>
                            <TableCell key={`balance-${segment.questionKey}-${answer.answer}-crosstab-${ia}-${ib}`}>{balance[balanceKey][segment.questionKey][answer.answer].crosstab[ans.answer]}</TableCell>
                          )
                        )}
                        <TableCell style={balanceColorCoding(balance[balanceKey][segment.questionKey][answer.answer].total, Number.isInteger(goals[tab].value) && !isNaN(parseInt(answer.goal)) ? Math.round(answer.goal / 100 * goals[tab].value) : null)} className={styles.boldCell}>{balance[balanceKey][segment.questionKey][answer.answer].total}</TableCell>
                        <TableCell>{Number.isInteger(goals[tab].value) && !isNaN(parseInt(answer.goal)) ? Math.round(answer.goal / 100 * goals[tab].value) : '-'}</TableCell>
                        <TableCell className={styles.boldCell}>{balance[balanceKey][segment.questionKey][answer.answer].current}</TableCell>
                        <TableCell>{!isNaN(parseInt(answer.goal)) ? answer.goal + '%' : '-'}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={`balance-${segment.questionKey}-none`}>
                      <TableCell>N/A</TableCell>
                      {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map((crosstabKey, ia) =>
                        segments[crosstabKey][0].answers.map((ans, ib) =>
                          <TableCell key={`balance-${segment.questionKey}-none-crosstab-${ia}-${ib}`}>{balance[balanceKey][segment.questionKey]['N/A'].crosstab[ans.answer]}</TableCell>
                        )
                      )}
                      <TableCell>{balance[balanceKey][segment.questionKey]['N/A'].total}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{balance[balanceKey][segment.questionKey]['N/A'].current}</TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                    <TableRow key={`balance-${segment.questionKey}-total`}>
                      <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                      {(segment.hasCrosstab && segment.crosstabKeys && !!segment.crosstabKeys.map(({ key }) => key).filter(crosstabKey => Object.keys(segments).includes(crosstabKey)).length) && segment.crosstabKeys.map(({ key }) => key).map((crosstabKey, ia) =>
                        segments[crosstabKey][0].answers.map((ans, ib) =>
                          <TableCell style={{ fontWeight: 'bold' }} key={`balance-${segment.questionKey}-total-crosstab-${ia}-${ib}`}>{Object.values(balance[balanceKey][segment.questionKey]).reduce((p, c) => p + c.crosstab[ans.answer], 0)}</TableCell>
                        )
                      )}
                      <TableCell style={{ fontWeight: 'bold' }}>{Object.values(balance[balanceKey][segment.questionKey]).reduce((p, c) => p + c.total, 0)}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{segment.answers.reduce((p, c) => p + ((c.goal && Number.isInteger(goals[tab].value)) ? Math.round(parseFloat(c.goal) / 100 * goals[tab].value) : 0), 0)}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{Object.values(balance[balanceKey][segment.questionKey]).reduce((p, c) => p + (parseFloat(c.current) || 0), 0)}%</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{segment.answers.reduce((p, c) => p + (c.goal ? parseFloat(c.goal) : 0), 0)}%</TableCell>
                    </TableRow>
                  </>}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </>}
    </div>
  );
})

export default withSuspense(SessionBalanceView)