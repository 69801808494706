import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Paper, Typography, Button, CircularProgress, Fade, Backdrop, Collapse, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { DropzoneArea } from 'material-ui-dropzone'
import StandardField from './fields/StandardField';
import { clearLocalStorage, useLocalStorage } from '../../hooks/hooks'

const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '100vw'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    width: '80%',
    minWidth: '300px',
    padding: '15px 0 0 0',
    maxHeight: '98%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 0 15px',
    marginBottom: '6px'
  },
  body: {
    padding: '0 15px 0 15px',
    overflowY: 'auto'
  },
  headerText: {
    margin: 'auto 0 auto 0'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 0 15px 0',
    marginTop: '6px'
  },
  button: {
    margin: '3px'
  },
  divider: {
    margin: '1rem'
  },
  dropzone: {
    minHeight: 'auto',
  },
  dropzoneParagraph: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginBlockEnd: '0.5em'
  },
  progressContainer: {
    width: '100%',
    textAlign: 'center',
    padding: '1rem 0 1rem 0',
    boxSizing: 'border-box'
  },
  progressBox: {
    position: 'relative',
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 0 1rem 0',
    boxSizing: 'border-box'
  },
  progress: {
    width: '50%',
    maxWidth: '300px'
  },
  progressLabel: {
    position: 'absolute'
  }
});

const ImportModal = React.memo(({ fields = [], modalKey, handleClose, open, title, placeholder, onSubmit, acceptedFileTypes, maxFileSize, percent, progress, started, complete }) => {
  const [files, setFiles] = useLocalStorage(`import-${modalKey}-files`, [])  //React.useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [params, setParams] = useLocalStorage(`import-${modalKey}-params`, {}) //useState(data || {});
  const [invalidFields, setInvalidFields] = useState(new Set());
  const styles = useStyles();

  const filesRef = useRef()
  filesRef.current = files
  const paramsRef = useRef(params);

  useEffect(() => {
    return () => {
      clearLocalStorage([`${modalKey}-params`, `${modalKey}-tab`])
    }
  })

  useEffect(() => {
    const newParams = {}
    const invalid = new Set()
    fields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((fld) => {
          newParams[fld.key] = paramsRef.current ? paramsRef.current[fld.key] : fld.defaultValue !== undefined ? fld.defaultValue : null
          if (fld.required && (!newParams[fld.key] || newParams[fld.key].length === 0)) invalid.add(fld.key)
        })
      } else {
        newParams[field.key] = paramsRef.current ? paramsRef.current[field.key] : field.defaultValue !== undefined ? field.defaultValue : null
        if (field.required && (!newParams[field.key] || newParams[field.key].length === 0)) invalid.add(field.key)
      }
    })
    // console.log('setting params & initial invalid')
    setInvalidFields(prev => new Set([...prev], [...invalid]))
    if (!Object.keys(paramsRef.current).length) setParams(newParams)
  }, [fields, setParams])

  useEffect(() => {
    paramsRef.current = params;
    // console.log('params change', params)
  }, [params])
  
  useEffect(() => {
    filesRef.current = files
  }, [files])

  const handleValueChange = useCallback((event, newValue, field, changeEffects, valueKey) => {
    const newParams = cloneDeep(paramsRef.current)
    const [f, k] = field.split('.')
    if (k) {
      if (newParams[f]) {
        newParams[f][k] = (newValue && valueKey) ? newValue[valueKey] : newValue
      } else {
        newParams[f] = {
          [k]: (newValue && valueKey) ? newValue[valueKey] : newValue
        }
      }
    } else {
      newParams[field] = (newValue && valueKey) ? newValue[valueKey] : newValue
    }
    if (changeEffects && typeof changeEffects === 'function') {
      Promise.resolve(changeEffects(newValue, newParams)).then(() => {
        setParams(newParams)
      })
    } else {
      setParams(newParams)
    }
  }, [setParams])

  const handleCustomButtonAction = useCallback((buttonAction) => {
    if (buttonAction && typeof buttonAction === 'function') {
      const newParams = cloneDeep(paramsRef.current)
      Promise.resolve(buttonAction(newParams)).then(() => {
        setParams(newParams)
      })
    }
  }, [setParams])

  const handleSubmit = useCallback(event => {
    setSubmitted(true)
    onSubmit(filesRef.current, params)
  }, [onSubmit, params]);

  const handleFileChange = useCallback(files => {
    setFiles(files)
  }, [setFiles])

  const setInvalid = useCallback((field, invalid) => {
    setInvalidFields(prev => {
      const newInvalid = new Set(prev)
      invalid ? newInvalid.add(field) : newInvalid.delete(field)
      return newInvalid
    })
  }, []);

  const isInvalid = useMemo(() => {
    return !!invalidFields.size || (!files || !files.length) || submitted
  }, [invalidFields.size, files, submitted])

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={handleClose}
      // closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={open}>
        <Paper
          className={styles.paper}
        >
          <div className={styles.header}>
            <Typography variant="h5" className={styles.headerText}>{title}</Typography>
          </div>
          <div className={styles.body}>
            <form autoCapitalize="off">
              {!submitted && <DropzoneArea
                acceptedFiles={acceptedFileTypes || []}
                filesLimit={1}
                maxFileSize={maxFileSize || 3000000}
                onChange={handleFileChange}
                dropzoneText={placeholder || 'Drag & drop a file or click here'}
                dropzoneClass={styles.dropzone}
                dropzoneParagraphClass={styles.dropzoneParagraph}
                useChipsForPreview
                // showPreviewsInDropzone
              />}
              {submitted && <div className={styles.progressContainer}>
                <div className={styles.progressBox}>
                  <div className={styles.progress}>
                    <CircularProgress variant={(started && !(Math.round(percent) === 100 && !complete)) ? 'static' : 'indeterminate' } value={percent} size={'100%'} />
                  </div>
                  <div className={styles.progressLabel}>
                    {(started && !isNaN(percent)) && <Typography variant="h2" component="div" color="textSecondary">{`${Math.round(percent)}%`}</Typography>}
                  </div>
                </div>
                <Typography variant="h4">{progress}</Typography>
              </div>}
              {fields && fields.length > 0 &&
                fields.map((field, fi) => {
                  const [fl, kl] = Array.isArray(field) ? [] : field.key.split('.')
                  return Array.isArray(field) ?
                    <div key={`field${fi}${field.key}`}>
                      {field.map((fld, i) => {
                        const [f, k] = fld.key.split('.')
                        return (
                          <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${i}${fld.key}`} in={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={styles.field}>
                            <StandardField
                              value={k ? params[f] && params[f][k] : params[fld.key]}
                              fieldKey={fld.key}
                              invalid={fld.subkey ? invalidFields.has(`${fld.key}-${fld.subkey}`) : invalidFields.has(fld.key)}
                              invalidate={setInvalid}
                              conditional={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                              conditionMet={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                              onChange={(e, v, k) => handleValueChange(e, v, fld.key, fld.onUpdate, k)}
                              onButtonClick={fld.buttonAction && (() => handleCustomButtonAction(fld.buttonAction))}
                              {...fld}
                              disabled={fld.disabled instanceof Function ? fld.disabled(params) : fld.disabled}
                              options={typeof fld.options === 'function' ? fld.options(params) : fld.options}
                              // options={fld.options}
                              disabledOptions={fld.disabledOptions instanceof Function ? (opt) => fld.disabledOptions(params, opt) : undefined} />
                          </Collapse>
                        )
                      })}
                      <Divider className={styles.divider} />
                    </div>
                    :
                    <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${fi}${field.key}`} in={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={styles.field}>
                      <StandardField
                        value={kl ? params[fl] && params[fl][kl] : params[field.key]}
                        fieldKey={field.key}
                        invalid={field.subkey ? invalidFields.has(`${field.key}-${field.subkey}`) : invalidFields.has(field.key)}
                        invalidate={setInvalid}
                        conditional={(field.conditions && field.conditions.length) ? field.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                        conditionMet={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                        onChange={(e, v, k) => handleValueChange(e, v, field.key, field.onUpdate, k)}
                        onButtonClick={field.buttonAction && (() => handleCustomButtonAction(field.buttonAction))}
                        {...field}
                        disabled={field.disabled instanceof Function ? field.disabled(params) : field.disabled}
                        options={typeof field.options === 'function' ? field.options(params) : field.options}
                        // options={field.options}
                        disabledOptions={field.disabledOptions instanceof Function ? (opt) => field.disabledOptions(params, opt) : undefined} />
                    </Collapse>
                })
              }
              <div className={styles.footer}>
                {!complete && <>
                  <Button className={styles.button} variant="contained" onClick={handleSubmit} color="primary" disabled={isInvalid}>Import</Button>
                  <Button className={styles.button} variant="contained" onClick={handleClose}>Cancel</Button>
                </>}
                {complete && <Button className={styles.button} variant="contained" color="primary" onClick={handleClose}>Close</Button>}
              </div>
            </form>
          </div>

        </Paper>
      </Fade>
    </Modal>
  );
})

ImportModal.defaultProps = {
  title: 'Import',
  importOptions: {

  }
}

export default ImportModal;
