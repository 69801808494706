import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import SelectInputField from './fields/SelectInputField';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, Checkbox, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dialog: {
        width: '50%',
        minWidth: '500px'
    },
    checkbox: {
        flexBasis: '50%',
        // textAlign: 'right',
        // marginRight: '10px'
    },
    checkboxPadding: {
        padding: '4px',
    },
    checkboxContent: {
        display: 'flex',
        alignItems: 'center',
        width: 'max-content'
    },
});

const valueMap = {
    text: 'value',
    boolean: 'checked'
}

const DialogField = ({ fieldKey, type, label, required, multiline, inputRef, onInvalid, onChange, ...restProps }) => {
    const styles = useStyles()
    const [invalid, setInvalid] = useState(type === 'text' && required)
    // console.log('DIALOG FIELD', fieldKey, type, required, type === 'text' && required)
    useEffect(() => {
        onInvalid(fieldKey, invalid)
        return () => onInvalid(fieldKey, false)
    }, [onInvalid, fieldKey, invalid])

    const handleInvalid = useCallback((e) => {
        if (type === 'text' && required) setInvalid(!e.target.value)
    }, [type, required, setInvalid])

    const handleChange = useCallback((e, t) => {
        if(type === 'select') onChange(t)
        else onChange(fieldKey, e.target[valueMap[type]])
    }, [type, fieldKey, onChange])

    switch (type) {
        case 'text':
            return <TextField
                autoFocus
                margin="dense"
                label={label}
                type="text"
                fullWidth
                multiline={multiline}
                rows={6}
                inputRef={inputRef}
                onChange={handleInvalid}
                error={invalid}
            />
        case 'boolean':
            return <div className={styles.checkboxContent}>
                <Checkbox size="small" color="primary" inputRef={inputRef} onChange={handleChange} />
                <Typography className={styles.checkLabel}>{label}</Typography>
            </div>
        case 'select':
            return <SelectInputField onChange={handleChange}  {...restProps} />
    }
}

const StandardDialog = React.memo(({ title, open, includeTextField, textFieldlabel, textFieldRequired, onChange, submitLabel, onSubmit, cancelLabel, onCancel, fieldOptions = [], checkboxOptions = [], content: Content }) => {
    const styles = useStyles()

    const [invalid, setInvalid] = useState(new Set())
    const [show, setShow] = useState(new Set())

    const fieldRef = useRef([])

    useEffect(() => {
        if (!open) {
            setInvalid(new Set())
            setShow(new Set())
        }
    }, [open])

    const fieldConditions = useMemo(() => {
        return fieldOptions.filter(x => x.condition).reduce((obj, field) => {
            obj[field.condition.key] = obj[field.condition.key] || []
            obj[field.condition.key].push({
                ...field.condition,
                key: field.key
            })
            return obj
        }, {})
    }, [fieldOptions])

    const handleShow = useCallback((key, value) => {
        setShow(prev => {
            const newShow = new Set([...prev])
            console.log(fieldConditions, key, value)
            if (fieldConditions[key]) fieldConditions[key].forEach(condition => condition.value === value ? newShow.add(condition.key) : newShow.delete(condition.key))
            return newShow
        })
    }, [fieldConditions])

    const handleInvalid = useCallback((key, isInvalid) => {
        setInvalid(prev => {
            const newInvalid = new Set([...prev])
            isInvalid ? newInvalid.add(key) : newInvalid.delete(key)
            return newInvalid
        })
    }, [])

    const handleSubmit = useCallback((e) => {
        const fieldValues = fieldOptions.reduce((p, c, i) => (p[c.key] = fieldRef.current[c.key]?.[valueMap[c.type]], p), {})
        onSubmit(e, fieldValues)
    }, [fieldOptions, onSubmit])

    return (
        open && <Dialog classes={{
            paper: styles.dialog
        }} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {Content && (typeof Content === 'string' ?
                    <DialogContentText>{Content}</DialogContentText>
                    : <Content />
                )}
                {fieldOptions.map((field, i) => {
                    const open = field.condition ? show.has(field.key) : true
                    return (
                        <Collapse key={field.key} mountOnEnter={false} unmountOnExit={true} in={open}>
                            <DialogField
                                fieldKey={field.key}
                                inputRef={el => fieldRef.current[field.key] = el}
                                onInvalid={handleInvalid}
                                onChange={onChange || handleShow}
                                {...field}
                            />
                        </Collapse>
                    )
                })}
            </DialogContent>
            <DialogActions>
                {onSubmit && <Button color='primary' variant='contained' onClick={handleSubmit} disabled={!!invalid.size}>
                    {submitLabel || "Submit"}
                </Button>}
                {onCancel && <Button color='default' variant='contained' onClick={onCancel}>
                    {cancelLabel || "Cancel"}
                </Button>}
            </DialogActions>
        </Dialog>
    );
})

export default StandardDialog;